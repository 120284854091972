<template>
  <v-container fluid>
      <v-dialog v-model="dialogUpload" width="800">
        <template v-slot:activator="{ on: dialog }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                color="primary"
                class="white--text"
                small
                v-on="{ ...tooltip, ...dialog }"
              >
                <v-icon>mdi-folder-upload-outline</v-icon>
              </v-btn>
            </template>
            <span>Загрузить</span>
          </v-tooltip>
        </template>
        <upload-files @close="dialogUpload = false" @finish="finishUpload" />
      </v-dialog>
      <bases-list v-bind:reload="reloadBasesList" />
  </v-container>
</template>

<script>
import BasesList from "@/components/Bases/BasesList.vue";
import UploadFiles from "@/components/Bases/Upload.vue";
export default {
  data() {
    return {
      dialogUpload: false,
      reloadBasesList: false,
    };
  },
  methods: {
    finishUpload() {
      this.dialogUpload = false;
      this.reloadBasesList = !this.reloadBasesList;
      console.log("finish uploading");
    },
  },
  components: {
    BasesList,
    UploadFiles,
  },
};
</script>
