var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"5"}},[_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"0"}},[(_vm.ready)?_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.rootfolders,"activatable":"","open-on-click":"","transition":"","open":_vm.openedTree},on:{"update:active":function($event){_vm.active=$event},"update:open":_vm.updateOpened},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[(!item.children)?_c('v-icon',{staticClass:"mx-1",attrs:{"x-small":""}},[_vm._v("mdi-music")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])],1),_c('v-col',{attrs:{"md":"2"}},[(item.comment != '<nil>')?_c('span',{staticClass:"font-weight-light text--secondary"},[_vm._v(_vm._s(item.comment.toLowerCase()))]):_vm._e()]),_c('v-col',{attrs:{"md":"1"}},[_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("id: "+_vm._s(item.id))])]),_c('v-col',{attrs:{"md":"3"}},[(item.owner != '<nil>' && !item.parent)?_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(item.owner))]):_vm._e()]),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.parent && item.used_pl > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Используется")])])],1)],1)]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.parent)?_c('v-btn',_vm._g({attrs:{"to":'/bases/delete/base/' + item.id + '/' + _vm.slashToBackslash(item.name),"x-small":"","disabled":item.children.length!=0,"icon":"","color":"error"}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Удалить")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.parent)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"error","disabled":item.used_files != 0 || item.used_pl != 0,"icon":"","to":'/bases/delete/base/' + item.id + '/' + _vm.slashToBackslash(item.name)}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}}],null,false,3396140499)}):_vm._e()],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',[_c('global-search')],1):_c('div',[_c('span',[_vm._v("выбрано: "+_vm._s(_vm.selected))]),_c('file-list',{attrs:{"folder_id":_vm.active[0]},on:{"deleted":function($event){return _vm.loadData()}}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }