<template>
  <v-card elevation="2" class="mt-4">
    <v-row>
        <v-col cols="11">
            <!-- Список файлов -->
            <v-data-table
              v-model="selected"
              dense
              :headers="headersFiles"
              :items="currentFiles"
              fixed-header
              sort-by="name"
              disable-pagination
              hide-default-footer
              show-select
              style="height: calc(100vh - 310px) !important"
              height="100%"
            >
                <template v-slot:[`header.actions`]>
                    <v-row justify="end" class="px-2">
                    <v-btn icon :disabled="!selected.length > 0">
                        <v-icon color="error" @click="deleteFiles()">mdi-delete</v-icon>
                    </v-btn>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>
        <v-col>
            <v-dialog v-model="dialogUpload" width="800">
                <template v-slot:activator="{ on: dialog }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        color="primary"
                        class="white--text"
                        small
                        v-on="{ ...tooltip, ...dialog }"
                    >
                        <v-icon>mdi-folder-upload-outline</v-icon>
                    </v-btn>
                    </template>
                    <span>Загрузить</span>
                </v-tooltip>
                </template>
                <playnow-upload v-bind:id="this.id" @finish="dialogUpload=false;loadFiles();" @close="dialogUpload = false"/>
            </v-dialog>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>
    import HTTP from "@/helpers/http-common";
    import PlaynowUpload from "./PlaynowUpload.vue"
    export default {
        data(){
            return {
              dialogUpload: false,  
              currentFiles: [],
              selected: [],
              headersFiles: [
                {
                text: "Наименование",
                value: "name",
                align: "left",
                width: "80%",
                sortable: false,
                fixed: true,
                },
                {
                text: "",
                value: "actions",
                align: "right",
                sortable: false,
                fixed: true,
                },
            ],
            }
        },
        methods: {
            loadFiles(){
                HTTP.GET("/endpoints/playnow/"+this.id).then((res) => {
                    this.currentFiles=res.data
                }).catch((error) => {
                    console.log(error)
                })
            },
            deleteFiles() {
                this.$confirm("Вы действительно желаете удалить файл?").then((res) => {
                    if (res) {
                    for (let r of this.selected) {
                        HTTP.DELETE("/endpoints/playnow/" + r.id)
                        .then(() => {
                            this.loadFiles();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    }
                    this.selected = [];
                    } 
                });
                },
        },
        computed: {
            
        },
        created(){
            this.loadFiles()
        },
        components: {
           PlaynowUpload 
        },
        props:['id']
        
    }
</script>

<style scoped>

</style>