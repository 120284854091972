<template>
    <div>
        <v-footer :fixed="true" app>
                <v-tooltip top v-if="!wsOk">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        color="red lighten-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >
                        mdi-server-remove
                        </v-icon>
                    </template>
                    <span>Offline</span>
                </v-tooltip>
            <span v-if="systemMessages.length > 0 && allowFor(['g_support'])">
                    <v-icon color="red darken-4" size=40 class="mr-2">mdi-alert-circle-outline</v-icon>
                    <v-btn dark v-for="item in systemMessages" :key="item.text" color="red darken-4" :to='item.route' depressed>
                        {{ item.text }}
                    </v-btn>
            </span>
            <span v-if="myOwner==3">Техническая поддержка: 8-800-511-43-22</span>
            <v-spacer/>
            <span class="caption">aJy AKPHA Platform</span>
        </v-footer>
    </div>
</template>

<script>
    export default {
        computed: {
            wsOk(){
                if(this.$route.name=="login"){
                    return true
                } 
                return this.$store.getters.getWS
            },
            systemMessages() {
                let notify = []
                if(this.$store.getters.getWSData('systemMessages')=="") {
                    return notify
                }
                let j = JSON.parse(this.$store.getters.getWSData('systemMessages'))[0]
                notify.push((j.switches_count>0)?{'route':'/switches/pinger', 'text':'Свитчей '+j.switches_count+"("+j.users_count+")"}:null)
                return notify
            },
            myOwner(){
                return this.$store.getters.userOwnerId
            }
        },
    }
</script>

<style scoped>

</style>
