<template>
  <v-card elevation="2" class="mt-4">
    <v-row>
      <v-menu
        dense
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="ma-3 caption"
            hide-details
            dense
            outlined
            readonly
            v-model="date"
            label="Выберите дату"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" @input="clickDatePicker" :first-day-of-week="1"></v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-card style="width: calc(100% - 1px)" elevation="0">
        <v-data-table
          class="mx-4"
          dense
          :headers="headers"
          :items="trackList"
          fixed-header
          sort-by="date"
          sort-desc=""
          disable-pagination
          hide-default-footer
          style="height: calc(100vh - 370px) !important;"
          height="100%"
        >
          <template v-slot:[`item.base`]="{ item }">
            <td><span :class="item.base.includes('PROMO')||item.base.includes('PLAYNOW')?'warning--text font-weight-bold':''">{{ item.base }}</span></td>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 10),

      trackList: [],
      headers: [
        {
          text: "Время",
          value: "date",
          align: "left",
          sortable: true,
          width: "10%",
        },
        {
          text: "Коллекция",
          value: "base",
          align: "left",
          sortable: false,
          width: "20%",
        },
        {
          text: "Артист",
          value: "artist",
          align: "left",
          sortable: false,

          width: "30%",
        },
        {
          text: "Наименование треков",
          value: "title",
          align: "left",
          sortable: false,

          width: "30%",
        },
        {
          text: "Лейбл",
          value: "label",
          align: "left",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  methods: {
    loadPlaylog() {
      if (this.id == "") {
        return;
      }
      HTTP.GET("/endpoints/playlog/" + this.id + "_" + this.date)
        .then((res) => {
          this.trackList = res.data;
        })
        .catch(() => {
          this.trackList = [];
        });
    },
    clickDatePicker() {
      this.menu = !this.menu;
    },
  },
  computed: {},
  created() {
    this.loadPlaylog();
  },
  watch: {
    id() {
      this.date = new Date().toISOString().substr(0, 10);
      this.loadPlaylog();
    },
    date() {
      this.loadPlaylog();
    },
    reload() {
      this.date = new Date().toISOString().substr(0, 10);
      this.loadPlaylog();
    },
  },
  props: ["id", "name", "reload"],
};
</script>

<style scoped></style>
