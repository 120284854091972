import axios from 'axios';

const Api = axios.create({
    baseURL: 'https://api.akpha.org',
    //baseURL: '/api/'
});
Api.defaults.withCredentials = true
Api.defaults.crossDomain= true

Api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
//    return Promise.reject(error.response.data);
    return Promise.reject(error);
});

export default {
    GET(source){
        return Api.get(source)
    },
    POST_ARRAY(source,payload){
        return Api({
            method: 'post',
            url: source,
            data: payload,
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
    },
    POST(source,payload){
        return Api({
            method: 'post',
            url: source,
            data: payload,
        })
    },
    PATCH(source,payload){
        return Api({
            method: 'patch',
            url: source,
            data: payload,
        })
    },
    DELETE(source,payload){
        return Api({
            method: 'delete',
            url: source,
            data: payload,
        })
    },
    UPLOAD(source,payload,progress){
        let formData = new FormData();
        formData.append("tracks", payload);
        // for(let f of payload){
        //     formData.append("tracks", f);
        // }
        return Api.put(source, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progress
        })
    },
    DOWNLOAD(source,progress){
        return Api.get(source, {
            responseType: 'blob',
            onDownloadProgress: progress
        })
    },
    validate(){
        return Api.get('/isauth')
    },
    loadMenu(){
        return Api.get('/menu')
    },
    loadSwitches(){
        return Api.get('/switches')
    },
    loadSelf(){
        return Api.get('/settings')
    },
    loadUsersList(){
        return Api.get('/users/show')
    },
    login(payload){
        return Api({
            method: 'post',
            url: '/auth',
            data: payload,
            })
    },
    createUser(payload){
        return Api({
            method: 'post',
            url: '/users/create',
            data: payload,
            })
    },
    deleteUser(payload){
        return Api({
            method: 'post',
            url: '/users/delete',
            data: payload,
            })
    },
    isLoginFree(payload){
        return Api({
            method: 'post',
            url: '/users/isloginfree',
            data: payload,
            })
    }
}
