<template>
    <v-card elevation="0">
        <v-toolbar color="primary">
        <v-toolbar-title class="text-h5 primary white--text">
            Копирование настроек
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closed">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="source_ep"
                        label="Откуда"
                        :items="endpoints"
                        :item-text="item => item.name+' / '+item.id"
                        item-value="id"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <v-checkbox
                  v-model="copyCollection"
                  label="Коллекции"
                  color="primary"
                  :disabled="copySchedule"
                  :value="copySchedule"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="copySchedule"
                  label="Расписание"
                  color="primary"
                  @click="copyCollection=copySchedule"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="copyAdvSchedule"
                  label="Реклама"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="copyPlaynow"
                  label="Playnow"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center">
                <v-btn
                  class="caption"
                  color="success"
                  @click="copySettings"
                  :disabled="!valid"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                endpoints: [],
                source_ep: null,
                copyCollection: true,
                copySchedule: true,
                copyAdvSchedule: false,
                copyPlaynow: false,
            }
        },
        methods: {
            loadEndpoints(){
                HTTP.GET("/endpoints/endpoints").then((res) => {
                    this.endpoints = res.data.filter((item) => {
                        return item.parent==this.subgroup_id && item.id != this.target_ep
                    })
                }).catch((error) => {
                    console.log(error)
                })
            },
            copySettings(){
                HTTP.PATCH("/endpoints/endpoint",{src: this.source_ep, dst: this.target_ep, copyCollection: this.copyCollection.toString(), copySchedule: this.copySchedule.toString(), copyAdvSchedule: this.copyAdvSchedule.toString(), copyPlaynow: this.copyPlaynow.toString()}).then(() => {
                    this.$emit("saved",{})
                }).catch((error) => {
                    console.log(error)
                })
            },
            closed(){
                this.$emit("closed",{})
            },
        },
        computed: {
            valid(){
                if(this.source_ep===null) {
                    return false
                }
                if(!this.copyCollection && !this.copySchedule && !this.copyAdvSchedule && !this.copyPlaynow){
                    return false
                }
                return true
            }
 
        },
        created(){
            this.loadEndpoints()
        },
        props:['target_ep','subgroup_id']
        
    }
</script>

<style scoped>

</style>