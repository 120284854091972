export default {
    state: {
        endpoints: {
            openedTree: []
        }
    },
    mutations:{
        EP_SET_OPENED(state,payload){
            state.endpoints.openedTree = payload
        }
    },
    actions: {
       EP_ADD_OPENED({commit},payload){
            commit('EP_SET_OPENED',payload)
        }
    },
    getters: {
        ep_opened_tree: (state) => state.endpoints.openedTree,
    }
}
