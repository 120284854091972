<template>
  <v-card elevation="2" class="mt-4">
        <v-row>
          <v-spacer></v-spacer>
          <v-col md="1" align="right">
            <v-dialog v-model="dialogAddEvent" width="600">
              <template v-slot:activator="{ on: dialog }">
                <v-btn x-small icon dark elevation="1" class="primary" v-on="dialog">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card elevation="0">
                <v-toolbar color="primary" flat>
                  <v-toolbar-title class="text-h5 primary white--text">
                    Включение рекламного блока
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon class="white--text" @click="dialogAddEvent = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                  <v-row>
                    <v-col>
                      <v-select
                        label="Рекламный блок"
                        v-model="form.advblockid"
                        :items="advsCurrent"
                        :item-text="(item) => item.name"
                        :item-value="(item) => item.id"
                        dense
                        hide-details
                        outlined
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5" dense>
                    <v-list-item dense class="px-0" ripple @click="toggleDay">
                      <v-icon left :color="form.day.length > 0 ? 'primary' : ''">
                        {{ iconDays }}
                      </v-icon>
                      <v-list-item-content>
                        <v-list-item-title> Все дни недели </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-col md="1" v-for="(day, index) in daysSelect" :key="index">
                      <v-checkbox
                        hide-details
                        dense
                        v-model="form.day"
                        :label="daysPrettyText(day.value)"
                        :value="day.value"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <br />

                  <v-row class="mt-5" dense>
                    <v-list-item dense class="px-0" ripple @click="toggleHour">
                      <v-icon left :color="form.hour.length > 0 ? 'primary' : ''">
                        {{ iconHours }}
                      </v-icon>
                      <v-list-item-title> Выбрать все часы </v-list-item-title>
                    </v-list-item>

                    <v-col md="1" v-for="(hour, index) in hoursSelect" :key="index">
                      <v-checkbox
                        dense
                        hide-details
                        v-model="form.hour"
                        :label="hoursPrettyText(hour.value)"
                        :value="hour.value"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-5">
                    <v-col md="1" v-for="(minute, index) in minutesSelect" :key="index">
                      <v-checkbox
                        hide-details
                        dense
                        v-model="form.minute"
                        :label="minute.name"
                        :value="minute.value"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled="!valid"
                        @click="addToSchedule"
                        large
                        color="success"
                      >
                        Сохранить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-snackbar
                  :timeout="-1"
                  v-model="snackFreeMark"
                  absolute
                  bottom
                  color="error"
                  outlined
                  right
                >
                  Ошибка! Пересечение с блоком <b>{{crossedName}}</b>
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="error"
                      icon
                      small
                      v-bind="attrs"
                      @click="snackFreeMark = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="headers"
          :items="loadedSchedule"
          fixed-header
          sort-by="name"
          disable-pagination
          hide-default-footer
          style="height: calc(100vh - 335px) !important"
          height="100%"
        >
          <template v-slot:[`item.day`]="{ item }">
            {{ daysPrettyText(item.day) }}
          </template>
          <template v-slot:[`item.hour`]="{ item }">
            {{ hoursPrettyText(item.hour) }}
          </template>
          <template v-slot:[`item.minute`]="{ item }">
            {{ minutesPrettyText(item.minute) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              icon
              elevation="1"
              @click="editDialog(item.ruleid)"
              class="warning white--text mx-2"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="deleteRule(item.ruleid, item.name)"
              x-small
              icon
              elevation="1"
              class="error white--text"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogEditEvent" width="600">
          <advs-edit
            @closed="dialogEditEvent = false"
            @saved="
              snackSuccess = true;
              dialogEditEvent = false;
              loadSchedule();
            "
            v-bind:ruleid="editedRule"
            v-bind:id="id"
            v-bind:current="loadedSchedule"
          />
        </v-dialog>

      <v-snackbar
        timeout="1200"
        v-model="snackSuccess"
        top
        color="success"
        center
      >
        Изменения сохранены
      </v-snackbar>
      <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
        Внимание! Ошибка сохранения
      </v-snackbar>

  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
import AdvsEdit from "./AdvsEditSchedule.vue";
export default {
  data() {
    return {
      snackSuccess: false,
      snackError: false,
      snackFreeMark: false,
      crossedName: "",
      dialogAddEvent: false,
      dialogEditEvent: false,
      editedRule: false,
      freeMarks: [],
      advsCurrent: [],
      scheduleCurrent: [],
      loadedSchedule: [],
      form: {
        eid: null,
        day: [],
        hour: [],
        minute: [],
        advblockid: null,
      },
      headers: [
        {
          text: "Наименование блока",
          value: "name",
          align: "left",
          sortable: true,
          fixed: true,
        },
        {
          text: "Дни выхода",
          value: "day",
          align: "left",
          sortable: false,
          fixed: true,
        },
        {
          text: "Часы выхода",
          value: "hour",
          align: "left",
          sortable: false,
          fixed: true,
        },
        {
          text: "Минуты выхода",
          value: "minute",
          align: "left",
          sortable: false,
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          fixed: true,
        },
      ],
      daysSelect: [
        { name: "Понедельник", value: "0" },
        { name: "Вторник", value: "1" },
        { name: "Среда", value: "2" },
        { name: "Четверг", value: "3" },
        { name: "Пятница", value: "4" },
        { name: "Суббота", value: "5" },
        { name: "Воскресенье", value: "6" },
      ],
    };
  },
  methods: {
    /*marksIsFree() {
      for (let d of this.form.day) {
        for (let h of this.form.hour) {
          for (let m of this.form.minute) {
            let a = this.freeMarks.days.filter((day) => {
              return day.number == d;
            });
            if (a.length == 1) {
              let b = a[0].hours.filter((hour) => {
                return hour.number == h;
              });
              if (b.length == 1) {
                let c = b[0].minutes.filter((minute) => {
                  return minute == m;
                });
                if (c.length == 0) {
                  this.snackFreeMark = true;
                  this.crossItem()
                  return false;
                }
              }
            }
          }
        }
      }
      this.snackFreeMark = false;
      return true;
    },*/
    marksIsFree(){
      for (let d of this.form.day) {
        for (let h of this.form.hour) {
          for (let m of this.form.minute) {
            let a = this.loadedSchedule.filter((item) => {
              let rvD = "(^|,)"+d+"(,|$)"
              let rvH = "(^|,)"+h+"(,|$)"
              let rvM = "(^|,)"+m+"(,|$)"
              var rgxpD = new RegExp(rvD, "g");
              var rgxpH = new RegExp(rvH, "g");
              var rgxpM = new RegExp(rvM, "g");
              return item.day.match(rgxpD)!==null && item.hour.match(rgxpH)!==null && item.minute.match(rgxpM)!==null
            })
            if(a.length != 0){
              this.snackFreeMark = true;
              this.crossedName = a[0].name + " строчка "+a[0].ruleid
              return false
            }
          }
        }
      }
      this.snackFreeMark = false;
      return true;
    },
    editDialog(rule) {
      this.editedRule = rule;
      this.dialogEditEvent = true;
    },
    deleteRule(ruleid, name) {
      console.log("delete ruleid: " + ruleid + " endpoint: " + this.id);
      this.$confirm("Вы действительно желаете удалить " + name + "?").then((res) => {
        if (res) {
          HTTP.DELETE("/endpoints/advschedule/" + this.id, { rule: ruleid })
            .then(() => {
              this.loadSchedule();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    toggleDay() {
      if (this.selectAllDays) {
        this.form.day = [];
      } else {
        this.form.day = [];
        for (let d of this.daysSelect) {
          this.form.day.push(d.value);
        }
      }
    },
    toggleHour() {
      if (this.selectAllHours) {
        this.form.hour = [];
      } else {
        this.form.hour = [];
        for (let d of this.hoursSelect) {
          this.form.hour.push(d.value);
        }
      }
    },
    toggleMinute() {
      if (this.selectAllMinutes) {
        this.form.minute = [];
      } else {
        this.form.minute = [];
        for (let d of this.minutesSelect) {
          this.form.minute.push(d.value);
        }
      }
    },
    cutDayName(n) {
      let cutes = {
        0: "Пн",
        1: "Вт",
        2: "Cр",
        3: "Чт",
        4: "Пт",
        5: "Сб",
        6: "Вс",
      };
      return cutes[n];
    },
    addToSchedule() {
      this.form.eid = this.id;
      let request = {
        eid: this.form.eid,
        blockid: this.form.advblockid,
        ruleid: "0",
        schedule: {},
      };
      request.schedule.days = this.form.day.sort(function (a, b) {
        return a - b;
      });
      request.schedule.hours = this.form.hour.sort(function (a, b) {
        return a - b;
      });
      request.schedule.minutes = this.form.minute.sort(function (a, b) {
        return a - b;
      });
      /*for(let d of this.form.day){
                    let day = {num: d, events: []}
                    for(let h of this.form.hour){
                        for(let m of this.form.minute){
                            day.events.push({hour: h, minute: m})
                        }
                    }
                    request.schedule.push(day)
                }*/
      HTTP.POST("/endpoints/advschedule", request)
        .then(() => {
          this.snackSuccess = true;
          this.dialogAddEvent = false;
          this.form.day = [];
          this.form.hour = [];
          this.form.minute = [];
          this.loadSchedule();
        })
        .catch((error) => {
          this.snackError = true;
          this.dialogAddEvent = false;
          console.log(error);
        });
    },
    loadSchedule() {
      HTTP.GET("/endpoints/advschedule/" + this.id)
        .then((res) => {
          this.loadedSchedule = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      /*HTTP.GET("/endpoints/freeadvsmarks/" + this.id)
        .then((res) => {
          this.freeMarks = res.data;
        })
        .catch((error) => {
          console.log(error);
        });*/
    },
    loadAdvs() {
      HTTP.GET("/endpoints/advblocks/" + this.parentid)
        .then((res) => {
          this.advsCurrent = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    daysPrettyText(daysString) {
      let cutes = {
        0: "Пн",
        1: "Вт",
        2: "Cр",
        3: "Чт",
        4: "Пт",
        5: "Сб",
        6: "Вс",
      };
      let str = "";
      let days = daysString.split(",");
      for (let i = 0; i < days.length; i++) {
        if (i == 0) {
          //First day
          str += cutes[days[i]];
          continue;
        }
        if (days[i] - 1 == days[i - 1] && parseInt(days[i]) + 1 == days[i + 1]) {
          //Interval day
          if (str.slice(-1) != "-") {
            str += "-";
          }
          continue;
        }
        if (days[i] - 1 == days[i - 1] && parseInt(days[i]) + 1 != days[i + 1]) {
          //End of interval
          if (str.slice(-1) != "-") {
            str += "," + cutes[days[i]];
          } else {
            str += cutes[days[i]];
          }
          continue;
        }
        if (days[i] - 1 != days[i - 1]) {
          str += "," + cutes[days[i]];
          continue;
        }
        if (parseInt(days[i]) + 1 != days[i + 1]) {
          str += cutes[days[i]] + ",";
          continue;
        }
      }
      return str.replace(/(^,)|(,$)/g, "");
    },
    hoursPrettyText(hoursString) {
      let str = "";
      let hours = hoursString.split(",");
      for (let i = 0; i < hours.length; i++) {
        if (i == 0) {
          str += this.leadingZero(hours[i]);
          continue;
        }
        if (hours[i] - 1 == hours[i - 1] && parseInt(hours[i]) + 1 == hours[i + 1]) {
          if (str.slice(-1) != "-") {
            str += "-";
          }
          continue;
        }
        if (hours[i] - 1 == hours[i - 1] && parseInt(hours[i]) + 1 != hours[i + 1]) {
          //End of interval
          if (str.slice(-1) != "-") {
            str += "," + this.leadingZero(hours[i]);
          } else {
            str += this.leadingZero(hours[i]);
          }
          continue;
        }
        if (hours[i] - 1 != hours[i - 1]) {
          str += "," + this.leadingZero(hours[i]);
          continue;
        }
        if (parseInt(hours[i]) + 1 != hours[i + 1]) {
          str += this.leadingZero(hours[i]) + ",";
          continue;
        }
      }
      return str;
    },
    minutesPrettyText(minutesString) {
      let str = "";
      let minutes = minutesString.split(",");
      for (let m of minutes) {
        str += this.leadingZero(m) + ",";
      }
      return str.slice(0, -1);
    },
    leadingZero(i) {
      if (i.length == 1) {
        return "0" + i;
      }
      return i;
    },
  },
  computed: {
    /*parsedBlocks(){
                console.log(this.loadedSchedule)
                let parsed = []
                for(let block of this.loadedSchedule){
                    let t = {name: "", ruleid: "" ,event: {days : [],hours: [], minutes: []}}
                    for(let r of block){
                        t.name = r.name
                        t.ruleid = r.ruleid
                        t.event.days.push(parseInt(r.day))
                        t.event.hours.push(parseInt(r.hour))
                        t.event.minutes.push(parseInt(r.minute))
                    }
                    let uniqueDays = t.event.days.filter(function(item, pos, self) {
                        return self.indexOf(item) == pos;
                    })
                    t.event.days=uniqueDays.sort()
                    this.daysPrettyText(t.event.days)

                    let uniqueHours = t.event.hours.filter(function(item, pos, self) {
                        return self.indexOf(item) == pos;
                    })
                    t.event.hours=uniqueHours.sort(function (a, b) {  return a - b;  })
                    let uniqueMinutes = t.event.minutes.filter(function(item, pos, self) {
                        return self.indexOf(item) == pos;
                    })
                    t.event.minutes=uniqueMinutes.sort()
                    parsed.push(t)
                }
                return parsed
            },*/
    iconMinutes() {
      if (this.selectAllMinutes) return "mdi-close-box";
      if (this.selectSomeMinutes) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllMinutes() {
      return this.form.minute.length === this.minutesSelect.length;
    },
    selectSomeMinutes() {
      return this.form.minute.length > 0 && !this.selectAllMinutes;
    },
    selectAllHours() {
      return this.form.hour.length === this.hoursSelect.length;
    },
    selectSomeHours() {
      return this.form.hour.length > 0 && !this.selectAllHours;
    },
    iconHours() {
      if (this.selectAllHours) return "mdi-close-box";
      if (this.selectSomeHours) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllDays() {
      return this.form.day.length === this.daysSelect.length;
    },
    selectSomeDays() {
      return this.form.day.length > 0 && !this.selectAllDays;
    },
    iconDays() {
      if (this.selectAllDays) return "mdi-close-box";
      if (this.selectSomeDays) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    valid() {
      if (
        this.form.day.length == 0 ||
        this.form.hour.length == 0 ||
        this.form.minute.length == 0 ||
        this.form.advblockid == null
      ) {
        return false;
      }
      if (!this.marksIsFree()) {
        return false;
      }
      return true;
    },
    hoursSelect() {
      let a = Array.from({ length: 24 }, (k, v) => {
        let o = {
          name: v.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          value: v.toString(),
        };
        v++;
        return o;
      });
      return a;
    },
    minutesSelect() {
      let r = [];
      for (let i = 0; i < 60; i++) {
        if (i % 5 == 0) {
          r.push({
            name: i.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
            value: i.toString(),
          });
        }
      }
      return r;
    },
  },
  created() {
    this.loadAdvs();
    this.loadSchedule();
  },
  components: {
    AdvsEdit,
  },
  props: ["id", "parentid"],
};
</script>

<style scoped></style>
