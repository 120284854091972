import HTTP from '../helpers/http-common'
import Cookies from 'js-cookie'


export default {
    state: {
        user: {
            isAuthenticated: false,
            uid: null,
            token: Cookies.get('token'),
            groups: null,
            groupsid: null,
            FirstName: null,
            SecondName: null,
            navbar: []
        }
    },
    mutations: {
        SET_USER(state,payload){
            state.user.isAuthenticated=true
            state.user.token = payload
            Cookies.set('token',payload,{domain: 'akpha.org'})
        },
        SET_GROUPS(state,payload){
            state.user.groups = payload[0]
            state.user.groupsid = payload[1]
        },
        SET_USER_DATA(state,payload){
            state.user.FirstName = payload.fName
            state.user.SecondName = payload.sName
        },
        SET_USER_MENU(state,payload){
            if (payload.menu) {
                state.user.navbar = payload
            } else {
                state.user.navbar = {menu:[]}
            }
            state.user.navbar.menu.push({icon:'mdi-account-cog',route:'/self'},{title:'',icon:'mdi-exit-to-app', route:'/logout'})
        },
        UNSET_USER(state){
            Cookies.remove('token',{domain: 'akpha.org'})
            state.user = {
                isAuthenticated: false,
                uid: null,
                token: null,
                groups: null,
                navbar: []
            }
        }
    },
    actions: {
        STATE_CHANGED({commit},payload){
            if(payload){
                commit('SET_USER',payload)
            } else {
                commit('UNSET_USER')
            }
        },
        LOGOUT({commit}){
            commit('UNSET_USER')
            commit('UNSET_ROUTE')
        },
        LOGIN({commit,dispatch},payload){
            HTTP.login(payload)
            .then((res) => {
                if (res.data.token !== ""){
                    commit('SET_USER',res.data.token)
                    dispatch('LOAD_MYGROUPS')
                    dispatch('LOAD_MENU',res.data.token)
                } 
            }).catch((error) => {
                if(error.response.status=="502"){
                    commit('SET_ERROR',"Попробуйте позже")
                } else {
                    commit('SET_ERROR',error.response.data)
                }
            })
        },
        async LOAD_MYGROUPS({commit}){
            HTTP.GET("/mygroups").then((res) => {
                commit('SET_GROUPS',res.data)
            })
        },
        LOAD_MENU({commit}){
            HTTP.loadMenu().then((res) => {
                commit('SET_USER_MENU',res.data)
            }).catch((error) => {
                console.log(error)
            })
        },
        /*
        LOAD_SELFDATA({commit}){
            HTTP.loadSelf().then((res) => {
                commit('SET_USER_DATA',res.data[0])
            }).catch((error) => {
                console.log(error)
            })
        },
        */
    },
    getters: {
        isUserAuthenticated: (state) => state.user.isAuthenticated,
        userToken: (state) => state.user.token,
        userMenu: (state) => state.user.navbar,
        getN: state => () => state.user.isAuthenticated,
        userData: (state) => state.user,
        userRole: (state) => state.user.groups,
        userOwnerId: (state) => state.user.groupsid
    }
}
