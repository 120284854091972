<template>
    <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-form>
        <v-text-field v-model="subgroupName">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Наименование
          </template>
        </v-text-field>
        <v-text-field v-model="description" placeholder="Комментарий">
        </v-text-field>

        <v-row>
          <v-spacer></v-spacer>
          <v-col align="right">
            <v-btn
              class="caption"
              color="success"
              @click="createSubgroup"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-snackbar
          timeout="1200"
          v-model="snackSuccess"
          absolute
          top
          color="success"
          center
        >
          Группа услуг создана
        </v-snackbar>
        <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
          Ошибка создания группы услуг
        </v-snackbar> -->
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                subgroupName: "",
                description: ""
            }
        },
        methods: {
            createSubgroup(){
                HTTP.POST('/endpoints/subgroup',{id:this.id, parent: this.parent_id, name: this.subgroupName, description: this.description}).then(() =>{
                   this.$emit("created",{})
               }).catch((error) =>{
                   console.log(error)
               })
            },
            close(){
                this.$emit("close", {});
            }
        },
        computed: {
            valid(){
                if (this.subgroupName.length > 0) return true
                return false
            },
            title(){
                if(this.name && this.id){
                    return "Редактировать подгруппу"
                }
                return "Создать подгруппу"
            }
        },
        created(){
            if(this.id){//EDIT
                this.subgroupName=this.name
                this.description=this.desc
                // HTTP.GET('/endpoints/group').then((res) => {
                //     this.groups=res.data
                //     this.parentId=this.parent_id
                //     this.fname=this.name
                // }).catch((error) => {
                //     console.log(error)
                // })
            } 
        },
        props: ['id','name','desc','parent_id','parent_name']
    }
</script>

<style scoped>

</style>