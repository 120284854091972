<template>
  <v-card elevation="0">
    <v-dialog v-model="dialogAddGroup" width="500">
      <add-group
        v-if="dialogAddGroup"
        v-bind:id="selectedGroupId"
        v-bind:name="selectedGroupName"
        @created="
          loadData();
          dialogAddGroup = false;
        "
        @close="dialogAddGroup = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogAddSubgroup" width="500">
      <add-subgroup
        v-if="dialogAddSubgroup"
        v-bind:id="selectedSubgroupId"
        v-bind:name="selectedSubgroupName"
        v-bind:parent_id="selectedGroupId"
        v-bind:parent_name="selectedGroupName"
        @created="
          loadData();
          dialogAddSubgroup = false;
        "
        @close="dialogAddSubgroup = false"
      />
    </v-dialog>

    <v-row>
      <v-col md="4" class="hidden-md-and-down">
        <v-row class="pt-1">
          <v-col v-if="allowFor(['g_admin','g_manager'])" md="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  elevation="0"
                  icon
                  small
                  class="primary ml-2"
                  v-on="{ on }"
                  @click.stop="
                    selectedGroupId = '';
                    selectedGroupName = '';
                    dialogAddGroup = true;
                  "
                >
                  <v-icon small>mdi-group</v-icon>
                </v-btn>
              </template>
              <span>Создать группу</span>
            </v-tooltip>
            <!-- Диалог Создать услугу -->
            <v-dialog v-model="dialogAddEndpoint" width="800">
              <template v-slot:activator="{ on: dialog }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      elevation="1"
                      icon
                      dark
                      small
                      class="primary ml-2"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon small>mdi-disc-player</v-icon>
                    </v-btn>
                  </template>
                  <span>Создать услугу</span>
                </v-tooltip>
              </template>
              <endpoint-create
                @saved="
                  loadData();
                  dialogAddEndpoint = false;
                "
                @close="dialogAddEndpoint = false"
              />
            </v-dialog>
          </v-col>

          <!-- Фильтры -->
          <v-col align="right" md="4" offset-md="4" v-if="allowFor(['g_admin','g_manager'])">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  v-on="on"
                  class="primary"
                  elevation="0"
                  small
                  icon
                  @click="showFilter = !showFilter"
                >
                  <v-icon small> mdi-filter-menu </v-icon>
                </v-btn>
              </template>
              <span>Фильтр</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-expand-transition>
            <v-card elevation="0" width="100%" flat v-if="showFilter">
              <v-card-text>
                <v-row>
                  <v-col md="6">
                    <v-select
                      dense
                      hide-details
                      label="Тип услуги"
                      v-model="searchType"
                      :items="selectTypes"
                      item-text="value"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      dense
                      hide-details
                      label="Группа услуг"
                      v-model="searchGroup"
                      :items="selectGroups"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      hide-details
                      v-model="searchCity"
                      label="Город"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      hide-details
                      v-model="searchName"
                      label="Наименование услуги"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span v-if="filterIn">
                  <v-btn x-small @click="unsetFilter" class="warning"> Сбросить</v-btn>
                </span>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-row>
        <v-card
          v-if="allowFor(['g_admin','g_manager'])"
          elevation="0"
          class="mt-5"
          style="
            overflow-y: overlay;
            height: calc(100vh - 146px) !important;
            width: calc(100% - 2px);
          "
          height="100%"
          flat
        >
          <v-treeview
            class="pl-1 mr-2"
            :items="filteredItems"
            item-key="uniqTree"
            v-if="ready"
            open-on-click
            color="primary"
            hoverable
            transition
            @update:open="updateOpened"
            :open="openedTree"
          >
            <!-- Текст -->
            <template v-slot:label="{ item }">
              <div v-if="!item.parent">
                <v-row>
                  <v-col sm="6" md="7" align="left" class="service-list-header">
                    {{ item.name }}
                    <span
                    class="text-caption text--disabled"
                    v-if="item.owner != '<nil>' && !item.parent"
                    >{{ item.owner }}</span>
                  </v-col>
                  <v-col sm="6" md="5" align="right">
                    <v-chip
                      class="ml-1 chip-size"
                      label
                      outlined
                      x-small
                      v-for="(item, index) in GroupStat(item.children)"
                      v-bind:key="index"
                      :color="item.color"
                      >{{ item.value }}</v-chip
                    >
                  </v-col>
                </v-row>
              </div>
              <div v-if="item.parent && item.children">
                <v-row>
                  <v-col sm="6" md="7" align="left" class="service-list-header">
                    {{ item.name }}
                  </v-col>
                  <v-col sm="6" md="5" align="right">
                    <v-chip
                      class="ml-1 chip-size"
                      label
                      outlined
                      x-small
                      v-for="(item, index) in GroupStat(item.children)"
                      v-bind:key="index"
                      :color="item.color"
                      >{{ item.value }}</v-chip
                    >
                  </v-col>
                </v-row>
              </div>
              <!-- Услуга -->
              <div
                v-if="!item.children"
                @click="
                  selectedEndpointId = item.id;
                  selectedEndpointName = item.name;
                "
              >
                <div>
                  <span>
                    {{ item.name }}
                    <v-tooltip bottom v-if="item.slave!='0'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-circle-medium
                        </v-icon>
                        <v-icon
                          color="primary"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-circle-medium
                        </v-icon>
                      </template>
                      <span>slaved {{ item.slave }}</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.slave!='0'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click.stop="ReleaseGroup(item.id)">
                        <v-icon
                          color="error"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-call-split
                        </v-icon>
                        </v-btn>
                      </template>
                      <span>Отсоеденить от группы</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="ImMaster(item.id,item.parent)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-circle-medium
                        </v-icon>
                      </template>
                      <span>master</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.slave=='0' && !ImMaster(item.id,item.parent)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click.stop="openGroupDialog(item.id,item.parent)">
                        <v-icon
                          color="warning"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-set-left-center
                        </v-icon>
                        </v-btn>
                      </template>
                      <span>Добавить в группу</span>
                    </v-tooltip>
                    <v-icon small color="success" v-if="item.tunnel=='2' && allowFor(['g_root'])">
                      mdi-google-downasaur
                    </v-icon>
                  </span>
                </div>
                <v-row dense class="pt-1">
                  <v-col
                    cols="12"
                    md="4"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    >ID: {{ item.id }}</v-col
                  >
                  <v-col
                    cols="12"
                    md="5"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    >{{ hstname(item.hostname) }}</v-col
                  >
                  <v-col
                    cols="12"
                    md="3"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    >{{ version(item.version) }}</v-col
                  >
                </v-row>

                <v-row dense class="pb-1">
                  <v-col
                    cols="12"
                    md="4"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    >{{ item.city }}</v-col
                  >
                  <v-col
                    cols="12"
                    md="5"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    >{{ item.address }}</v-col
                  >
                  <v-col
                    cols="12"
                    md="3"
                    align="left"
                    class="text--secondary text-caption service-list-td"
                    ><endpoint-status :id="item.id" :short="true"
                  /></v-col>
                </v-row>
              </div>
            </template>

            <!-- Кнопки -->
            <!-- Группы -->
            <template v-slot:append="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="
                      selectedGroupId = item.id;
                      selectedGroupName = item.name;
                      selectedSubgroupId = '';
                      selectedSubgroupName = '';
                      dialogAddSubgroup = true;
                    "
                    v-bind:parent_id="selectedGroupId"
                    v-bind:parent_name="selectedGroupName"
                    elevation="1"
                    icon
                    x-small
                    dark
                    class="primary"
                    v-if="!item.parent"
                    v-on="on"
                  >
                    <v-icon x-small> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Добавить подгруппу</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="1"
                    icon
                    x-small
                    dark
                    class="warning ml-1"
                    v-if="!item.parent"
                    v-on="on"
                    @click.stop="
                      selectedGroupId = item.id;
                      selectedGroupName = item.name;
                      dialogAddGroup = true;
                    "
                  >
                    <v-icon x-small> mdi-cog-outline </v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="'/endpoints/delete/group/' + item.id + '/' + item.name"
                    elevation="1"
                    icon
                    x-small
                    dark
                    class="red ml-1"
                    v-if="!item.parent && item.children && item.children.length == 0"
                    v-on="on"
                  >
                    <v-icon x-small> mdi-delete-forever </v-icon>
                  </v-btn>
                </template>
                <span>Удалить группу</span>
              </v-tooltip>

              <!-- Подгруппы -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="openAdvDialog(item.id, item.name)"
                    elevation="1"
                    icon
                    x-small
                    dark
                    class="primary"
                    v-if="item.parent && item.children"
                    v-on="{ on }"
                  >
                    <v-icon x-small> mdi-alarm-note </v-icon>
                  </v-btn>
                </template>
                <span>Ролики</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="
                      selectedSubgroupId = item.id;
                      selectedSubgroupName = item.name;
                      selectedGroupId = item.parent;
                      dialogAddSubgroup = true;
                    "
                    elevation="1"
                    icon
                    x-small
                    dark
                    class="warning ml-1"
                    v-if="item.children && item.parent"
                    v-on="on"
                  >
                    <v-icon x-small> mdi-cog-outline </v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="'/endpoints/delete/subgroup/' + item.id + '/' + item.name"
                    fab
                    class="red ml-1"
                    x-small
                    v-if="item.parent && item.children && item.children.length == 0"
                    v-on="on"
                  >
                    <v-icon x-small> mdi-delete-forever </v-icon>
                  </v-btn>
                </template>
                <span>Удалить подгруппу</span>
              </v-tooltip>

              <!-- Услуги -->
            </template>
          </v-treeview>
        </v-card>
      </v-col>

      <!-- SMALL -->
      <v-col class="hidden-lg-and-up" v-if="allowFor(['g_admin','g_manager'])">
        <!-- Список услуг -->
        <v-btn elevation="1" color="primary" small @click.stop="showList = !showList" style="z-index: 4;">
          <v-icon>mdi-format-list-bulleted</v-icon>
          <span class="mx-2">Список услуг</span>
        </v-btn>
        <v-card
          class="mt-2"
          v-if="showList"
          elevation="3"
          style="
            width: 600px;
            position: absolute;
            z-index: 4;
            height: calc(100vh - 146px) !important;
            overflow-y: overlay;
            overflow-x: hidden;
          "
          height="100%"
        >
          <v-row class="ma-1">
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="white"
                    elevation="0"
                    icon
                    small
                    class="primary ml-2"
                    v-on="{ on }"
                    @click.stop="
                      selectedGroupId = '';
                      selectedGroupName = '';
                      dialogAddGroup = true;
                    "
                  >
                    <v-icon small>mdi-group</v-icon>
                  </v-btn>
                </template>
                <span>Создать группу</span>
              </v-tooltip>
              <!-- Диалог Создать услугу -->
              <v-dialog v-model="dialogAddEndpoint" width="800">
                <template v-slot:activator="{ on: dialog }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        elevation="1"
                        icon
                        dark
                        small
                        class="primary ml-2"
                        v-on="{ ...tooltip, ...dialog }"
                      >
                        <v-icon small>mdi-disc-player</v-icon>
                      </v-btn>
                    </template>
                    <span>Создать услугу</span>
                  </v-tooltip>
                </template>
                <endpoint-create
                  @saved="
                    loadData();
                    dialogAddEndpoint = false;
                  "
                  @close="dialogAddEndpoint = false"
                />
              </v-dialog>
            </v-col>
            <!-- Фильтры -->
            <v-col align="right" md="4" offset-md="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="white"
                    v-on="on"
                    class="primary"
                    elevation="0"
                    small
                    icon
                    @click="showFilter = !showFilter"
                  >
                    <v-icon small> mdi-filter-menu </v-icon>
                  </v-btn>
                </template>
                <span>Фильтр</span>
              </v-tooltip>
            </v-col>

            <v-col md="12">
              <v-expand-transition>
                <v-card elevation="0" width="100%" flat v-if="showFilter">
                  <v-card-text>
                    <v-row>
                      <v-col md="6">
                        <v-select
                          dense
                          hide-details
                          label="Тип услуги"
                          v-model="searchType"
                          :items="selectTypes"
                          item-text="value"
                          item-value="id"
                        ></v-select>
                      </v-col>
                      <v-col md="6">
                        <v-select
                          dense
                          hide-details
                          label="Группа услуг"
                          v-model="searchGroup"
                          :items="selectGroups"
                          item-text="name"
                          item-value="id"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          hide-details
                          v-model="searchCity"
                          label="Город"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          hide-details
                          v-model="searchName"
                          label="Наименование услуги"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <span v-if="filterIn">
                      <v-btn x-small @click="unsetFilter" class="warning">
                        Сбросить</v-btn
                      >
                    </span>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row class="ma-1" dense v-if="allowFor(['g_admin','g_manager'])">
            <v-col>
              <v-treeview
                class="ma-2"
                :items="filteredItems"
                item-key="uniqTree"
                v-if="ready"
                open-on-click
                color="primary"
                hoverable
                transition
                @update:open="updateOpened"
                :open="openedTree"
              >
                <!-- Текст -->
                <template v-slot:label="{ item }">
                  <div v-if="!item.parent">
                    <v-row>
                      <v-col md="7" align="left" class="service-list-header">
                        {{ item.name }}
                      </v-col>
                      <v-col md="5" align="right">
                        <v-chip
                          class="ml-1 chip-size"
                          label
                          outlined
                          x-small
                          v-for="(item, index) in GroupStat(item.children)"
                          v-bind:key="index"
                          :color="item.color"
                          >{{ item.value }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.parent && item.children">
                    <v-row>
                      <v-col md="7" align="left" class="service-list-header">
                        {{ item.name }}
                      </v-col>
                      <v-col md="5" align="right">
                        <v-chip
                          class="ml-1 chip-size"
                          label
                          outlined
                          x-small
                          v-for="(item, index) in GroupStat(item.children)"
                          v-bind:key="index"
                          :color="item.color"
                          >{{ item.value }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Услуга -->
                  <!-- showList = false; -->
                  <div
                    v-if="!item.children"
                    @click="
                      selectedEndpointId = item.id;
                      selectedEndpointName = item.name;
                    "
                  >
                    <div>
                      <span>{{ item.name }}</span>
                    </div>
                    <v-row dense class="pt-1">
                      <v-col
                        cols="12"
                        md="4"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        >ID: {{ item.id }}</v-col
                      >
                      <v-col
                        cols="12"
                        md="5"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        >{{ hstname(item.hostname) }}</v-col
                      >
                      <v-col
                        cols="12"
                        md="3"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        >{{ version(item.version) }}</v-col
                      >
                    </v-row>

                    <v-row dense class="pb-1" v-if="allowFor(['g_admin','g_manager'])">
                      <v-col
                        cols="12"
                        md="4"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        >{{ item.city }}</v-col
                      >
                      <v-col
                        cols="12"
                        md="5"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        >{{ item.address }}</v-col
                      >
                      <v-col
                        cols="12"
                        md="3"
                        align="left"
                        class="text--secondary text-caption service-list-td"
                        ><endpoint-status :id="item.id" :short="true"
                      /></v-col>
                    </v-row>
                  </div>
                </template>

                <!-- Кнопки -->
                <!-- Группы -->
                <template v-slot:append="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="
                          selectedGroupId = item.id;
                          selectedGroupName = item.name;
                          selectedSubgroupId = '';
                          selectedSubgroupName = '';
                          dialogAddSubgroup = true;
                        "
                        v-bind:parent_id="selectedGroupId"
                        v-bind:parent_name="selectedGroupName"
                        elevation="1"
                        icon
                        x-small
                        dark
                        class="primary"
                        v-if="!item.parent"
                        v-on="on"
                      >
                        <v-icon x-small> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Добавить подгруппу</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="
                          selectedGroupId = item.id;
                          selectedGroupName = item.name;
                          dialogAddGroup = true;
                        "
                        elevation="1"
                        icon
                        x-small
                        dark
                        class="warning ml-1"
                        v-if="!item.parent"
                        v-on="on"
                      >
                        <v-icon x-small> mdi-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :to="'/endpoints/delete/group/' + item.id + '/' + item.name"
                        elevation="1"
                        icon
                        x-small
                        dark
                        class="red ml-1"
                        v-if="!item.parent && item.children && item.children.length == 0"
                        v-on="on"
                      >
                        <v-icon x-small> mdi-delete-forever </v-icon>
                      </v-btn>
                    </template>
                    <span>Удалить группу</span>
                  </v-tooltip>

                  <!-- Подгруппы -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="openAdvDialog(item.id, item.name)"
                        elevation="1"
                        icon
                        x-small
                        dark
                        class="primary"
                        v-if="item.parent && item.children"
                        v-on="{ on }"
                      >
                        <v-icon x-small> mdi-alarm-note </v-icon>
                      </v-btn>
                    </template>
                    <span>Ролики</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click.stop="
                          selectedSubgroupId = item.id;
                          selectedSubgroupName = item.name;
                          selectedGroupId = item.parent;
                          dialogAddSubgroup = true;
                        "
                        elevation="1"
                        icon
                        x-small
                        dark
                        class="warning ml-1"
                        v-if="item.children && item.parent"
                        v-on="on"
                      >
                        <v-icon x-small> mdi-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :to="'/endpoints/delete/subgroup/' + item.id + '/' + item.name"
                        fab
                        class="red ml-1"
                        x-small
                        v-if="item.parent && item.children && item.children.length == 0"
                        v-on="on"
                      >
                        <v-icon x-small> mdi-delete-forever </v-icon>
                      </v-btn>
                    </template>
                    <span>Удалить подгруппу</span>
                  </v-tooltip>

                  <!-- Услуги -->
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col :lg="allowFor(['g_admin','g_manager'])?8:12" sm="12" xs="12" md="12">
        <tabs-view
          @reloadlist="loadData"
          v-if="selectedEndpointId"
          v-bind:id="selectedEndpointId"
          v-bind:ovl="showList"
          v-bind:data="selectedEndpointInfo"
          v-bind:parentname="selectedEndpointParent"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialogAdvs" width="1200">
      <endpoint-advs
        v-bind:id="advDialogId"
        v-bind:name="advDialogName"
        @closed="dialogAdvs = false"
      />
    </v-dialog>
    
    <v-dialog v-model="addToGroupDialog" width="600">
      <endpoint-group
        v-bind:id="addToGroupId"
        v-bind:masters="addToGroupMasters"
        @closed="addToGroupDialog = false"
        @saved="loadData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

import HTTP from "@/helpers/http-common";
import EndpointStatus from "./EndpointStatus.vue";
import TabsView from "./TabsView.vue";
import AddGroup from "./AddGroup.vue";
import AddSubgroup from "./AddSubgroup.vue";
import EndpointCreate from "./EndpointCreate.vue";
import EndpointAdvs from "./EndpointAdv.vue";
import EndpointGroup from "./EndpointAddToGroup.vue";
export default {
  data() {
    return {
      drawer: false,
      showList: false,
      ready: false,
      advDialogId: "",
      advDialogName: "",
      groups: [],
      subgroups: [],
      endpoints: [],
      selectedEndpointId: "",
      selectedEndpointName: "",
      selectedGroupId: "",
      selectedGroupName: "",
      selectedSubgroupId: "",
      selectedSubgroupName: "",
      searchName: "",
      searchAddress: "",
      searchCity: "",
      searchType: "",
      searchGroup: "",
      selectTypes: [
        { id: "", value: "Все типы" },
        { id: "1", value: "Приложение" },
        { id: "2", value: "Raspberry" },
      ],
      showFilter: false,
      dialogAddGroup: false,
      dialogAddSubgroup: false,
      dialogAddEndpoint: false,
      dialogAdvs: false,
      addToGroupDialog:false,
      addToGroupId: '',
      addToGroupMasters: null,
    };
  },
  components: {
    EndpointStatus,
    TabsView,
    AddGroup,
    AddSubgroup,
    EndpointCreate,
    EndpointAdvs,
    EndpointGroup
  },
  methods: {
    openAdvDialog(id, name) {
      this.dialogAdvs = false;
      this.advDialogId = id;
      this.advDialogName = name;
      this.dialogAdvs = true;
    },
    unsetFilter() {
      this.searchName = "";
      this.searchGroup = "";
      this.searchCity = "";
      this.searchType = "";
    },
    updateOpened(a) {
      this.$store.dispatch("EP_ADD_OPENED", a);
    },
    hstname(s) {
      if (s != "<nil>") {
        return s;
      } else {
        return "пусто";
      }
    },
    version(a) {
      return a == "<nil>" ? "обновить" : a;
    },
    ArrayJoin() {
      for (let g of this.groups) {
        g.uniqTree = g.id+g.name
        g.children = [];
      }
      for (let s of this.subgroups) {
        s.children = [];
      }
      for (let e of this.endpoints) {
        for (let s of this.subgroups) {
          if (e["parent"] == s["id"]) {
            s.children.push(e);
          }
        }
      }
      for (let s of this.subgroups) {
        for (let g of this.groups) {
          if (g["id"] == s["parent"]) {
            for (let e of s.children) {
              e["group"] = s["parent"];
            }
            g.children.push({
              id: s["id"],
              name: s["name"],
              parent: s["parent"],
              uniqTree: s["id"]+s["name"],
              children: s.children
            });
          }
        }
      }
      this.ready = true;
    },
    GroupStat(d) {
      let total = 0;
      let online = 0;
      let sync = 0;
      let offline = 0;
      for (let s of d) {
        if (s.children) {
          //Groups stat
          total += s.children.length;
          if (s.children.length > 0) {
            for (let e of s.children) {
              if (e.minutesago == "<nil>" || e.minutesago >= 3) offline++;
              if (e.minutesago < 3 && e.update == 0) online++;
              if (e.status == 1) sync++;
            }
          }
        } else {
          //Subgroup stat
          total++;
          if (s.minutesago == "<nil>" || s.minutesago >= 3) offline++;
          if (s.minutesago < 3 && s.update == 0) online++;
          if (s.status == 1) sync++;
        }
      }
      return [
        { value: online, color: "success" },
        { value: sync, color: "yellow darken-3" },
        { value: offline, color: "red" },
        { value: total, color: "grey" },
      ];
    },
    endpointStatus(s) {
      if (s.minutesago == "<nil>" || s.minutesago > 3) return "offline";
      if (s.minutesago < 3 && s.update == 0) return "online";
      if (s.update == 3) return "updating";
    },
    loadData() {
      this.ready = false;
      HTTP.GET("/endpoints/group")
        .then((res) => {
          this.groups = res.data;
          HTTP.GET("/endpoints/subgroup")
            .then((res) => {
              this.subgroups = res.data;
              HTTP.GET("/endpoints/endpoints")
                .then((res) => {
                  this.endpoints = res.data;
                  this.endpoints.sort((a,b) => {
                    let x = a.id
                    let y = b.id
                    return x-y
                  })
                  this.ArrayJoin();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openGroupDialog(endpoint,subgroup){
      this.addToGroupId=endpoint
      this.addToGroupMasters = this.endpoints.filter((item) => {
        return item.id != endpoint && item.slave == "0" && item.parent == subgroup
      })
      this.addToGroupDialog=true
    },
    ImMaster(id,group){
      let s = this.endpoints.filter((item) => {
        return item.parent == group && item.slave == id
      })
      if (s.length > 0) {
        return true
      }
      false
    },
    ReleaseGroup(id){
      this.$confirm('Вы действительно желаете отсоеденить услугу от группы?').then(res => {
                if(res){
                    HTTP.POST('/endpoints/slave/'+id, {master: '0'}).then(() => {
                        this.loadData()
                    }).catch((error) => {
                        console.log(error)
                    })
                } 
            })
    }
  },
  async created() {
    while(!this.appReady()){
      await sleep(100)
    }
    if (this.disallowFor(['g_eptuser'])) { //Endpoint user
      HTTP.GET("/endpoints/related").then((res) => {
        this.endpoints=res.data
        this.selectedEndpointId=res.data[0].id
      }).catch((error) => {
        console.log(error)
      })
    } else { //Other users
      this.loadData();
    }
    this.$store.dispatch("WS_SUBSCRIBE", "endpointsMinutesAgo");
  },
  destroyed() {
    this.$store.dispatch("WS_SUBSCRIBE", "endpointsMinutesAgo");
  },
  computed: {
    selectedEndpointInfo() {
      return this.endpoints.filter((e) => {
        return e.id == this.selectedEndpointId;
      });
    },
    selectedEndpointParent() {
      for (let s of this.subgroups) {
        if (this.selectedEndpointInfo[0].parent == s.id) {
          return s.name;
        }
      }
      return "";
    },
    selectGroups() {
      let r = [{ id: "", name: "Все группы" }];
      for (let sg of this.groups) {
        r.push({ id: sg.id, name: sg.name });
      }
      return r;
    },
    filterIn() {
      if (
        this.searchName != "" ||
        this.searchType != "" ||
        this.searchCity != "" ||
        this.searchGroup != ""
      ) {
        return true;
      }
      return false;
    },
    endpointsStatus() {
      return this.$store.getters.getWSData("endpointsMinutesAgo");
    },
    openedTree() {
      return this.$store.getters.ep_opened_tree;
    },
    filteredItems() {
      if (
        this.searchType != "" ||
        this.searchName != "" ||
        this.searchCity != "" ||
        this.searchGroup != ""
      ) {
        let params = [
          { field: "type", value: this.searchType, exact: true },
          { field: "name", value: this.searchName, exact: false },
          { field: "city", value: this.searchCity, exact: false },
          { field: "group", value: this.searchGroup, exact: true },
        ];
        params = params.filter((item) => {
          return item.value != "";
        });
        let endpoints = [];
        for (let subgroup of this.groups) {
          for (let es of subgroup.children) {
            for (let e of es.children) {
              endpoints.push(e);
            }
          }
        }
        let result = [];
        for (let e of endpoints) {
          let match = true;
          for (let fltr of params) {
            switch (fltr.exact) {
              case true:
                if (e[fltr.field] != fltr.value) {
                  match = false;
                }
                break;
              case false:
                if (!e[fltr.field].toLowerCase().includes(fltr.value.toLowerCase())) {
                  match = false;
                }
                break;
            }
          }
          if (match) result.push(e);
        }
        result = result.filter(
          (item, index, ar) => ar.findIndex((t) => t.id === item.id) === index
        );
        return result;
      }
      return this.groups;
    },

  },
  watch: {
    endpointsStatus(v) {
      let j = JSON.parse(v);
      if(this.groups.length > 0){
      for (let g of this.groups) {
        for (let sub of g.children) {
          for (let endpoint of sub.children) {
            for (let e of j) {
              if (e["id"] == endpoint.id) {
                endpoint.minutesago = e["minutesago"];
                endpoint.tunnel = e["tunnelstatus"]
              }
            }
          }
        }
      }
      }
    },
  },
};
</script>

<style scoped>
html {
  margin-left: calc(100vw - 100%);
}
</style>
