<template>
  <v-card elevation="0" class="mx-4">
    <v-overlay :value="overlay" z-index="3"></v-overlay>
    <v-row v-if="!disallowFor(['g_eptuser'])">
      <v-col cols="12" md="5" lg="12">
        <div class="text-h4">{{ info.name }}</div>
        <span class="subtitle-2">Статус: <endpoint-status :id="info.id" /></span
        ><span class="text--secondary caption" v-if="info.minutesago > 3">{{
          lastConnectionTime
        }}</span></v-col
      >
      <v-col lg="3">
        <span v-if="info.type==1 || !hardwareInfoPresent()" class="text--secondary caption">Хостнейм: {{ info.hostname }}</span>
        <span v-if="info.type==2 && hardwareInfoPresent()" class="text--secondary caption">Хостнейм: <a :href="'http://'+localIp+':8080'" target="_blank">{{ info.hostname }}</a></span>
        <br />
        <span class="text--secondary caption">Идентификатор: {{ info.id }}</span><br>
        <span class="text--secondary caption">Версия ПО: {{ info.version }}</span>
      </v-col>

      <v-col>
        <span class="text--secondary caption">Город: {{ info.city }}</span>
        <br />
        <span class="text--secondary caption"
          >Адрес: {{ info.address }}</span
        >
        <br />
        <span class="text--secondary caption">Телефон: {{ info.phone }}</span>
      </v-col>

      <v-col v-if="info.type==2 && hddUsage('total')!='NaN' && hardwareInfoPresent">
        <span>
         <v-progress-linear
          background-color="light-blue"
          :color="usedColor()"
          height="12"
          :value="percentUsed()"
          striped
        >
          <template v-slot:default="{ }">
           <span class="font-weight-bold white--text text-caption">{{ hddUsage('used') }} / {{ hddUsage('total') }}  GB</span>
          </template>
        </v-progress-linear>
        </span>
        <div v-for="item in interfaces" :key="item.id">
          <span class="text--secondary caption"><span :class="item.ip!='not_set'?'success--text font-weight-bold':''">{{ item.name }}</span> [{{ item.ip }}] [{{ item.mac }}]</span>
        </div>
      </v-col>

      <v-col>
        <span v-if="info.type==2 && tunnelStatus>0 && allowFor(['g_root']) && hardwareInfoPresent">
          <v-progress-circular
            v-if="tunnelStatus==1 || awaitTunnel"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-tooltip bottom v-show="tunnelStatus==2">
              <template v-slot:activator="{ on }">
                <v-btn @click="closeTunnel" v-show="info.type==2 && tunnelStatus==2" x-large icon color="error" v-on="on">
                  <v-icon>mdi-google-downasaur</v-icon>
                </v-btn>
              </template>
              <span>Закрыть туннель {{ tunnelPort }}</span>
          </v-tooltip>
        </span>
        <span v-if="info.type==2 && tunnelStatus==0 && allowFor(['g_root']) && hardwareInfoPresent">
        <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="runTunnel" v-show="info.type==2 && tunnelStatus==0" x-large icon color="warning" v-on="on">
                  <v-icon>mdi-google-downasaur</v-icon>
                </v-btn>
              </template>
              <span>Открыть туннель</span>
        </v-tooltip>
        </span>
        <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="downloadImage" v-show="info.type==2 && !imgGenerate && imgDownloading==0" x-large icon color="primary" v-on="on">
                  <v-icon>mdi-harddisk</v-icon>
                </v-btn>
              </template>
              <span>Скачать образ</span>
        </v-tooltip>
        <v-progress-circular
          v-show="info.type==2 && imgGenerate"
          indeterminate
          :size="50"
          :width="10"
          color="primary"
        ></v-progress-circular>
        <v-progress-circular
        v-show="info.type==2 && imgDownloading!=0"
        :rotate="360"
        :size="50"
        :width="10"
        :value="imgDownloading"
        color="primary"
        >
          {{ imgDownloading }}
        </v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs class="mx-4" fixed-tabs v-if="data" center-active>
        <v-tab @click="tabChange()" href="#playlog">Плейлог</v-tab>
        <v-tab-item id="playlog" v-if="render">
          <endpoint-playlog
            v-bind:id="info.id"
            v-bind:name="info.name"
            v-bind:reload="reload"
          />
        </v-tab-item>

        <v-tab @click="tabChange()" href="#playlist" v-if="allowFor(['g_admin','g_manager'])">Коллекции</v-tab>
        <v-tab-item id="playlist" v-if="render">
          <endpoint-playlist
            v-bind:id="info.id"
            v-bind:name="info.name"
            v-bind:reload="reload"
          />
        </v-tab-item>

        <v-tab @click="tabChange()" href="#schedule">Расписание</v-tab>
        <v-tab-item id="schedule" v-if="render">
          <endpoint-schedule
            v-bind:id="info.id"
            v-bind:name="info.name"
            v-bind:reload="reload"
            v-if="render"
          />
        </v-tab-item>

        <v-tab @click="tabChange()" href="#advs" v-if="allowFor(['g_admin','g_manager'])">Реклама</v-tab>
        <v-tab-item id="advs" v-if="render">
          <endpoint-advs-schedule v-bind:id="info.id" v-bind:parentid="info.parent" />
        </v-tab-item>

        <v-tab @click="tabChange()" href="#playnow">Playnow</v-tab>
        <v-tab-item id="playnow" v-if="render">
          <endpoint-playnow v-bind:id="info.id"/>
        </v-tab-item>

        <v-tab @click="tabChange()" href="#edit" v-if="allowFor(['g_admin','g_manager'])">Редактирование</v-tab>
        <v-tab-item id="edit" v-if="render">
          <endpoint-edit @saved="editSaved" v-bind:id="id" v-bind:name="info.name" />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-card>
</template>

<script>
import EndpointPlaylog from "./EndpointPlaylog.vue";
import EndpointPlaylist from "./EndpointPlaylist.vue";
import EndpointSchedule from "./EndpointSchedule.vue";
import EndpointStatus from "./EndpointStatus.vue";
import EndpointEdit from "./EndpointEdit.vue";
import EndpointAdvsSchedule from "./AdvsSchedule.vue";
import EndpointPlaynow from "./Playnow.vue";
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      awaitTunnel: false,
      reload: false,
      render: false,
      imgGenerate: false,
      imgDownloading: 0
    };
  },
  methods: {
    onDownloadProgress() {
      return (progressEvent) => {
        this.imgGenerate=false
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.imgDownloading=percentCompleted
      }
    },
    runTunnel(){
      HTTP.GET("/endpoints/createtunnel/"+this.info.id).then(() => {
        this.awaitTunnel=true
      }).catch((error) => {
        console.log(error)
      })
    },
    closeTunnel(){
      HTTP.GET("/endpoints/createtunnel/"+this.info.id).then(() => {
        this.ssh_port=''
        this.awaitTunnel=false
      }).catch((error) => {
        console.log(error)
      })
    },
    downloadImage(){
      this.imgGenerate=true
      HTTP.DOWNLOAD("/endpoints/getimage/"+this.info.id,this.onDownloadProgress()).then((res) => {
        this.imgDownloading=0
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.info.hostname+'.img');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch((error) => {
        this.imgGenerate=false
        console.log(error)
      })
    },
    async tabChange() {
      this.reload = !this.reload;
      this.render = false;
      await new Promise((r) => setTimeout(r, 200));
      this.render = true;
    },
    editSaved() {
      this.$emit("reloadlist", {});
    },
    hddUsage(type){
      let ret = ""
      let raw = this.info.hddspace.split('/')
      switch (type) {
        case 'total':
          ret = raw[0]/1024
          break
        case 'free':
          ret = raw[2]/1024
          break
        case 'used':
          ret = raw[1]/1024
          break
      }
      return ret.toFixed(2)
    },
    percentUsed(){
      let onePercent = this.hddUsage('total')/100
      let ret = this.hddUsage('used')/onePercent
      return Math.floor(ret)
    },
    usedColor(){
      let ret = ""
      if (this.percentUsed() < 70){
        ret = "success"
      }
      if (this.percentUsed() > 70 && this.percentUsed() < 81){
        ret = "warning"
      }
      if (this.percentUsed() > 81){
        ret = "error"
      }
      return ret
    },
    hardwareInfoPresent(){
      if(this.info.ips == "<nil>" || this.info.hddspace == "<nil>") {
        return false
      }
      return true
    },
  },
  computed: {
    interfaces(){
      let ret = []
      if(this.info.ips!="<nil>"){
        let raw = this.info.ips.split(';')
        for(let i of raw){
          let t = i.split(',')
          ret.push({name: t[0], ip: t[1], mac: t[2]})
        }
      }
      return ret
    },
    localIp(){
      let ret = ""
      for(let i of this.interfaces){
        if(i.ip != "not_set"){
          let t = i.ip.split("/")
          ret = t[0]
          break
        }
      }
      return ret
    },
    tunnelPort(){
      let v = this.$store.getters.getWSData("endpointsMinutesAgo");
      let data = JSON.parse(v)
      var mydata = data.find(obj => {
        return obj.id === this.info.id
      })
      return mydata.ssh_port
    },
    tunnelStatus(){
      let v = this.$store.getters.getWSData("endpointsMinutesAgo");
      if(v!=""){
        let data = JSON.parse(v)
        var mydata = data.find(obj => {
          return obj.id === this.info.id
        })
        if(mydata.tunnelstatus=="<nil>") {
          return -1
        }
        return mydata.tunnelstatus
      }
      return -1
    },
    overlay(){
      if (this.ovl===true){
        return true
      }
      return false
    },
    info() {
      return this.data[0];
    },
    lastConnectionTime() {
      var r = "";
      let j = JSON.parse(this.$store.getters.getWSData("endpointsMinutesAgo"));
      for (let e of j) {
        if (e["id"] == this.id) {
          r = e["minutesago"];
          if (r == "<nil>") {
            r = " всегда";
          } else {
            var now = Math.floor(Date.now() / 1000);
            var lastUnix = now - e["minutesago"] * 60;
            var last = new Date(lastUnix * 1000);
            r =
              " с " +
              last.getFullYear() +
              "-" +
              ("0" + (last.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + last.getDate()).slice(-2) +
              " " +
              ("0" + last.getHours()).slice(-2) +
              ":" +
              ("0" + last.getMinutes()).slice(-2);
          }
        }
      }
      return r;
    },
  },
  created() {
    this.render = true;
  },
  watch: {
    tunnelStatus(v){
      if(v==2){
        this.awaitTunnel=false
      }
    },
    async id() {
      this.reload = !this.reload;
      this.render = false;
      await new Promise((r) => setTimeout(r, 200));
      this.render = true;
    },
  },
  components: {
    EndpointPlaylog,
    EndpointPlaylist,
    EndpointSchedule,
    EndpointStatus,
    EndpointEdit,
    EndpointAdvsSchedule,
    EndpointPlaynow,
  },
  props: ["id", "parentname", "data","ovl"],
};
</script>

<style scoped></style>
