<template>
  <v-card elevation="0" min-height="700">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        Загрузка треков
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-4 pt-4">
      Загрузка треков возможна только при наличии заполненных тегов: "Артист",
      "Наименование", "Альбом", "Жанр", "Издатель".<br /><br />
      Формирование баз происходит автоматически:<br />
      тег "Альбом" - формирует группу базы;<br />
      тег "Жанр" - формирует подгруппу базы.
    </v-card-text>
    <v-card-text class="pa-3">
      <v-row dense>
        <v-col>
          <v-file-input
            counter
            multiple
            show-size
            id="tracks"
            accept=".mp3,audio/*"
            v-model="files"
            color="primary"
            label="Добавьте файлы в формате MP3"
            prepend-icon="mdi-paperclip"
            outlined
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="primary" label small class="white--text">
                {{ text }}
              </v-chip>
              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
          <v-progress-linear
            class="mx-8"
            v-if="uploading"
            :value="progress"
          ></v-progress-linear>
        </v-col>

        <v-col md="2" v-if="files.length > 0">
          <v-btn
            color="primary"
            class="caption"
            @click="upload"
            :disabled="!valid || uploading || parsing"
            >Загрузить</v-btn
          >
        </v-col>
      </v-row>

      <span v-if="files.length > 0">
        <v-row>
          <v-col
            class="mx-8"
            order="first"
            v-show="
              filesProgress.filter((o) => {
                return o.duplicated || o.tagserror;
              }).length > 0
            "
          >
            Ошибки в файлах:
            <v-list-item
              v-for="(item, i) in filesProgress.filter((item) => {
                return item.tagserror || item.duplicated;
              })"
              :key="i"
            >
              <v-list-item-subtitle
                class="red--text"
                v-if="item.tagserror || item.duplicated"
                >{{ item.name }}</v-list-item-subtitle
              >
            </v-list-item>
          </v-col>
        </v-row>

        <v-row class="mx-8 mt-6 mb-2">
          <v-btn small color="warning" class="caption" @click="additional = !additional"
            >Показать загружаемые треки</v-btn
          >
        </v-row>
        <v-row dense>
          <v-col class="mx-4" v-show="additional" transition="slide-x-transition">
            <v-list-item v-for="(item, i) in filesProgress" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <!--<v-list-item-subtitle v-if="!item.tagserror && !item.duplicated">
                  {{ item.album }}/{{ item.genre }}
                </v-list-item-subtitle>
                  <v-list-item-subtitle class="red--text">{{
                    item.tagserror
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="red--text">{{
                    item.duplicated
                  }}</v-list-item-subtitle>
                </div>
                <div v-else>-->
                  <div>
                  </div>
              </v-list-item-content>
              <v-list-item-action v-if="!item.tagserror && !item.duplicated">
                <v-avatar
                  :color="
                    item.result == null
                      ? 'success'
                      : item.result == 'D'
                      ? 'warning'
                      : 'error'
                  "
                  size="34"
                >
                  <span class="white--text">{{
                    item.result == null ? item.progress : item.result
                  }}</span>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </span>

      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5"> Загрузка завершена </v-card-title>
          <v-card-text>
            <p>
              Всего файлов:
              <span class="font-weight-medium">{{
                filesProgress.filter((item) => {
                  return !item.tagserror && !item.duplicated;
                }).length
              }}</span>
            </p>
            <p v-if="accepted > 0" class="mt-0">
              Успешно загружено:
              <span class="success--text font-weight-medium">{{ accepted }}</span>
            </p>
            <p v-if="duplicated.length > 0" class="mt-0">
              Перезаписано:
              <br />
              <span
                v-for="(f, index) in duplicated"
                :key="index"
                class="font-weight-medium"
              >
                {{ f.name }}
                <br/>
                <!--<span class="text--secondary">{{ f.album }}/{{ f.genre }}</span>-->
                <v-divider/>
              </span>
            </p>
            <p v-if="fataled.length > 0" class="mt-0">
              Не загружено(ошибки в файлах):
              <span
                v-for="(f, index) in fataled"
                :key="index"
                class="font-weight-medium"
                >{{ f.name }}</span
              >
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="accept"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
//import { parse } from "id3-parser";
//import { parseV2Tag } from "id3-parser";

//import { convertFileToBuffer } from "id3-parser/lib/universal/helpers";
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      files: [],
      additional: false,
      validFiles: new DataTransfer(),
      filesProgress: [],
      totalUploadSize: null,
      totalLoadedSize: null,
      uploading: false,
      dialog: false,
      parsing: true,
    };
  },
  methods: {
    close() {
      this.$emit("close", {});
    },
    filterFiles() {
      let validTags = this.filesProgress.filter((item) => {
        return !item.tagserror;
      });
      for (let i = 0; i < validTags.length - 1; i++) {
        var duplicate = validTags.slice(i + 1).filter((item) => {
          if (item.artist == validTags[i].artist && item.title == validTags[i].title) {
            item.duplicated = "Дубликат";
            return item;
          }
        });
        if (duplicate.length > 0) {
          validTags[i].duplicated = "Дубликат";
        }
      }
    },
    accept() {
      console.log("Accept upload result");
      this.dialog = false;
      //this.$router.push('/bases')
      this.$emit("finish", {});
    },
    async finishLoad() {
      await new Promise((res) => setTimeout(res, 300));
      this.$router.push("/bases");
    },
    nowLoading(f) {
      return (progressEvent) => {
        for (let fp of this.filesProgress) {
          if (fp.name == f) {
            fp.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        }
      };
    },
    setResult(f, result) {
      for (let fp of this.filesProgress) {
        if (fp.name == f) {
          fp.result = result;
        }
      }
    },
    onUploadProgress(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.progress = percentCompleted;
    },
    upload() {
      this.uploading = true;
      // for(let f of this.files) {
      for (let f of this.filesProgress.filter((item) => {
        return !item.tagserror && !item.duplicated;
      })) {
        this.totalUploadSize += Math.round(f.file.size);
        HTTP.UPLOAD("/basefolders/upload", f.file, this.nowLoading(f.name))
          .then(() => {
            this.totalLoadedSize += Math.round(f.file.size);
          })
          .catch((error) => {
            this.totalLoadedSize += Math.round(f.file.size);
            if (error.response.status == 403) {
              this.setResult(f.file.name, "D");
            }
            if (error.response.status == 500) {
              this.setResult(f.file.name, "F");
            }
          });
      }
    },
  },
  computed: {
    valid() {
      if (
        this.filesProgress.filter((item) => {
          return !item.tagserror && !item.duplicated;
        }).length > 0
      )
        return true;
      return false;
    },
    progress() {
      var v = 0;
      if (this.uploading) {
        let cellCoast = Math.round(this.totalUploadSize / 100);
        v = Math.round(this.totalLoadedSize / cellCoast);
        if (v >= 100) {
          v = 100;
        }
      }
      return v;
    },
    accepted() {
      let t = 0;
      for (let fp of this.filesProgress.filter((item) => {
        return !item.tagserror && !item.duplicated;
      })) {
        if (fp.result == null) {
          t++;
        }
      }
      return t;
    },
    duplicated() {
      let t = [];
      for (let fp of this.filesProgress) {
        if (fp.result == "D") {
          t.push(fp);
        }
      }
      return t;
    },
    fataled() {
      let t = [];
      for (let fp of this.filesProgress) {
        if (fp.result == "F") {
          t.push(fp);
        }
      }
      return t;
    },
  },
  watch: {
    files(v) {
      this.parsing = true;
      this.filesProgress = [];
      //var complited = 0;
      for (let f of v) {
        this.filesProgress.push({
                file: f,
                name: f.name,
                progress: 0,
                result: null,
                artist: null,
                title: null,
                label: null,
                album: null,
                genre: null,
          //      tagserror: "Ошибка в тэгах",
                duplicated: null,
          });
        /*convertFileToBuffer(f)
          .then(parseV2Tag)
          .then((tag) => {
            console.log(tag)
            if (!tag.album || !tag.artist || !tag.title || !tag.publisher || !tag.genre) {
              this.filesProgress.push({
                file: f,
                name: f.name,
                progress: 0,
                result: null,
                artist: null,
                title: null,
                label: null,
                album: null,
                genre: null,
                tagserror: "Ошибка в тэгах",
                duplicated: null,
              });
            } else {
              this.filesProgress.push({
                file: f,
                name: f.name,
                progress: 0,
                result: null,
                artist: tag.artist,
                title: tag.title,
                label: tag.publisher,
                album: tag.album,
                genre: tag.genre,
                tagserror: null,
                duplicated: null,
              });
            }
            complited++;
            if (v.length == complited) {
              this.filterFiles();
              this.parsing = false;
            }
          });*/
      }
      this.parsing = false;
    },
    progress(v) {
      if (v >= 100) {
        this.uploading = false;
        this.dialog = true;
        //this.finishLoad()
      }
    },
  },
  created() {},
  props: [],
};
</script>

<style scoped></style>
