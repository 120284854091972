<template>
    <v-container>
        <v-row class="pt-1" dense>
            <v-col>
                <v-text-field
                    v-model="searchName"
                    label="Поиск по всем базам"
                    clearable
                    append-icon="mdi-magnify"
                    @click:clear="searchName = ''"
                    dense
                ></v-text-field>
            </v-col>
        </v-row>
        
    <v-data-table v-if="this.filelist.length>0"
      v-model="selected"
      dense
      :headers="headers"
      :items="filelist"
      fixed-header
      sort-by="name"
      disable-pagination
      hide-default-footer
      show-select
      style="height: calc(100vh - 290px) !important"
      height="100%"
      @toggle-select-all="selectAllFilesToggle"
    >
      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-simple-checkbox
          :value="selected.indexOf(item) == -1 ? false : true"
          :ripple="false"
          :disabled="item.used > 0"
          @input="
            selected.indexOf(item) == -1
              ? selected.push(item)
              : selected.splice(selected.indexOf(item), 1)
          "
        />
      </template>
      <template v-slot:[`header.actions`]>
        <v-row justify="end" class="px-2">
          <v-btn icon @click="deleteSelected" :disabled="!selected.length > 0">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-row justify="start">
          <v-col md="12"> {{ item.artist }} - {{ item.title }} <span class="text--secondary">[{{ item.album }} / {{ item.genre }}]</span></v-col>
        </v-row>
      </template>

      <template v-slot:[`item.uploaddate`]="{ item }">
        {{ splitDate(item.uploaddate) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" v-if="item.used > 0" color="warning"
                >mdi-lock
              </v-icon>
            </template>
            <span>Используется</span>
          </v-tooltip>
          <v-btn class="mx-2" @click.stop="itemClick(item)" icon color="success">
            <v-icon :color="playIcon(item.id) == 'mdi-play' ? 'succes' : 'warning'">{{
              playIcon(item.id)
            }}</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row justify="center" class="mt-2">
          <!--v-if="renderPlayer" -->
          <player
            @pause="play = ''"
            @playend="play = ''"
            v-bind:name="selectedName"
            v-bind:url="audioSrc"
          />
        </v-row>
      </template>
    </v-data-table>
            
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    import Player from "../Player.vue";

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    export default {
        data(){
            return {
                searchName: "",
                play: "",
                parsing: false,
                filelist: [],
                selected: [],
                selectedName: "",
                renderPlayer: false,
                headers: [
                    {
                    text: "Наименование треков",
                    value: "name",
                    align: "left",
                    width: "70%",
                    sortable: false,
                    fixed: true,
                    },
                    {
                    text: "Дата",
                    value: "uploaddate",
                    align: "left",
                    width: "15%",
                    sortable: true,
                    fixed: true,
                    },
                    {
                    text: "",
                    value: "actions",
                    align: "left",
                    sortable: false,
                    fixed: true,
                    },
                ],
            }
        },
        methods: {
            loadList(){
                this.parsing=true
                HTTP.POST("/basefolders/hotsearch",{name: this.searchName.toLowerCase()}).then((res) => {
                    if(res.data!==null){
                        this.filelist=res.data
                    }
                    this.parsing=false
                }).catch((error) => {
                    console.log(error)
                    this.parsing=false
                })
            },
            selectAllFilesToggle() {
                let disabledCount = this.filelist.filter((item) => {
                    return item.used != 0;
                });
                if (this.selected.length != this.filelist.length - disabledCount.length) {
                    this.selected = this.filelist.filter((item) => {
                    return item.used == 0;
                    });
                } else {
                    this.selected = [];
                }
            },
            splitDate(d) {
                let a = d.split("+");
                return a[0];
            },
            playIcon(id) {
                if (this.play == id) {
                    return "mdi-pause";
                }
                return "mdi-play";
            },
            deleteSelected() {
                this.$confirm("Вы действительно желаете удалить выбранные файлы?").then((res) => {
                    if (res) {
                    this.deleted = this.selected.length;
                    for (let f of this.selected) {
                        HTTP.DELETE("/basefolders/track/" + f.id)
                        .then(() => {
                            this.filelist = this.filelist.filter((track) => track.id != f.id);
                            this.deleted--;
                        })
                        .catch((error) => {
                            this.deleted--;
                            console.log(error);
                            return;
                        });
                    }
                    this.selected = [];
                    }
                });
            },
            async itemClick(a) {
                if (a.id == this.play) {
                    this.play = "";
                    return;
                }
                this.selectedName = a.artist + " - " + a.title;
                this.play = a.id;
                this.renderPlayer = false;
                await sleep(1);
                this.renderPlayer = true;
                //var p = document.getElementById("player");
                //p.volume = 0.6;
            },
        },
        computed: {
            audioSrc() {
                if (this.play == "") {
                    return "";
                }
                return "https://api.akpha.org/basefolders/play/" + this.play;
            },
        },
        created(){
            
        },
        watch: {
            searchName(v){
                if(v===null || v==""){
                    this.filelist=[]
                    return
                }
                this.loadList()
            }
        },
        components: {
            Player
        },
        props:[]
        
    }
</script>

<style scoped>

</style>