var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0","rounded":"0"}},[(_vm.filelist.length > 0)?_c('span',{staticClass:"text--secondary caption"},[_vm._v("Треков: "+_vm._s(_vm.filelist.length))]):_vm._e(),_c('v-row',{staticClass:"pt-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":"Название","clearable":"","append-icon":"mdi-magnify","dense":""},on:{"click:clear":function($event){_vm.searchName = ''}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-select',{attrs:{"items":_vm.selectYears,"item-text":"value","item-value":"id","label":"Год","dense":""},model:{value:(_vm.searchYear),callback:function ($$v) {_vm.searchYear=$$v},expression:"searchYear"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-select',{attrs:{"items":_vm.selectMonths,"disabled":_vm.searchYear == '',"item-text":"value","item-value":"id","label":"Месяц","dense":""},model:{value:(_vm.searchMonth),callback:function ($$v) {_vm.searchMonth=$$v},expression:"searchMonth"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-menu',{attrs:{"dense":"","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","readonly":"","label":"Дата"},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"events":_vm.uploadDates,"first-day-of-week":1,"event-color":"green lighten-1"},on:{"input":_vm.clickDatePicker},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}})],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"warning",attrs:{"disabled":(_vm.searchName == '' || _vm.searchName === null) &&
          _vm.searchYear == '' &&
          _vm.searchMonth == '' &&
          _vm.searchDate == '',"small":""},on:{"click":function($event){_vm.searchName = '';
          _vm.searchYear = '';
          _vm.searchMonth = '';
          _vm.searchDate = '';}}},[_vm._v(" Сбросить ")])],1),_c('v-spacer')],1),_c('div',[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"font-weight-bold pa-0 ma-0 d-inline-flex",attrs:{"cols":"9"}},[_c('v-checkbox',{attrs:{"ripple":false,"dense":"","hide-details":""},on:{"change":_vm.selectAllToggle},model:{value:(_vm.selectAllCheckbox),callback:function ($$v) {_vm.selectAllCheckbox=$$v},expression:"selectAllCheckbox"}}),_c('span',{staticClass:"text--secondary text-subtitle-2"},[_vm._v("Наименование треков")])],1),_c('v-col',{staticClass:"font-weight-bold pa-0 ma-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text--secondary text-subtitle-2"},[_vm._v("Дата")])]),_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.selectedLen==0},on:{"click":_vm.deleteSelected}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)],1)],1),_c('v-divider'),_c('v-virtual-scroll',{staticStyle:{"height":"calc(100vh - 320px) !important"},attrs:{"bench":80,"items":_vm.filteredItems,"item-height":"24"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [(_vm.showList)?_c('file-list-item',{attrs:{"item":item,"selected":item.selected,"playid":_vm.play},on:{"play":_vm.itemClick,"itemSelected":_vm.itemSelected}}):_vm._e()]}}])}),_c('player',{staticClass:"pt-2",attrs:{"name":_vm.selectedName,"url":_vm.audioSrc},on:{"pause":function($event){_vm.play = ''},"playend":function($event){_vm.play = ''}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }