<template>
    <v-container>
        <v-data-table
            class="pt-5"
            v-model="selectedUsers"
            dense
            :headers="headers"
            :items="users"
            fixed-header
            sort-by="name"
            disable-pagination
            hide-default-footer
            show-select
            style="height: calc(100vh - 270px) !important"
            height="100%"
            @toggle-select-all="selectAll"
          >
          <template v-slot:[`item.role`]="{ item }">
              {{ roleById(item.role) }}
          </template>
          <template v-slot:[`item.comment`]="{ item }">
              {{ prettyComment(item.comment) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon x-small>
                <v-icon @click="editUser(item.id)" color="warning">mdi-pencil</v-icon>
              </v-btn>
          </template>
          <template v-slot:[`item.data-table-select`]="{ item }">
                  <v-simple-checkbox
                    :value="selectedUsers.indexOf(item)==-1?false:true"
                    :ripple="false"
                    :disabled="item.role=='1000'"
                    @input="selectedUsers.indexOf(item)==-1?selectedUsers.push(item):selectedUsers.splice(selectedUsers.indexOf(item),1)"
                  />
          </template>
          <template v-slot:[`header.actions`]>
              <v-row justify="end">
              <v-btn icon :disabled="!selectedUsers.length > 0">
                <v-icon color="error" @click="deleteUser()">mdi-delete</v-icon>
              </v-btn>
              </v-row>
          </template>
        </v-data-table>
        <v-dialog v-model="editUserDialog" width="500">
           <edit-user v-bind:user_id="editUserId" @close="editUserDialog=false;editUserId=null;loadUsers();"/>
       </v-dialog>
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    import EditUser from './EditUser.vue'
    export default {
        data(){
            return {
                roles: [],
                users: [],
                selectedUsers: [],
                headers: [
                    {
                        text: "Имя",
                        value: "name",
                        align: "left",
                        sortable: false,
                        fixed: true,
                    },
                    {
                        text: "Логин",
                        value: "login",
                        align: "left",
                        sortable: false,
                        fixed: true,
                    },
                    {
                        text: "Уровень доступа",
                        value: "role",
                        align: "left",
                        sortable: false,
                        fixed: true,
                    },
                    {
                        text: "Комментарий",
                        value: "comment",
                        align: "left",
                        sortable: false,
                        fixed: true,
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "right",
                        sortable: false,
                        fixed: true,
                    },
                ],
                editUserId: null,
                editUserDialog: false,
            }
        },
        methods: {
            editUser(id){
                this.editUserId=id
                this.editUserDialog=true
            },
            loadUsers(){
                HTTP.GET("/users/list").then((res) => {
                    if(this.allowFor(['g_root','g_admin','g_manager'])){
                        this.users = res.data
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            loadRoles(){
                HTTP.GET("/users/roles").then((res) => {
                    if(this.allowFor(['g_root','g_admin','g_manager'])){
                        this.roles=res.data
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            selectAll(){
                let disabledCount = this.users.filter((item) => {
                    return item.role=="1000"
                })
                if(this.selectedUsers.length != this.users.length - disabledCount.length){
                    this.selectedUsers = this.users.filter((item) => {
                        return item.role!="1000"
                    })
                } else {
                    this.selectedUsers = []
                }
            },
            roleById(id){
                let r = this.roles.filter((item) => {
                    return item.id==id
                })
                if(r.length==1) {
                    return r[0].comment
                }
                return id
            },
            prettyComment(s){
                if(s=="<nil>") {
                    return ""
                }
                return s
            },
            deleteUser(){
                this.$confirm("Вы действительно желаете удалить пользователя?").then((res) => {
                    if (res) {
                        for(let u of this.selectedUsers){
                            HTTP.DELETE("/users/delete/"+u.id).then(() => {
                                this.loadUsers()
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        this.selectedUsers=[]
                    }
                })
            }
        },
        computed: {
            
        },
        created(){
            
        },
        mounted(){
            this.loadRoles()
            this.loadUsers()
        },
        watch: {
            refresh(){
                this.loadRoles()
                this.loadUsers()
            }
        },
        components: {
            EditUser,
        },
        props:['refresh']
    }
</script>

<style scoped>

</style>