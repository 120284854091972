<template>
  <v-dialog v-model="filelistDialog" width="600">
    <template v-slot:activator="{ on: filelistDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, attrs }">
          <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...filelistDialog }">
            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>Список роликов</span>
      </v-tooltip>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary" flat>
        <v-toolbar-title class="text-h5 primary white--text">
          {{ name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon class="white--text" @click="filelistDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="px-4 pt-4">
        Добавляйте доступные ролики в рекламный блок.<br />
        Перемещайте их между собой, что бы выстроить нужную последовательность.
      </v-card-text>
      <v-card-text>
        <span v-show="current.length == 0">Список пуст...</span>
        <draggable v-model="current" v-show="current.length >= 1">
          <v-list-item v-for="(item, i) in current" :key="i">
            <v-list-item-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="removeAdvFromBlock(item.relationid)"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Удалить</span>
              </v-tooltip>
              <span class="caption text--secondary">{{
                formatDuration(item.duration)
              }}</span>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </draggable>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="
                append = true;
                loadAdvs();
              "
              color="primary"
              v-bind="attrs"
              v-on="on"
              v-show="append == false"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Добавить ролик в блок</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="addAdvToBlock"
              color="success"
              v-bind="attrs"
              v-on="on"
              v-show="selected.length != 0"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Сохранить</span>
        </v-tooltip>
        <v-select
          v-show="append == true"
          v-model="selected"
          :items="selectItems"
          item-text="name"
          item-value="id"
          single-line
        ></v-select>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/helpers/http-common";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      filelistDialog: false,
      selectItems: [],
      selected: "",
      append: false,
      current: [],
    };
  },
  methods: {
    saveOrder() {
      let formData = new FormData();
      let index = 0;
      for (let i of this.current) {
        index += 1;
        formData.append("items", i.relationid + ":" + index);
      }
      HTTP.PATCH("/endpoints/advblocksfiles/" + this.id, formData)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    loadAdvs() {
      HTTP.GET("/endpoints/adv/" + this.subgroup)
        .then((res) => {
          this.selectItems = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadAdvsBlockContent() {
      HTTP.GET("/endpoints/advblocksfiles/" + this.id)
        .then((res) => {
          this.current = res.data;
          this.saveOrder();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAdvToBlock() {
      let num = this.current.length + 1;
      HTTP.POST("/endpoints/advblocksfiles/" + this.id, {
        fileid: this.selected,
        order: num.toString(),
      })
        .then((res) => {
          console.log(res);
          this.selected = "";
          this.append = false;
          this.loadAdvsBlockContent();
        })
        .catch((error) => {
          console.log(error);
          this.selected = "";
          this.append = false;
        });
    },
    removeAdvFromBlock(advid) {
      this.$confirm("Вы действительно желаете удалить ролик?").then((res) => {
        if (res) {
          HTTP.DELETE("/endpoints/advblocksfiles/" + this.id, { fileid: advid })
            .then((res) => {
              console.log(res);
              this.loadAdvsBlockContent();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    formatDuration(s) {
      return new Date(s * 1000).toISOString().substr(14, 5);
    },
  },
  computed: {},
  created() {
    this.loadAdvsBlockContent();
  },
  components: {
    draggable,
  },
  watch: {
    current(o, n) {
      if (o.length == n.length && o.length != 0) {
        console.log("Old len: " + o.length);
        console.log("New len: " + n.length);
        console.log("Order changed");
        this.saveOrder();
      }
    },
  },
  props: ["subgroup", "id", "name"],
};
</script>

<style scoped></style>
