import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'
import store from './store/index'
import HTTP from './helpers/http-common'

import './assets/sass/style.scss'

Vue.config.productionTip = false

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Да',
  buttonFalseText: 'Нет',
  width: 400,
})

Vue.mixin({
  methods: {
    allowFor: function (a) {
        let allow = false
        let role = this.$store.getters.userRole
        if (role=="g_root") return true;
        a.forEach(element => {
          if (element==role) {
              allow = true
              return
          }
        })        
        return allow
    },
    disallowFor: function (a){
        let allow = false
        let role = this.$store.getters.userRole
        if (role=="g_root") return false
        a.forEach(element => {
          if (element==role) {
            allow = true
            return
          }
        })        
        return allow
    },
    appReady: function() {
      if(this.$store.getters.userRole===null) {
        return false
      }
      return true
    }
  },
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  created() {
    let path = this.$router.currentRoute.path
    if(this.$store.getters.userToken !== undefined && this.$store.getters.userToken !== null && this.$store.getters.userToken != "") {
        console.log("Validate token")
        HTTP.validate().then(() => {
          this.$store.dispatch('STATE_CHANGED',this.$store.getters.userToken)
          this.$store.dispatch('LOAD_MYGROUPS')
          this.$store.dispatch('LOAD_MENU',this.$store.getters.userToken)
        }).catch(() => {
          this.$store.dispatch('STATE_CHANGED')
          if(path != '/login'){
            this.$router.push('/login')
          }
        })
    } else {
        console.log("No token found")
        if(path != '/login') {
          this.$router.push('/login')
        }
    } 
  },
  mounted() {
    this.$store.watch(this.$store.getters.getN, n => {
      if(!n){
        router.push('/login')
      } else {
        
        this.$store.dispatch('WS_CONNECT')
        //this.$store.dispatch('LOAD_MYGROUPS')
        let requestedURI=this.$router.history._startLocation
        let route = this.$store.getters.getLastRoute
        if(route === null || route == "") {
          this.$router.push('/endpoints')
        }
        if(route !== null && route != "") {
          if(route!=requestedURI){
            this.$router.push(requestedURI)
          } else {
            this.$router.push(route)
          }
        }
      }
    })
  }
}).$mount('#app')
