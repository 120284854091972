<template>
    <v-container>
        <v-card elevation="0">
            Активные стримы
            {{ Streams }}
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data(){
            return {
                
            }
        },
        methods: {
            
        },
        computed: {
            Streams(){
                let j = JSON.parse(this.$store.getters.getWSData('activeStreams'))
                return j
            }
        },
        created(){
            this.$store.dispatch('WS_SUBSCRIBE','activeStreams')
        },
    }
</script>

<style scoped>

</style>