<template>
    <div>
        <v-row >
            <v-col>
                <span class="text--secondary caption" v-if="filelist.length>0">Всего {{ filelist.length }}</span>
                <v-row v-if="isStatic" align="center">
                    <v-col cols="4" class="d-inline-flex">
                    <v-switch
                        prepend-icon="mdi-shuffle"
                        v-model="shuffle"
                        @change="changeShuffle"
                        :label="shuffle?'Перемешивание':'По порядку'"
                    ></v-switch>
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" color="grey" class="pl-1 mb-4">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                    <span class="caption">Порядок воспроизведения треков.<br>Перемешивание - треки будут воспроизводиться в случайном порядке.<br>По порядку - в том порядке, в котором они отображены.</span>
                    </v-tooltip>
                    </v-col>
                </v-row>
                
            </v-col>
        </v-row>
       
        <!-- <v-text-field class="mx-3" flat label="Поиск" v-model="search" clearable @click:clear="search=''"></v-text-field> -->
        <v-row v-if="!ready">
            <v-spacer/>
            <v-col>
            <v-progress-circular
                :size="90"
                color="primary"
                indeterminate
            ></v-progress-circular>
            </v-col>
        </v-row>
        <v-list v-if="ready">
                <draggable v-model="filelist">
                    <template slot="header">
                        <v-list-item>
                            <v-checkbox
                                hide-details
                                v-model="selectAllToggle"
                                dense
                                color="primary"
                            />
                            <v-spacer/>
                            <v-btn icon x-small @click="deleteSelected" :disabled="selected.length==0">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>
                    <v-list-item v-for="(item,i) in filelist" :key="i" >
                            <v-checkbox
                                hide-details
                                :value="isSelected(item.plid)"
                                dense
                                color="primary"
                                @click="setToSelected(item.plid)"
                            />
                            <!-- @change="changeSelect(item)" -->
                            <v-list-item-title>
                                <v-btn v-if="!isStatic" icon color="success" @click="copyItem(item)">
                                    <v-icon small>mdi-content-copy</v-icon>
                                </v-btn>
                                {{ showedName(item.name,item.artist,item.title) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-text-field
                                v-model="item.count"
                                v-if="!item.hash"
                                :rules="countRules"
                                class="mt-0 pl-5 pr-5"
                                hide-details
                                dense
                                single-line
                                type="number"
                                style="width: 80px"
                            ></v-text-field>
                            </v-list-item-subtitle>
                        <!--<v-list-item-action>
                            <v-btn :to="'/playlists/delete/item/'+folder_id+'/'+item.plid+'/'+slashToBackslash(item.name)" icon color="red">
                                <v-icon small>mdi-delete-forever</v-icon>
                            </v-btn>
                        </v-list-item-action>-->
                    </v-list-item>
                    
                </draggable>
        </v-list>
        <v-divider></v-divider>
        <v-btn
            v-if="filelist.length>0"
            color="blue darken-1"
            text
            @click="save(false)"
          >
            Сохранить
        </v-btn>
        <v-snackbar
            timeout="2000"
            v-model="snackSuccess"
            absolute
            top
            color="success"
            center
            >
            Изменения сохранены
        </v-snackbar>
    </div>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    import draggable from 'vuedraggable'
    export default {
        data(){
            return {
               selectAllToggle: false,
               filelist: [],
               selected: [],
               shuffle: false,
               drag: false,
               ready: false,
               search: '',
               snackSuccess: false,
               countRules: [
                    v => !!v || "Обязательное поле",
                    v => ( v && v >= 1 ) || "Не может быть меньше 1",
                ]
            }
        },
        methods: {
            deleteSelected(){
                this.$confirm(`Вы действительно желаете удалить выбранные эелементы?`).then(res => {
                    if(res){
                        let deleted = this.selected.length
                        for(let item of this.selected){
                            HTTP.DELETE('/playlistfolders/item/'+this.folder_id+'/'+item).then(()=>{
                                deleted--
                                if(deleted==0){
                                    this.loadList()
                                }
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                    }
                })
                //HTTP.DELETE('/playlistfolders/item/'+this.parent_id+'/'+this.deleted_id)
            },
            isSelected(id){
                if(this.selected.indexOf(id)>=0){
                    return true
                }
                return false
            },
            setToSelected(id){
                if(this.selected.indexOf(id)>=0){
                    this.selected.splice(this.selected.indexOf(id),1)
                } else {
                    this.selected.push(id)
                }
            },
            slashToBackslash(s){
                return s.replace(/\//g, '\\')
            },
            copyItem(item){
                let i = Object.assign({},item)
                i.plid="0"
                this.filelist.push(i)
            },
            showedName(name,artist,title){
                if (artist && title){
                    return artist+" - "+title
                } else {
                    return name
                }
            },
            save(auto){
                let formData = new FormData();
                let index=0
                for(let i of this.filelist){
                    index+=1
                    //formData.append('items',i.id+":"+index+","+i.count)
                    formData.append('items',i.plid+":"+index+","+i.count+","+i.id)
                }
                HTTP.POST_ARRAY('/playlistfolders/order/'+this.folder_id,formData).then(() => {
                    if(!auto){
                        this.snackSuccess=true
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            changeShuffle(){
                HTTP.PATCH("/playlistfolders/playlist/"+this.folder_id,{shuffle: this.shuffle.toString()})
            },
            loadList(){
                this.selected=[]
                this.selectAllToggle=false
                if(this.folder_id=="0"){
                    return
                }
                this.ready=false
                this.selectAllChechbox=false
                HTTP.GET('/playlistfolders/playlist/'+this.folder_id).then((res) => {
                    this.filelist=res.data
                    if(res.data.length > 0){
                        if (res.data[0].shuffle=="1"){
                            this.shuffle=true
                        } else {
                            this.shuffle=false
                        }
                    }
                    this.ready=true
                    this.save(true)
                }).catch((error) => {
                    console.log(error)
                })
            },
        },
        computed: {
            loadedItems(){
                return this.filelist
            },
            isStatic(){
                let a = this.folders.filter((item) => {
                    return item.id==this.folder_id && item.dynamic=="0"
                })
                if(a.length > 0) {
                    return true
                }
                return false
            },
            isShuffled(){
                let a = this.folders.filter((item) => {
                    return item.id==this.folder_id && item.shuffle=="1"
                })
                if(a.length > 0) {
                    return true
                }
                return false
            },
            filteredItems() {
                return this.filelist.filter(item => {
                    if(this.search==''){
                        return item 
                    }
                    return item.name.toLowerCase().includes(this.search.toLowerCase())
                })
            },
           isPlay(){
               return this.play
           }
        },
        components: {
            draggable
        },
        created(){
            this.loadList()
        },
        destroyed(){
            this.selected=[]
        },
        watch: {
            folder_id(){
                this.loadList()
            },
            selectAllToggle(v){
                console.log(`select all toogle`)
                if(v){
                    this.selected=[]
                    for(let f of this.filelist){
                        this.selected.push(f.plid)
                    }
                } else {
                    this.selected=[]
                }
                console.log(this.selected)
            }
        },
        props: ['folder_id','folders']
    }
</script>

<style scoped>
.v-textfield {
  border: 1px solid #4455aa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding-left: 22px;
  max-width: 10px;
}

.v-tooltip__content {
    background-color: rgba(87, 83, 83, 0.9);
    color: white;
    font-size: 12px !important;
    opacity: 1 !important;
    border-radius: 10px;
    border-width: 5px 5px 5px 5px;
}


</style>