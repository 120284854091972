<template>
    <v-container>
            
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                
            }
        },
        methods: {
            
        },
        computed: {
            
        },
        created(){
            this.$confirm('Вы действительно желаете удалить '+this.deleted_name+'?').then(res => {
                if(res){
                    HTTP.DELETE('/playlistfolders/item/'+this.parent_id+'/'+this.deleted_id).then(() => {
                        this.$router.go(-1)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    this.$router.go(-1)
                }
            })
        },
        props:['parent_id','deleted_id','deleted_name']
        
    }
</script>

<style scoped>

</style>