<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row align="center" justify="center">
      <v-form>
        <v-switch
          v-if="edit_id == '' || edit_id===undefined"
          v-model="dynamic"
          inset
          :label="dynamic == 0 ? 'Статический' : 'Динамический'"
        ></v-switch>
        <v-text-field v-model="baseName">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Имя
          </template>
        </v-text-field>
        <!--<v-color-picker
                        v-if="this.id!=0"
                        v-model="color"
                        class="ma-2"
                        dot-size="30"
                    ></v-color-picker>-->
        <v-btn class="mr-4" color="success" @click="createBase" :disabled="!valid">Сохранить</v-btn>
      </v-form>
    </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      baseName: "",
      id: "0",
      dynamic: 1,
      color: "#FF00FF",
    };
  },
  methods: {
    createBase() {
      if(this.edit_id!="" && this.edit_id!==undefined){
        HTTP.PATCH("/playlistfolders/base", {
          id: this.edit_id,
          parent_id: this.parent_id,
          name: this.baseName,
          color: this.color,
          dynamic: this.dynamic == 0 ? "0" : "1",
        })
          .then(() => {
            this.$emit("finish",{});
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        HTTP.POST("/playlistfolders/base", {
          id: this.parent_id,
          parent_id: this.parent_id,
          name: this.baseName,
          color: this.color,
          dynamic: this.dynamic == 0 ? "0" : "1",
        })
          .then(() => {
             this.$emit("finish",{});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    close() {
      this.$emit("close", {})
      this.baseName=""
    },
  },
  computed: {
    valid() {
      if (this.baseName.length > 0) return true;
      return false;
    },
    title(){
      if(this.edit_id!==undefined && this.edit_id!="" && this.edit_name!="" && this.edit_name!=undefined){
        return "Редактировать коллекцию"
      }
      return "Создать коллекцию"
    }
  },
  watch:{
    edit_id(){
      if(this.edit_id!==undefined && this.edit_name!=undefined && this.edit_id!=""){
        this.id=this.edit_id
        this.baseName=this.edit_name
      } else {
        this.id="0"
        this.baseName=""
      }
    }
  },
  created() {
    if(this.edit_id!==undefined && this.edit_name!=undefined){
      this.id=this.edit_id
      this.baseName=this.edit_name
    }
  },
  props: ["parent_id","edit_id","edit_name"],
};
</script>

<style scoped></style>
