<template>
<v-dialog
    persistent
    v-model="dialog"
    scrollable
    max-width="600px"
>
    <template v-slot:activator="{ on: d }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on: t }">
                <v-btn
                    small
                    icon
                    color="primary"
                    v-on="d"
                >
                    <v-icon v-on="t">mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Добавить</span>
        </v-tooltip>
    </template>
    <v-card>
        <v-card-title>Выберите коллекцию</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
                <v-treeview
                    v-model="selection"
                    v-if="ready"
                    :active.sync="active"
                    :items="rootfolders"
                    transition
                    selectable
                    item-disabled="empty"
                >
                </v-treeview>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="Add"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="warning"
            text
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                dialog: false,
                ready: false,
                active: [],
                selection: [],
                dialogm1: null,
                rootfolders: [],
                subfolders: []
            }
        },
        methods: {
            cancel(){
                this.$confirm('Все изменения будут потеряны. Вы уверенны?').then(res => {
                    if(res){
                        this.$emit('dialog-closed', this.playlist_id)
                        this.dialog=false
                    } else {
                        this.dialog=true
                    }
                })
            },
            Add(){
                let formData = new FormData();
                for(let b of this.selection){
                    formData.append('items',b)
                }
                HTTP.POST_ARRAY('/playlistfolders/playlist/'+this.playlist_id,formData).then(() => {
                    this.$emit('saved', this.playlist_id)
                    this.dialog=false
                }).catch((error) => {
                    console.log(error)
                })
            },
            ArrayJoin(){
                for (let rf of this.rootfolders){
                    rf.children=[]
                }
                for (let sf of this.subfolders){
                    for(let rf of this.rootfolders){
                        if(rf.id==sf.parent){
                            rf.children.push(sf)
                        }
                    }
                }
                for (let rf of this.rootfolders){
                    if (rf.children.length == 0){
                        rf.empty=true
                    }
                }
                HTTP.GET('/playlistfolders/playlist/'+this.playlist_id).then((res) => {
                        for(let s of res.data){
                            this.selection.push(s.id)
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                this.ready=true
            }
        },
        created() {
            HTTP.GET('/basefolders/bases').then((res) => {
                this.rootfolders = res.data
                HTTP.GET('/basefolders/subbases').then((res) => {
                    this.subfolders = res.data
                    this.ArrayJoin()
                }).catch((error) => {
                    console.log(error)
                })
            }).catch((error) =>{
                console.log(error)
            })
        },
        props: ['playlist_id']
    }
</script>

<style scoped>

</style>
