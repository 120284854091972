<template>
    <v-container>
        Нет такой страницы
    </v-container>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style scoped>

</style>