<template>
    <v-card elevation="0">
        <v-toolbar color="primary" flat>
            <v-toolbar-title class="text-h5 primary white--text">
                Добавить услугу в группу 
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon class="white--text" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
            <v-select
            v-model="select"
            :items="masters"
            item-value="id"
            label="Master"
            persistent-hint
            return-object
            single-line
            >
            <template slot="selection" slot-scope="data">
                {{ data.item.name }} [{{ data.item.id }}]
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.name }} [{{ data.item.id }}]
            </template>
            </v-select>
            <v-snackbar
                timeout="1500"
                v-model="snackSuccess"
                absolute
                top
                color="success"
                center
            >
                Сохранено
            </v-snackbar>
            <v-snackbar timeout="1500" v-model="snackError" absolute top color="warning" center>
                Ошибка сохранения
            </v-snackbar>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn color="success" :disabled="select==''" @click="save">
                Сохранить
            </v-btn>
        </v-card-actions>
    </v-card>    
</template>

<script>
    import HTTP from "@/helpers/http-common";
    export default {
        data(){
            return {
                select: '',
                snackSuccess: false,
                snackError: false,
            }
        },
        methods: {
            close() {
                this.$emit("closed", {});
            },
            save(){
                HTTP.POST('/endpoints/slave/'+this.id, {master: this.select.id}).then(() => {
                    this.snackSuccess=true
                    this.$emit("saved",{})
                    this.$emit("closed", {});
                }).catch((error) => {
                    this.snackError=true
                    console.log(error)
                })
            }
        },
        computed: {
        
        },
        created(){

        },
        watch: {
            id(){
                this.select=''
            }
        },
        props:["id","masters"]
        
    }
</script>

<style scoped>

</style>