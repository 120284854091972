var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogEditGroup),callback:function ($$v) {_vm.dialogEditGroup=$$v},expression:"dialogEditGroup"}},[_c('edit-group',{attrs:{"edit_id":_vm.selectedGroupId,"edit_name":_vm.selectedGroupName},on:{"close":function($event){_vm.dialogEditGroup=false;},"finish":function($event){_vm.dialogEditGroup=false;_vm.loadData();}}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogAddPlaylist),callback:function ($$v) {_vm.dialogAddPlaylist=$$v},expression:"dialogAddPlaylist"}},[_c('add-playlist',{attrs:{"parent_id":_vm.selectedGroupId,"edit_id":_vm.selectedPlaylistId,"edit_name":_vm.selectedPlaylistName},on:{"close":function($event){_vm.dialogAddPlaylist=false;},"finish":function($event){_vm.dialogAddPlaylist=false;_vm.loadData();}}})],1),_c('v-col',{attrs:{"md":"6","lg":"5"}},[_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"0"}},[(_vm.ready)?_c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.rootfolders,"activatable":"","open-on-click":"","transition":"","open":_vm.openedTree},on:{"update:active":function($event){_vm.active=$event},"update:open":_vm.updateOpened},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"md":"6","align":"left"}},[(item.parent)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]),_c('v-col',{attrs:{"md":"1","align":"left"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.parent)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.dynamic == 1 ? 'success' : 'warning'}},'v-icon',attrs,false),on),[_vm._v("mdi-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.dynamic == 0 ? "Статичный" : "Динамический"))])])],1),_c('v-col',{attrs:{"md":"1","align":"left"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.parent && item.used > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Используется")])])],1),_c('v-col',{attrs:{"md":"1","align":"left"}},[(item.parent && item.total_files > 0)?_c('span',{staticClass:"text--secondary caption"},[_vm._v("Треков: "+_vm._s(item.total_files))]):_vm._e(),(item.parent && item.total_files == 0)?_c('span',{staticClass:"text--secondary caption"},[_vm._v("Треков: -- 0 -- ")]):_vm._e()])],1),(!item.parent)?_c('v-badge',{attrs:{"inline":"","x-small":"","color":"grey","content":item.children.length > 0 ? item.children.length : '0'}},[_vm._v(" "+_vm._s(item.name)),(_vm.allowFor(['g_root']))?_c('span',{staticClass:"pl-5 text--secondary text-caption"},[_vm._v(_vm._s(item.owner))]):_vm._e()]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.parent)?_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();_vm.selectedGroupId=item.id;_vm.selectedPlaylistId='';_vm.dialogAddPlaylist=true;}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Добавить коллекцию")])]),(item.parent && item.dynamic == 1)?_c('base-browser',{attrs:{"playlist_id":item.id},on:{"dialog-closed":_vm.refresh,"saved":_vm.refresh}}):_vm._e(),(item.parent && item.dynamic == 0)?_c('file-browser',{attrs:{"playlist_id":item.id},on:{"dialog-closed":_vm.refresh,"saved":_vm.refresh}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.parent)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"warning","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.selectedGroupId=item.id;_vm.selectedGroupName=item.name; _vm.dialogEditGroup=true}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.parent)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"error","disabled":item.children.length != 0,"icon":"","to":'/playlists/delete/group/' + item.id + '/' + _vm.slashToBackslash(item.name)}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Удалить")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.parent)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"warning","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.selectedGroupId=item.parent;_vm.selectedPlaylistId=item.id;_vm.selectedPlaylistName=item.name;_vm.dialogAddPlaylist=true;}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.parent)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"error","disabled":item.used != 0,"icon":"","to":'/playlists/delete/playlist/' + item.id + '/' + _vm.slashToBackslash(item.name)}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}}],null,false,730275583)}):_vm._e()],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',{staticClass:"title grey--text text--lighten-1 font-weight-light",staticStyle:{"align-self":"center"}},[_vm._v(" Выберите коллекцию ")]):_c('div',[_c('span',[_vm._v(_vm._s(_vm.selected))]),_c('file-list',{attrs:{"folder_id":_vm.active[0],"folders":_vm.subfolders}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }