<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        Создать услугу
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row>
        <v-col>
          <v-form>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  v-model="form.parentid"
                  :items="subgroups"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                  outlined
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Родительская группа
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  hide-details
                  v-model="form.type"
                  :items="types"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                  outlined
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Тип
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="form.type==2">
                <v-select
                  hide-details
                  v-model="form.tz"
                  :items="tztypes"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                  outlined
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Часовой пояс
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.name">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Наименование
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.jur_name">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Наименование юр.лица
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.inn">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>ИНН
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.dog_number">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Номер договора
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-menu
                  dense
                  v-model="dpdogmenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="caption"
                      hide-details
                      dense
                      readonly
                      v-model="form.dog_date"
                      label="Дата договора"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.dog_date"
                    @input="dpdogmenu = !dpdogmenu"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="dog_pay"
                  min="0"
                  type="number"
                  label="Стоимость услуги"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.city">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Город
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.address">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Адрес
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.email">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Почта
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.phone">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Телефон
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.rao_number"
                  label="Номер договор РАО"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu
                  dense
                  v-model="dpraomenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="caption"
                      hide-details
                      dense
                      readonly
                      v-model="form.rao_date"
                      label="Дата договор РАО"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.rao_date"
                    @input="dpraomenu = !dpraomenu"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  min="0"
                  type="number"
                  v-model="rao_pay"
                  label="Платёж РАО"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.vois_number"
                  label="Номер договор ВОИС"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu
                  dense
                  v-model="dpvoismenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="caption"
                      hide-details
                      dense
                      readonly
                      v-model="form.vois_date"
                      label="Дата договор ВОИС"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.vois_date"
                    @input="dpvoismenu = !dpvoismenu"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  min="0"
                  type="number"
                  v-model="vois_pay"
                  label="Платёж ВОИС"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.inspector"
                  label="Инспектор"
                ></v-text-field>
              </v-col>
              <v-col> </v-col>
              <v-col> </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-col align="right">
                <v-btn
                  class="caption"
                  color="success"
                  @click="createEndpoint"
                  :disabled="!valid"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
            <v-snackbar
              timeout="1200"
              v-model="snackSuccess"
              absolute
              top
              color="success"
              center
            >
              Изменения сохранены
            </v-snackbar>
            <v-snackbar
              timeout="1200"
              v-model="snackError"
              absolute
              top
              color="error"
              center
            >
              Внимание! Ошибка сохранения
            </v-snackbar>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      dpdogmenu: false,
      dpraomenu: false,
      dpvoismenu: false,
      dialogCopy: false,
      snackSuccess: false,
      snackError: false,
      edit: true,
      types: [],
      groups: [],
      subgroups: [],
      form: {
        parentid: null,
        type: null,
        name: null,
        jur_name: null,
        inn: null,
        city: null,
        address: null,
        email: null,
        phone: null,
        dog_date: new Date().toISOString().substr(0, 10),
        dog_number: null,
        dog_pay: "0",
        rao_number: null,
        rao_date: new Date().toISOString().substr(0, 10),
        rao_pay: "0",
        vois_number: null,
        vois_date: new Date().toISOString().substr(0, 10),
        vois_pay: "0",
        inspector: null,
        hostname: null,
        tz: "",
      },
      tztypes: [
        {id:"+02 Europe/Kaliningrad",name:"+02 Калининград"},
        {id:"+03 Europe/Moscow",name:"+03 Москва"},
        {id:"+04 Europe/Samara",name:"+04 Самара"},
        {id:"+05 Europe/Yekaterinburg",name:"+05 Екатеренбург"},
        {id:"+06 Asia/Omsk",name:"+06 Омск"},
        {id:"+07 Asia/Novosibirsk",name:"+07 Новосибирск"},
        {id:"+08 Asia/Irkutsk",name:"+08 Иркутск"},
        {id:"+09 Asia/Yakutsk",name:"+09 Якутск"},
        {id:"+10 Asia/Vladivostok",name:"+10 Владивосток"},
        {id:"+11 Asia/Magadan",name:"+11 Магадан"},
        {id:"+12 Asia/Kamchatka",name:"+12 Камчатка"},
      ],
    };
  },
  computed: {
    
    valid() {
      let reqFields = [
        "type",
        "name",
        "jur_name",
        "inn",
        "dog_number",
        "dog_date",
        "city",
        "address",
        "email",
        "phone",
        //"hostname",
      ];
      let v = true;
      let arr = Object.entries(this.form);
      for (let k of reqFields) {
        for (let el of arr) {
          if ((el[0] == k && el[1] === null) || (el[0] == k && el[1] == "")) {
            v = false;
            break;
          }
        }
      }
      return v;
    },
    rao_pay: {
      get: function () {
        return this.form.rao_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.rao_pay = "0";
        } else {
          this.form.rao_pay = v;
        }
      },
    },
    vois_pay: {
      get: function () {
        return this.form.vois_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.vois_pay = "0";
        } else {
          this.form.vois_pay = v;
        }
      },
    },
    dog_pay: {
      get: function () {
        return this.form.dog_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.dog_pay = "0";
        } else {
          this.form.dog_pay = v;
        }
      },
    },
  },
  methods: {
    createEndpoint() {
      let d = {};
      let arr = Object.entries(this.form);
      for (let el of arr) {
        d[el[0]] = el[1];
      }
      HTTP.POST("/endpoints/endpoint", d)
        .then(() => {
          //this.$router.push('/endpoints')
          this.snackSuccess = true;
          this.$emit("saved", {});
        })
        .catch((error) => {
          this.snackError = true;
          console.log(error);
        });
    },
    close() {
      this.$emit("close", {});
    }
  },

  created() {
    HTTP.GET("/endpoints/type")
      .then((res) => {
        this.types = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.GET("/endpoints/group")
      .then((res) => {
        this.groups = Object.fromEntries(
          res.data.map(e => [e.id, e.name])
        )
        HTTP.GET("/endpoints/subgroup")
          .then((res) => {
            for(let s of res.data){
              s.name=this.groups[s.parent]+` / `+s.name
              s.parent_name=this.groups[s.parent]
              this.subgroups.push(s)
            }
            this.subgroups.sort((a,b) => (a.parent_name > b.parent_name) ? 1 : ((b.parent_name > a.parent_name) ? -1 : 0))
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
      this.form.tz = this.tztypes[1].id
  },
};
</script>

<style scoped></style>
