<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        Редактировать рекламный блок
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="mt-5">
      <v-form>
        <v-text-field dense v-model="block.name" label="Наименование"></v-text-field>
        <v-text-field dense v-model="block.comment" label="Комментарий"></v-text-field>

        <v-row>
          <v-spacer></v-spacer>
          <v-col align="right">
            <v-btn
              class="caption"
              color="success"
              @click="createBlock(block.id)"
              :disabled="block.name.length == 0"
              >Сохранить</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {};
  },
  methods: {
    createBlock(bid) {
      HTTP.POST("/endpoints/advblocks/" + this.parentid, {
        id: bid,
        name: this.block.name,
        comment: this.block.comment,
      })
        .then(() => {
          this.$emit("saved", {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close(){
      this.$emit("closed",{})
    },
    comment(c) {
      if (c == "<nil>") {
        return "Нет комментария";
      }
      return c;
    },
  },
  computed: {},
  created() {},
  props: ["parentid", "block"],
};
</script>

<style scoped></style>
