<template>
    <v-row>
        <v-col>
            <v-btn @click="dialogReport=true" small color="primary">
                Отчет по владельцу
            </v-btn>
        </v-col>
        <v-dialog v-model="dialogReport" width="500">
              <v-card elevation="0">
                <v-toolbar color="primary">
                <v-toolbar-title class="text-h5 primary white--text">
                    Отчет по владельцу
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialogReport=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="selectedOwner"
                                :items="owners"
                                item-text="name"
                                item-value="id"
                                label="Владелец"
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="right">
                            <v-btn @click="downloadReport" :disabled="selectedOwner===null" color="success">
                                Выгрузить
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                dialogReport: false,
                owners: [],
                selectedOwner: null
            }
        },
        methods: {
            loadOwners(){
                HTTP.GET("/reports/owners").then((res) => {
                    this.owners = res.data
                }).catch((error) => {
                    console.log(error)
                })
            },
            downloadReport(){
                HTTP.DOWNLOAD("/reports/report/"+this.selectedOwner,null).then((res) => {
                    var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    let date = new Date().toISOString().substr(0, 10);
                    fileLink.setAttribute('download', `Report_Service_${date}.xlsx`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    this.dialogReport = false
                    this.selectedOwner = null
                }).catch((error) => {
                    console.log(error)
                })
            }        
        },
        computed: {
            
        },
        created(){
            this.loadOwners()
        },
        props:[]
        
    }
</script>

<style scoped>

</style>