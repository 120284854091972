<template>
  <span>
    <!-- <span :class="statusClass">{{ statusText }}</span><span class="red--text" v-if="statusText == 'offline'">{{ lastConnectionTime }}</span> -->
    <span :class="statusClass">{{ statusText }}</span><span class="red--text" v-if="statusText == 'updating' && !short"> established time: {{ progress }}</span>
  </span>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    lastConnectionTime() {
      var r = "";
      let j = JSON.parse(this.$store.getters.getWSData("endpointsMinutesAgo"));
      for (let e of j) {
        if (e["id"] == this.id) {
          console.log(j)
          r = e["minutesago"];
          if (r == "<nil>") {
            r = " всегда";
          } else {
            var now = Math.floor(Date.now() / 1000);
            var lastUnix = now - e["minutesago"] * 60;
            var last = new Date(lastUnix * 1000);
            r =
              " с " +
              last.getFullYear() +
              "-" +
              ("0" + (last.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + last.getDate()).slice(-2) +
              " " +
              ("0" + last.getHours()).slice(-2) +
              ":" +
              ("0" + last.getMinutes()).slice(-2);
          }
        }
      }
      return r;
    },
    statusText() {
       if(this.$store.getters.getWSData("endpointsMinutesAgo")==""){
        return 
      }
      let j = JSON.parse(this.$store.getters.getWSData("endpointsMinutesAgo"));
      let m = "";
      let u = "";
      for (let e of j) {
        if (e["id"] == this.id) {
          m = e["minutesago"];
          u = e["status"];
        }
      }
      if (m=="" ||m == "<nil>" || m >= 3) return "offline";
      if (m < 3 && u == 0) return "online";
      if (m < 3 && u == 1) return "updating";
      return "unknow"
    },
    statusClass() {
      if(this.$store.getters.getWSData("endpointsMinutesAgo")==""){
        return 
      }
      let j = JSON.parse(this.$store.getters.getWSData("endpointsMinutesAgo"));
      let m = "";
      let u = "";
      for (let e of j) {
        if (e["id"] == this.id) {
          m = e["minutesago"];
          u = e["status"];
        }
      }

      if (m=="" || m == "<nil>" || m >= 3) return "red--text";
      if (m < 3 && u == 0) return "success--text";
      if (m < 3 && u == 1) return "warning--text";
      return ""
    },
    progress(){
      let v = "00:00:00"
      let j = JSON.parse(this.$store.getters.getWSData("endpointsMinutesAgo"));
      for (let e of j) {
        if (e["id"] == this.id && e.updateprogress!="<nil>") {
          var date = new Date(null)
          date.setSeconds(e.updateprogress)
          v = date.toISOString().substr(11, 8)
        }
      }
      return v
    },
  },
  created() {
  },
  props: ["id","short"],
};
</script>

<style scoped></style>
