<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        Редактирование рекламного блока
        
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field disabled :value="form.name">
            {{ form.name }}
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-list-item dense class="px-0" ripple @click="toggleDay">
          <v-icon left :color="form.day.length > 0 ? 'primary' : ''">
            {{ iconDays }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title> Все дни недели </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-col md="1" v-for="(day, index) in daysSelect" :key="index">
          <v-checkbox
            hide-details
            dense
            v-model="form.day"
            :label="daysPrettyText(day.value)"
            :value="day.value"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider />
      <br />

      <v-row class="mt-5" dense>
        <v-list-item dense class="px-0" ripple @click="toggleHour">
          <v-icon left :color="form.hour.length > 0 ? 'primary' : ''">
            {{ iconHours }}
          </v-icon>
          <v-list-item-title> Выбрать все часы </v-list-item-title>
        </v-list-item>
        <v-col md="1" v-for="(hour, index) in hoursSelect" :key="index">
          <v-checkbox
            dense
            hide-details
            v-model="form.hour"
            :label="hoursPrettyText(hour.value)"
            :value="hour.value"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="mt-5">
        <v-col md="1" v-for="(minute, index) in minutesSelect" :key="index">
          <v-checkbox
            dense
            hide-details
            v-model="form.minute"
            :label="minute.name"
            :value="minute.value"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn :disabled="!valid" @click="addToSchedule" large color="success">
            Сохранить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      :timeout="-1"
      v-model="snackFreeMark"
      absolute
      bottom
      color="error"
      outlined
      right
    >
      Ошибка! Пересечение с блоком <b>{{ crossedName }}</b>
      <template v-slot:action="{ attrs }">
        <v-btn color="error" icon small v-bind="attrs" @click="snackFreeMark = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      snackFreeMark: false,
      crossedName: "",
      form: {
        name: null,
        eid: null,
        day: [],
        hour: [],
        minute: [],
        advblockid: null,
      },
      daysSelect: [
        { name: "Понедельник", value: "0" },
        { name: "Вторник", value: "1" },
        { name: "Среда", value: "2" },
        { name: "Четверг", value: "3" },
        { name: "Пятница", value: "4" },
        { name: "Суббота", value: "5" },
        { name: "Воскресенье", value: "6" },
      ],
    };
  },
  methods: {
    marksIsFree() {
      for (let d of this.form.day) {
        for (let h of this.form.hour) {
          for (let m of this.form.minute) {
            let a = this.current.filter((item) => {
              let rvD = "(^|,)"+d+"(,|$)"
              let rvH = "(^|,)"+h+"(,|$)"
              let rvM = "(^|,)"+m+"(,|$)"
              var rgxpD = new RegExp(rvD, "g");
              var rgxpH = new RegExp(rvH, "g");
              var rgxpM = new RegExp(rvM, "g");
              return  item.ruleid!=this.ruleid && item.day.match(rgxpD)!==null && item.hour.match(rgxpH)!==null && item.minute.match(rgxpM)!==null
            })
            if(a.length != 0){
              this.snackFreeMark = true;
              this.crossedName = a[0].name + " строчка "+a[0].ruleid
              return false
            }
          }
        }
      }
      this.snackFreeMark = false;
      return true;
    },
    toggleDay() {
      if (this.selectAllDays) {
        this.form.day = [];
      } else {
        this.form.day = [];
        for (let d of this.daysSelect) {
          this.form.day.push(d.value);
        }
      }
    },
    toggleHour() {
      if (this.selectAllHours) {
        this.form.hour = [];
      } else {
        this.form.hour = [];
        for (let d of this.hoursSelect) {
          this.form.hour.push(d.value);
        }
      }
    },
    close() {
      this.$emit("closed", {});
    },
    addToSchedule() {
      this.form.eid = this.id;
      let request = {
        eid: this.form.eid,
        blockid: this.form.advblockid,
        ruleid: this.ruleid,
        schedule: {},
      };
      request.schedule.days = this.form.day.sort(function (a, b) {
        return a - b;
      });
      request.schedule.hours = this.form.hour.sort(function (a, b) {
        return a - b;
      });
      request.schedule.minutes = this.form.minute.sort(function (a, b) {
        return a - b;
      });
      HTTP.POST("/endpoints/advschedule", request)
        .then(() => {
          this.$emit("saved", {});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSchedule() {
      this.crossedName=""
      this.snackFreeMark=false
      HTTP.GET("/endpoints/advschedule/" + this.id)
        .then((res) => {
          let t = res.data.filter((item) => {
            return item.ruleid == this.ruleid;
          });
          this.form.advblockid = t[0].blockid;
          this.form.name = t[0].name;
          this.form.day = t[0].day.split(",");
          this.form.hour = t[0].hour.split(",");
          this.form.minute = t[0].minute.split(",");
        })
        .catch((error) => {
          console.log(error);
        });
      /*HTTP.GET("/endpoints/freeadvsmarks/" + this.id + "/" + this.ruleid)
        .then((res) => {
          this.freeMarks = res.data;
        })
        .catch((error) => {
          console.log(error);
        });*/
    },
    daysPrettyText(daysString) {
      let cutes = {
        0: "Пн",
        1: "Вт",
        2: "Cр",
        3: "Чт",
        4: "Пт",
        5: "Сб",
        6: "Вс",
      };
      let str = "";
      let days = daysString.split(",");
      for (let i = 0; i < days.length; i++) {
        if (i == 0) {
          //First day
          str += cutes[days[i]];
          continue;
        }
        if (days[i] - 1 == days[i - 1] && parseInt(days[i]) + 1 == days[i + 1]) {
          //Interval day
          if (str.slice(-1) != "-") {
            str += "-";
          }
          continue;
        }
        if (days[i] - 1 == days[i - 1] && parseInt(days[i]) + 1 != days[i + 1]) {
          //End of interval
          if (str.slice(-1) != "-") {
            str += "," + cutes[days[i]];
          } else {
            str += cutes[days[i]];
          }
          continue;
        }
        if (days[i] - 1 != days[i - 1]) {
          str += "," + cutes[days[i]];
          continue;
        }
        if (parseInt(days[i]) + 1 != days[i + 1]) {
          str += cutes[days[i]] + ",";
          continue;
        }
      }
      return str.replace(/(^,)|(,$)/g, "");
    },
    hoursPrettyText(hoursString) {
      let str = "";
      let hours = hoursString.split(",");
      for (let i = 0; i < hours.length; i++) {
        if (i == 0) {
          str += this.leadingZero(hours[i]);
          continue;
        }
        if (hours[i] - 1 == hours[i - 1] && parseInt(hours[i]) + 1 == hours[i + 1]) {
          if (str.slice(-1) != "-") {
            str += "-";
          }
          continue;
        }
        if (hours[i] - 1 == hours[i - 1] && parseInt(hours[i]) + 1 != hours[i + 1]) {
          //End of interval
          if (str.slice(-1) != "-") {
            str += "," + this.leadingZero(hours[i]);
          } else {
            str += this.leadingZero(hours[i]);
          }
          continue;
        }
        if (hours[i] - 1 != hours[i - 1]) {
          str += "," + this.leadingZero(hours[i]);
          continue;
        }
        if (parseInt(hours[i]) + 1 != hours[i + 1]) {
          str += this.leadingZero(hours[i]) + ",";
          continue;
        }
      }
      return str;
    },
    leadingZero(i) {
      if (i.length == 1) {
        return "0" + i;
      }
      return i;
    },
  },
  computed: {
    selectAllHours() {
      return this.form.hour.length === this.hoursSelect.length;
    },
    selectSomeHours() {
      return this.form.hour.length > 0 && !this.selectAllHours;
    },
    iconHours() {
      if (this.selectAllHours) return "mdi-close-box";
      if (this.selectSomeHours) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllDays() {
      return this.form.day.length === this.daysSelect.length;
    },
    selectSomeDays() {
      return this.form.day.length > 0 && !this.selectAllDays;
    },
    iconDays() {
      if (this.selectAllDays) return "mdi-close-box";
      if (this.selectSomeDays) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    valid() {
      if (
        this.form.day.length == 0 ||
        this.form.hour.length == 0 ||
        this.form.minute.length == 0 ||
        this.form.advblockid == null
      ) {
        return false;
      }
      if (!this.marksIsFree()) {
        return false;
      }
      return true;
    },
    hoursSelect() {
      let a = Array.from({ length: 24 }, (k, v) => {
        let o = {
          name: v.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
          value: v.toString(),
        };
        v++;
        return o;
      });
      return a;
    },
    minutesSelect() {
      let r = [];
      for (let i = 0; i < 60; i++) {
        if (i % 5 == 0) {
          r.push({
            name: i.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
            value: i.toString(),
          });
        }
      }
      return r;
    },
  },
  created() {
    this.loadSchedule();
  },
  watch: {
    ruleid() {
      this.loadSchedule();
    },
  },
  props: ["ruleid", "id","current"],
};
</script>

<style scoped></style>
