<template>
    <v-row>
      <v-dialog v-model="dialogEditGroup" width="500">
        <edit-group @close="dialogEditGroup=false;" @finish="dialogEditGroup=false;loadData();" v-bind:edit_id="selectedGroupId" v-bind:edit_name="selectedGroupName"/>
      </v-dialog>
      <v-dialog v-model="dialogAddPlaylist" width="500">
        <add-playlist @close="dialogAddPlaylist=false;" @finish="dialogAddPlaylist=false;loadData();" v-bind:parent_id="selectedGroupId" v-bind:edit_id="selectedPlaylistId" v-bind:edit_name="selectedPlaylistName"/>
      </v-dialog>
      <v-col md="6" lg="5">
              <v-card elevation="0" class="mt-4">
        <v-treeview
          v-if="ready"
          :active.sync="active"
          :items="rootfolders"
          activatable
          open-on-click
          transition
          @update:open="updateOpened"
          :open="openedTree"
        >
          <template v-slot:label="{ item }">
            <v-row>
              <v-col md="6" align="left">
                <span v-if="item.parent">{{ item.name }}</span>
              </v-col>
              <v-col md="1" align="left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    small
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.parent"
                      :color="item.dynamic == 1 ? 'success' : 'warning'"
                    >mdi-circle
                    </v-icon>
                  </template>
                  <span>{{ item.dynamic == 0 ? "Статичный" : "Динамический" }}</span>
                </v-tooltip>
              </v-col>
              <v-col md="1" align="left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.parent && item.used > 0"
                      color="warning"
                    >mdi-lock
                </v-icon>
                  </template>
                  <span>Используется</span>
                </v-tooltip>
              </v-col>
              <v-col md="1" align="left">
                <span
                  class="text--secondary caption"
                  v-if="item.parent && item.total_files > 0"
                  >Треков: {{ item.total_files }}</span
                >
                <span
                  class="text--secondary caption"
                  v-if="item.parent && item.total_files == 0"
                  >Треков: -- 0 --
                </span>
              </v-col>
            </v-row>

            <v-badge
              inline
              x-small
              v-if="!item.parent"
              color="grey"
              :content="item.children.length > 0 ? item.children.length : '0'"
            >
              {{ item.name }}<span class="pl-5 text--secondary text-caption" v-if="allowFor(['g_root'])">{{item.owner}}</span>
            </v-badge>
          </template>
          <template v-slot:append="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- :to="'/playlists/add/subfolder/' + item.id + '/' + item.name" -->
                <v-btn
                  @click.stop="selectedGroupId=item.id;selectedPlaylistId='';dialogAddPlaylist=true;"
                  icon
                  color="primary"
                  small
                  v-if="!item.parent"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Добавить коллекцию</span>
            </v-tooltip>
            <base-browser
              v-bind:playlist_id="item.id"
              v-if="item.parent && item.dynamic == 1"
              @dialog-closed="refresh"
              @saved="refresh"
            />
            <file-browser
              v-bind:playlist_id="item.id"
              v-if="item.parent && item.dynamic == 0"
              @dialog-closed="refresh"
              @saved="refresh"
            />

            <!-- GROUP MENU -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn 
                  v-if="!item.parent"
                  v-on="on"
                  x-small
                  color="warning"
                  icon
                  @click.stop="selectedGroupId=item.id;selectedGroupName=item.name; dialogEditGroup=true"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-if="!item.parent"  v-on="on" x-small color="error" :disabled="item.children.length != 0" icon :to="'/playlists/delete/group/' + item.id + '/' + slashToBackslash(item.name)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
            <!--<v-menu top right offset-x offset-y v-if="!item.parent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" color="primary">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="selectedGroupId=item.id;selectedGroupName=item.name; dialogEditGroup=true">
                  <v-list-item-subtitle>
                    <span class="primary--text">Редактировать</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.children.length == 0"
                  :to="'/playlists/delete/group/' + item.id + '/' + item.name"
                >
                  <v-list-item-title>
                    <span class="red--text">Удалить</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>-->

            <!-- PLAYLIST MENU -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn 
                  v-if="item.parent"
                  v-on="on"
                  x-small
                  color="warning"
                  icon
                  @click.stop="selectedGroupId=item.parent;selectedPlaylistId=item.id;selectedPlaylistName=item.name;dialogAddPlaylist=true;"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-if="item.parent"  v-on="on" x-small color="error" :disabled="item.used != 0" icon :to="'/playlists/delete/playlist/' + item.id + '/' + slashToBackslash(item.name)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>

            <!--<v-menu top right offset-x offset-y v-if="item.parent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" color="primary">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  @click.stop="selectedGroupId=item.parent;selectedPlaylistId=item.id;selectedPlaylistName=item.name;dialogAddPlaylist=true;"
                >
                  <v-list-item-subtitle>
                    <span class="primary--text">Редактировать</span>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-if="item.used == 0"
                  :to="'/playlists/delete/playlist/' + item.id + '/' + item.name"
                >
                  <v-list-item-title>
                    <span class="red--text">Удалить</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>-->
          </template>
        </v-treeview>
              </v-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
              <v-card elevation="0">
                <v-scroll-y-transition mode="out-in">
                  <div
                    v-if="!selected"
                    class="title grey--text text--lighten-1 font-weight-light"
                    style="align-self: center"
                  >
                    Выберите коллекцию
                  </div>
                  <div v-else>
                    <span>{{ selected }}</span>
                    <file-list v-bind:folder_id="active[0]" v-bind:folders="subfolders"/>
                  </div>
                </v-scroll-y-transition>
              </v-card>
      </v-col>
    </v-row>
</template>

<script>
import HTTP from "@/helpers/http-common";
import FileList from "./FileList";
import BaseBrowser from "./BaseBrowser";
import FileBrowser from "./FileBrowser";
import EditGroup from "./CreatePlaylistGroup.vue";
import AddPlaylist from "./AddPlaylist.vue"
const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default {
  data() {
    return {
      dialogEditGroup: false,
      dialogAddPlaylist: false,
      selectedGroupName: "",
      selectedGroupId: "",
      selectedPlaylistId: "",
      selectedPlaylistName: "",
      ready: false,
      rootfolders: [],
      subfolders: [],
    };
  },
  methods: {
    slashToBackslash(s){
      return s.replace(/\//g, '\\')
    },
    loadData() {
      this.ready = false;
      HTTP.GET("/playlistfolders/bases")
        .then((res) => {
          this.rootfolders = res.data;
          HTTP.GET("/playlistfolders/subbases")
            .then((res) => {
              this.subfolders = res.data;
              this.ArrayJoin();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async refresh(v) {
      let d = [0];
      this.active = d;
      await pause(500);
      d = [v];
      this.active = d;
      this.loadData()
    },
    updateOpened(a) {
      this.$store.dispatch("PLAYLISTS_ADD_OPENED", a);
    },
    ArrayJoin() {
      for (let rf of this.rootfolders) {
        rf.children = [];
      }
      for (let sf of this.subfolders) {
        for (let rf of this.rootfolders) {
          if (rf.id == sf.parent) {
            rf.children.push(sf);
          }
        }
      }
      this.ready = true;
    },
  },
  computed: {
    selected() {
      if (!this.active.length) return undefined;
      let sfname = "";
      let rfname = "";
      const id = this.active[0];
      for (let sf of this.subfolders) {
        if (id == sf.id) {
          sfname = sf.name;
          for (let rf of this.rootfolders) {
            if (sf.parent == rf.id) {
              rfname = rf.name;
            }
          }
        }
      }
      return rfname + "/" + sfname;
    },
    openedTree() {
      return this.$store.getters.playlists_opened_tree;
    },
    active: {
      get: function () {
        return this.$store.getters.playlists_active_tree;
      },
      set: function (a) {
        this.$store.dispatch("PLAYLISTS_ADD_ACTIVE", a);
      },
    },
  },
  components: {
    FileList,
    BaseBrowser,
    FileBrowser,
    EditGroup,
    AddPlaylist
  },
  created() {
    this.loadData();
    /*HTTP.GET("/playlistfolders/bases")
      .then((res) => {
        this.rootfolders = res.data;
        HTTP.GET("/playlistfolders/subbases")
          .then((res) => {
            this.subfolders = res.data;
            this.ArrayJoin();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });*/
  },
  watch: {
    reload() {
      this.loadData();
    },
  },
  props: ["reload"],
};
</script>

<style scoped></style>
