<template>
    <div>
        <v-row align="end" class="ma-0 text-caption">
            <v-col cols="9" class="d-inline-flex ma-0 pa-0">
                <v-checkbox
                    :input-value="selected"
                    :ripple="false"
                    dense
                    hide-details
                    :disabled="item.used > 0"
                    @change="emitSelected"
                ></v-checkbox>
                {{ item.artist }} - {{ item.title }}
            </v-col>
            <v-col cols="2" class="d-inline-flex ma-0 pa-0">
                {{ splitDate(item.uploaddate) }}
            </v-col>
            <v-col class="d-inline-flex ma-0 pa-0">
                <v-btn x-small @click.stop="emitPlay" icon color="success">
                    <v-icon :color="playIcon(item.id) == 'mdi-play' ? 'succes' : 'warning'">{{playIcon(item.id)}}</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on" v-show="item.used > 0" color="warning"
                        >mdi-lock
                    </v-icon>
                    </template>
                    <span>Используется</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-divider/>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                
            }
        },
        methods: {
            playIcon(id) {
                if (this.playid == id) {
                    return "mdi-pause";
                }
                return "mdi-play";
            },
            splitDate(d) {
                let a = d.split("+");
                return a[0];
            },
            emitPlay(){
                this.$emit('play',{id:this.item.id})
            },
            emitSelected(){
                this.$emit('itemSelected',{id:this.item.id})
            }
        },
        computed: {
            
        },
        created(){
            
        },
        watch:{
            play(){
                console.log('play id changed')
            }
        },
        props:['item','selected','playid']
        
    }
</script>

<style scoped>

</style>