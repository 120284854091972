<template>
  <div>
    <v-navigation-drawer absolute temporary v-model="drawer" class="hidden-md-and-up">
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          text
          :key="`navdrawer${i}`"
          :to="item.route"
        >
          <v-list-item-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app elevate-on-scroll dense class="primary white--text">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-app-bar-title><span v-if="myOwner!=3">AKPHA.ORG</span>{{ route }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          class="white--text"
          v-for="(item, i) in menuItems"
          text
          :key="`menuitem${i}`"
          :to="item.route"
        >
          <v-icon center v-html="item.icon"></v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    menuItems() {
      return this.isUserAuthenticated
        ? this.$store.getters.userMenu.menu
        : [{ title: "Войти", route: "/login" }];
    },
    route() {
      if (this.$store.getters.userMenu.menu === undefined) {
        return "";
      }
      let c = this.$route.path;
      let r = this.$store.getters.userMenu.menu.filter((i) => {
        return i.route == c;
      });
      if (r.length > 0) {
        return " / " + r[0].title;
      }
      return "";
    },
    myOwner(){
      return this.$store.getters.userOwnerId
    }
  },
  watch: {
    myOwner(v){
      if(v==3){
        document.title = "Music Solution"
        document.querySelector("link[rel*='icon']").href = "http://musicsolution.ru/fav/favicon-32x32.png";
      }
    }
  }
};
</script>

<style scoped></style>
