import Cookies from 'js-cookie'

export default {
    state: {
        processing: false,
        error: null,
        errorBool: false,
        lastRoute: Cookies.get('route')
    },
    mutations: {
        SET_PROCESSING(state,payload){
            state.processing = payload
        },
        SET_ERROR(state,payload){
            state.error = payload
            state.errorBool = true
        },
        CLEAR_ERROR(state){
            state.error = null
            state.errorBool = false
        },
        SET_LAST_ROUTE(state,payload){
            console.log(payload)
            state.lastRoute = payload
            Cookies.set('route',payload)
        },
        UNSET_ROUTE(state){
            state.lastRoute=null
            Cookies.set('route','')
        }
    },
    getters: {
        getProcessing: (state) => state.processing,
        getError: (state) => state.error,
        getErrorBool: (state) => state.errorBool,
        getLastRoute: (state) => state.lastRoute
    },
    actions: {
        
    }
}
