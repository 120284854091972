<template>
    <v-list-item dense @click="Add(id)" style="font-size: .8rem;">
        <!--<v-list-item-content>-->
            <!--<v-list-item-title :class='{"primary--text" : isActive}'> -->
                <!--                 input-value="active" -->
            <v-checkbox
                hide-details
                v-model="isActive"

                dense
                color="primary"
            />
            <template v:slot="label">
                        <span>{{ name }}<span v-if="path" class="text--secondary"> [{{ path }}]</span></span>
            </template>
            <v-spacer/>
            <v-btn @click.stop="itemClick({id,name})" tile small icon color="success">
                <v-icon :color="playIcon(id) == 'mdi-play' ? 'succes' : 'warning'">{{playIcon(id)}}</v-icon>
            </v-btn>
            <!-- </v-list-item-title> -->
        <!--</v-list-item-content>-->
    </v-list-item>
</template>

<script>
    // function sleep(ms) {
    //     return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    export default {
        data(){
            return {
               
            }
        },
        methods: {
            async itemClick(a) {
                this.$emit("playThis", a.id, a.name)
            },
            playIcon() {
                if(this.isplay){
                    return "mdi-pause"
                }
                return "mdi-play"
                // if (this.play == id) {
                //     return "mdi-pause";
                // }
                // return "mdi-play";
            },
            Add(i){
                this.$store.dispatch('PLAYLISTS_ADD_SELECTED_FILES',i)
            }
        },
        computed: {
            isActive: {
                get: function() {
                    let index = this.$store.getters.playlists_selected_files.indexOf(this.id)
                    if (index == -1){
                        return false
                    }
                    return true
                },
                set: function(){

                }
            },
        },
        created(){
           
        },
        props:['id','name','isplay','path']
        
    }
</script>

<style scoped>

</style>