<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        Рекламный контент
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row>
        <!-- Доступные ролики -->
        <v-col>
          <v-toolbar-title class="text-h5"> Доступные ролики </v-toolbar-title>
          <v-row>
            <v-col v-if="files.length > 0">
              Всего файлов для загрузки: {{ files.length }}
              <br />
              <v-btn
                v-show="files.length > 0"
                :disabled="files.length == 0"
                @click="upload"
                small
                color="primary"
                >Загрузить</v-btn
              >
            </v-col>
            <v-col md="12">
              <v-file-input
                ref="uploader"
                style="color: red"
                dense
                hide-input
                id="tracks"
                accept=".mp3,audio/*"
                v-model="files"
                multiple
                prepend-icon="mdi-folder-upload-outline"
              >
              </v-file-input>
            </v-col>
          </v-row>

          <v-col class="px-0">
            <!-- Список файлов -->
            <v-data-table
              v-model="selected"
              dense
              :headers="headersFiles"
              :items="advsCurrent"
              fixed-header
              sort-by="name"
              disable-pagination
              hide-default-footer
              show-select
              style="height: calc(100vh - 310px) !important"
              height="100%"
              @toggle-select-all="selectAllFilesToggle"
            >
              <template v-slot:[`item.data-table-select`]="{ item }">
                  <v-simple-checkbox
                    :value="selected.indexOf(item)==-1?false:true"
                    :ripple="false"
                    :disabled="item.used>0"
                    @input="selected.indexOf(item)==-1?selected.push(item):selected.splice(selected.indexOf(item),1)"
                  />
              </template>
              <template v-slot:[`header.actions`]>
                <v-row justify="end" class="px-2">
                  <v-btn icon :disabled="!selected.length > 0">
                    <v-icon color="error" @click="deleteAdv()">mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <v-row dense class="mx-0">
                  {{ item.name }}
                </v-row>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-row justify="end" class="px-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.used > 0"
                        color="warning"
                        >mdi-lock
                      </v-icon>
                    </template>
                    <span>Используется</span>
                  </v-tooltip>
                  <v-btn @click.stop="itemClick(item)" icon color="success">
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                </v-row>
              </template>
              <template v-slot:footer>
                <v-row>
                  <player  @playend="play=''" v-bind:name="selectedName" v-bind:url="audioSrc"/>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-col>

        <v-divider vertical></v-divider>
        <!-- Рекламные блоки -->
        <v-col>
          <v-toolbar-title class="text-h5"> Рекламные блоки </v-toolbar-title>
          <v-dialog v-model="dialogAddBlock" width="500">
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="primary"
                    class="white--text"
                    small
                    icon
                    v-on="{ ...tooltip, ...dialog }"
                  >
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </template>
                <span>Создать рекламный блок</span>
              </v-tooltip>
            </template>

            <v-card elevation="0">
              <v-toolbar color="primary" flat>
                <v-toolbar-title class="text-h5 primary white--text">
                  Создать рекламный блок
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="white--text" @click="dialogAddBlock = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="mt-5">
                <v-form>
                  <v-text-field
                    dense
                    v-model="blockTitle"
                    label="Наименование"
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="blockComment"
                    label="Комментарий"
                  ></v-text-field>

                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col align="right">
                      <v-btn
                        class="caption"
                        color="success"
                        @click="createBlock()"
                        :disabled="blockTitle.length == 0"
                        >Сохранить</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-data-table
            class="pt-5"
            v-model="selectedBlocks"
            dense
            :headers="headersBlocks"
            :items="blocksCurrent"
            fixed-header
            sort-by="name"
            disable-pagination
            hide-default-footer
            show-select
            style="height: calc(100vh - 270px) !important"
            height="100%"
            @toggle-select-all="selectAllBlocksToggle"
          >
            <template v-slot:[`item.data-table-select`]="{ item }">
                  <v-simple-checkbox
                    :value="selectedBlocks.indexOf(item)==-1?false:true"
                    :ripple="false"
                    :disabled="item.used>0"
                    @input="selectedBlocks.indexOf(item)==-1?selectedBlocks.push(item):selectedBlocks.splice(selectedBlocks.indexOf(item),1)"
                  />
            </template>
            <template v-slot:[`header.actions`]>
              <v-row justify="end">
              <v-btn icon :disabled="!selectedBlocks.length > 0">
                <v-icon color="error" @click="deleteBlock()">mdi-delete</v-icon>
              </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.used > 0"
                    color="warning"
                    >mdi-lock
                  </v-icon>
                </template>
                <span>Используется</span>
              </v-tooltip>
              <file-list
                v-bind:id="item.id"
                v-bind:name="item.name"
                v-bind:subgroup="id"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    @click="
                      selectedBlock = item;
                      dialogEditBlock = true;
                    "
                    color="warning"
                    class="white--text"
                    x-small
                    icon
                    v-on="{ tooltip }"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-overlay :value="uploading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-dialog v-model="dialogEditBlock" width="600">
        <block-edit
          @saved="
            loadBlocks();
            dialogEditBlock = false;
          "
          @closed="dialogEditBlock = false"
          v-bind:block="selectedBlock"
          v-bind:parentid="id"
        />
      </v-dialog>
      <v-snackbar
        timeout="3000"
        v-model="snackSuccess"
        absolute
        top
        color="success"
        center
      >
        Рекламный блок успешно создан
      </v-snackbar>
      <v-snackbar timeout="3000" v-model="snackError" absolute top color="warning" center>
        Ошибка создания рекламного блока
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
import FileList from "./EndpointAdvBlockFilelist.vue";
import BlockEdit from "./AdvBlockEdit.vue";
import Player from '../Player.vue';
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      selectedName: "",
      renderPlayer: false,
      selected: [],
      selectedBlocks: [],
      selectedBlock: null,
      dialogAddBlock: false,
      dialogEditBlock: false,
      toDeleteBlock: "",
      uploading: false,
      play: "",
      blockTitle: "",
      blockComment: "",
      addBlock: false,
      advsCurrent: [],
      blocksCurrent: [],
      files: [],
      snackSuccess: false,
      snackError: false,
      blocksColors: [
        "#FBD6CE",
        "#F6F0BC",
        "#FEC7C1",
        "#EDFEC1",
        "#B6EDF5",
        "#9BB6F9",
        "#E5B9FE",
        "#D3F9E9",
        "#979EBD",
        "#FFC8E4",
      ],
      headersBlocks: [
        {
          text: "Наименование",
          value: "name",
          align: "left",
          sortable: false,
          fixed: true,
        },
        {
          text: "Комментарий",
          value: "comment",
          align: "left",
          sortable: false,
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          fixed: true,
        },
      ],
      headersFiles: [
        {
          text: "Наименование",
          value: "name",
          align: "left",
          width: "80%",
          sortable: false,
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          fixed: true,
        },
      ],
    };
  },
  methods: {
    selectAllFilesToggle(){
      let disabledCount = this.advsCurrent.filter((item) => {
        return item.used!=0
      })
      if(this.selected.length != this.advsCurrent.length - disabledCount.length){
        this.selected = this.advsCurrent.filter((item) => {
          return item.used==0
        })
      } else {
        this.selected = []
      }
    },
    selectAllBlocksToggle(){
      let disabledCount = this.blocksCurrent.filter((item) => {
        return item.used!=0
      })
      if(this.selectedBlocks.length != this.blocksCurrent.length - disabledCount.length){
        this.selectedBlocks = this.blocksCurrent.filter((item) => {
          return item.used==0
        })
      } else {
        this.selectedBlocks = []
      }
    },
    loadBlocks() {
      HTTP.GET("/endpoints/advblocks/" + this.id)
        .then((res) => {
          this.blocksCurrent = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createBlock() {
      HTTP.POST("/endpoints/advblocks/" + this.id, {
        id: "0",
        name: this.blockTitle,
        comment: this.blockComment,
      })
        .then(() => {
          this.snackSuccess = true;
          this.loadBlocks();
        })
        .catch((error) => {
          this.snackError = false;
          console.log(error);
        });
      this.dialogAddBlock = false;
      this.blockTitle = "";
      this.blockComment = "";
    },
    deleteBlock(block_id) {
      this.toDeleteBlock = block_id;
      this.$confirm("Вы действительно желаете удалить блок?").then((res) => {
        if (res) {
          for (let b of this.selectedBlocks) {
            HTTP.DELETE("/endpoints/advblocks/" + this.id, { id: b.id })
              .then(() => {
                this.loadBlocks();
                this.toDeleteBlock = "";
              })
              .catch((error) => {
                console.log(error);
                this.toDeleteBlock = "";
              });
          }
          this.selectedBlocks = [];
        } else {
          this.toDeleteBlock = "";
        }
      });
    },
    deleteAdv() {
      this.$confirm("Вы действительно желаете удалить ролики?").then((res) => {
        if (res) {
          for (let r of this.selected) {
            HTTP.DELETE("/endpoints/adv/" + r.id)
              .then(() => {
                this.loadAdvs();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          this.selected = [];
        } 
      });
    },
    async itemClick(a) {
      console.log(a)
      if (a == this.play) {
        this.play = null;
        return;
      }
      this.selectedName=a.name
      this.play = a.id;
      this.renderPlayer = false
      await sleep(1);
      this.renderPlayer = true
    },
    comment(c) {
      if (c == "") {
        return "Нет комментария";
      }
      return c;
    },
    upload() {
      this.uploading = true;
      let uploaded = 0;
      for (let f of this.files) {
        HTTP.UPLOAD("/endpoints/adv/" + this.id, f)
          .then(() => {
            uploaded++;
            if (this.files.length == uploaded) {
              this.uploading = false;
              this.files = [];
              this.loadAdvs();
            }
          })
          .catch(() => {
            uploaded++;
            if (this.files.length == uploaded) {
              this.uploading = false;
              this.files = [];
              this.loadAdvs();
            }
          });
      }
    },
    loadAdvs() {
      HTTP.GET("/endpoints/adv/" + this.id)
        .then((res) => {
          this.advsCurrent = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDuration(s) {
      return new Date(s * 1000).toISOString().substr(14, 5);
    },
    close() {
      this.$emit("closed", {});
    },
  },
  computed: {
    audioSrc() {
      if (this.play == "") {
        return "";
      }
      return "https://api.akpha.org/endpoints/playadv/" + this.play;
    },
    isPlay() {
      return this.play;
    },
  },
  created() {
    this.loadAdvs();
    this.loadBlocks();
  },
  mounted() {
    this.$refs.uploader.$el.querySelector("button.v-icon--link").style.color = "#1976d2";
  },
  watch: {
    id() {
      this.loadAdvs();
      this.loadBlocks();
    },
  },
  components: {
    FileList,
    BlockEdit,
    Player
  },
  props: ["id", "name"],
};
</script>

<style scoped></style>
