export default {
    state: {
        bases: {
            openedTree: [],
            active: []
        }
    },
    mutations:{
        BASES_SET_OPENED(state,payload){
            state.bases.openedTree = payload
        },
        BASES_SET_ACTIVE(state,payload){
            state.bases.active = payload
        }
    },
    actions: {
       BASES_ADD_OPENED({commit},payload){
            commit('BASES_SET_OPENED',payload)
        },
        BASES_ADD_ACTIVE({commit},payload){
            commit('BASES_SET_ACTIVE',payload)
        }
    },
    getters: {
        bases_opened_tree: (state) => state.bases.openedTree,
        bases_active_tree: (state) => state.bases.active,
    }
}
