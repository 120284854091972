<template>
    <v-container>
        <v-row align="center" justify="center" class="pt-2">
            <v-btn to="/playlists" 
                    color="primary">
                    <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-spacer/>
            <h3>Редактирование {{ cName }}</h3>
            <v-spacer/>
        </v-row>
        
        <v-row align="center" justify="center">
            <v-progress-circular
            v-if="loading"
            :size="70"
            indeterminate
            color="primary"
            ></v-progress-circular>
            <v-form v-else>
                <v-text-field v-model="cName">
                    <template #label>
                        <span class="red--text"><strong>* </strong></span>Имя
                    </template>
                    </v-text-field>
                    <!--<v-color-picker
                        v-if="this.parent_id!=0"
                        v-model="color"
                        class="ma-2"
                        dot-size="30"
                    ></v-color-picker>-->
                <v-btn
                    class="mr-4"
                    @click="update"
                    :disabled="!valid"
                >
                    Сохранить
                </v-btn>
            </v-form>
        </v-row>
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                cName: "",
                color: '#FF00FF',
                loading: true
            }
        },
        methods: {
            update(){
                HTTP.PATCH('/playlistfolders/playlist/'+this.id,{name:this.cName, color: this.color}).then(() => {
                    this.$router.go(-1)
                }).catch((error) => {
                    console.log(error)
                })
            }
        },
        computed: {
            valid(){
                if (this.cName.length > 0) return true
                return false
            },
        },
        created(){
            this.cName=this.name
            if(this.id!=0){
                HTTP.GET('/playlistfolders/playlistcolor/'+this.id).then((res) => {
                    this.color=res.data[0].color
                    this.loading=false
                }).catch((error) => {
                    console.log(error)
                })
            }
        },
        props:['id','name','parent_id']
        
    }
</script>

<style scoped>

</style>