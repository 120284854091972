<template>
    <v-container>
            
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                
            }
        },
        methods: {
            
        },
        computed: {
            cType(){
                let r=""
                switch (this.type) {
                    case "group":
                        r="группу"
                        break
                    case "subgroup":
                        r="подгруппу"
                        break
                    case "endpoint":
                        r="услугу"
                        break
                }
                return r
            }
        },
        created(){
            this.$confirm('Вы действительно желаете удалить '+this.cType+' '+this.name+'?').then(res => {
                if(res){
                    HTTP.DELETE('/endpoints/'+this.type+'/'+this.id).then(() => {
                        this.$router.go(-1)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    this.$router.go(-1)
                }
            })
        },
        props:['type','id','name']
        
    }
</script>

<style scoped>

</style>