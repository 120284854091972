<template>
    <v-card v-if="ready">
        <v-toolbar color="primary" flat>
            <v-toolbar-title class="text-h5 primary white--text">
                Редактирование пользователя
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon class="white--text" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
            <v-row>
                <v-col>
                    <v-text-field
                      dense
                      :disabled="true"
                      hide-details
                      v-model="userRoleName"
                      label="Уровень доступа"
                    ></v-text-field>
                    <!--<v-select
                        :disabled="true"
                        :items="roles"
                        :item-text="(item) => item.comment"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userRole"
                        label="Уровень доступа"
                    ></v-select>-->
                </v-col>
            </v-row>
            <v-row v-if="allowFor(['g_root']) && userRole!='1000'">
                <v-col>
                    <v-select
                        :disabled="id!='0'"
                        :items="admins"
                        :item-text="(item) => item.name"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userGroup"
                        label="Группа"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="userRole=='3'">
                <v-col>
                    <v-text-field
                      dense
                      :disabled="true"
                      hide-details
                      v-model="userEndpoint"
                      label="Услуга"
                    ></v-text-field>
                    <!--<v-select
                        :disabled="id!='0'"
                        :items="filteredEndpoints"
                        :item-text="(item) => item.name+` [`+item.address+`]`"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userEndpoint"
                        label="Услуга"
                    ></v-select>-->
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userLogin"
                      label="Логин"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userPassword"
                      label="Пароль"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userName"
                      label="Имя"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userComment"
                      label="Комментарий"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row >
                <v-col>
                    
                </v-col>
                <v-col class="text-right">
                    <v-btn dense color="success" :disabled="!valid" @click="updateUser">
                        Сохранить
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
          Логин занят
        </v-snackbar>
    </v-card>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                ready: false,
                roles : [],
                userName: "",
                userLogin: "",
                userPassword: "",
                userComment: "",
                userRole: "",
                userRoleName: "",
                userEndpoint: "",
                id: "0",
                snackError: false
            }
        },
        methods: {
            updateUser(){
                console.log(`Updating user`)
                HTTP.POST("/users/user/"+this.user_id,{name: this.userName, login: this.userLogin, pass: this.userPassword, comment: this.userComment}).then(() => {
                    this.close()
                }).catch((error) => {
                    if(error.response.status==409){
                        this.snackError=true
                    }
                })
            },
            close() {
                this.$emit("close", {})
                this.unsetForm()
            },
            unsetForm(){
                this.userName=""
                this.userLogin=""
                this.userPassword=""
                this.userComment=""
                this.userRole=""
                this.userRoleName=""
                this.id="0"
            },
            loadUserData(){
                HTTP.GET("/users/user/"+this.user_id).then((res) => {
                    this.userName=res.data[0].name
                    this.userLogin=res.data[0].login
                    this.userPassword=res.data[0].pass
                    this.userComment=res.data[0].comment
                    this.userRole=res.data[0].role
                    this.userRoleName=res.data[0].rolename
                    this.userEndpoint=res.data[0].endpoint
                }).catch((error) => {
                    console.log(error)
                })
            },
            loadRoles(){
                HTTP.GET("/users/roles").then((res) => {
                    this.roles=res.data
                    this.ready=true
                }).catch((error) => {
                    console.log(error)
                })
            },
        },
        computed: {
            compUserRole(){
                let a = this.roles.filter((role) => {
                    return role.id==this.userRole
                })
                return a[0].comment
            },
            valid(){
                if (this.userName.length > 0 && this.userPassword.length > 0 && this.userLogin.length > 0) {
                   return true
                }
                return false
            },
        },
        created(){
            this.loadUserData()
            this.loadRoles()
        },
        watch: {
            user_id(v){
                if(v){
                    this.loadUserData()
                }
            }
        },
        props:["user_id"]
        
    }
</script>

<style scoped>

</style>