<template>
   <v-container>
       <v-dialog v-model="addUserDialog" width="500">
           <add-user @saved="addUserDialog=false;change++" @close="addUserDialog=false"/>
       </v-dialog>
        <v-row align="start" class="pt-2">
            <v-col cols="10" offset="1">
                <users-list v-bind:refresh="change"/>
            </v-col>
            <v-spacer/>
            <v-col>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on , attrs }">
                        <v-btn
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="addUserDialog=true"
                        >
                            <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                        <span>Добавить пользователя</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import addUser from '@/components/Users/AddUser.vue'
    import usersList from '@/components/Users/UsersList.vue'
    export default {
        data(){
            return {
                change: 0,
                addUserDialog: false,
            }
        },
        methods: {
            
        },
        computed: {
            
            
        },
        created(){
            
        },
        components: {
            addUser,
            usersList
        },
        props:[]
    }
</script>

<style scoped>

</style>