<template>
    <div>
        <v-container>
            <v-row>
                <v-col md="2" sm="8" class="offset-md-5 offset-sm-2">
                    <v-card flat>
                        <v-form v-model="valid">
                            <v-text-field dense outlined clearable label="Старый пароль" name="old_password" type="password" required v-model="old_password"></v-text-field>
                            <v-text-field dense outlined clearable label="Новый пароль" name="password01" type="password" required v-model="password01" :rules="passwordRules"></v-text-field>
                            <v-text-field dense outlined clearable label="Ещё раз" name="password02" type="password" required v-model="password02" :rules="passwordRules" v-on:keyup.enter="onEnter"></v-text-field>
                        </v-form>
                        <v-btn color="primary" @click="changePassword" :disabled="!valid">Сохранить</v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <span>{{ result }}</span>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                result: null,
                valid: false,
                old_password: null,
                password01: null,
                password02: null,
                passwordRules: [
                    //(v) => !!v || 'Введите пароль',
                    (v) => (v && v.length >= 4) || 'Минимум 4 символа',
                    (v) => (v === this.password01) || 'Пароли должны совпадать'
                ],
            }
        },
        methods: {
            changePassword(){
                HTTP.POST('/selfsettings/password',{oldpw: this.old_password, newpw:this.password02}).then(() => {
                    this.result="Пароль успешно изменен"
                }).catch((error) => {
                    console.log(error)
                    this.result="Ошибка при изменении пароля"
                })
            }
        }
    }
</script>

<style scoped>

</style>
