<template>
  <v-card elevation="2" class="mt-4">
    <v-row>
      <v-col cols="9">
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
      </v-col>
      <v-col cols="3">
        <div id="external-events">
          <v-switch
            class="mt-10"
            :disabled="saveBtn == 1"
            v-model="cUniq"
            :label="cUniq == 0 ? 'Цикличные' : 'Уникальные'"
          ></v-switch>
          <p>
            <strong>Коллекции</strong>
          </p>
          <v-card
            elevation="0"
            dense
            hover
            outlined
            max-width="150"
            v-for="(item, i) in draggables"
            :key="i"
            :uniq="uniq"
            :plid="item.id"
            :fccolor="item.color"
            class="ma-1 fc-event"
            :style="'background:' + item.color"
          >
            <v-card-subtitle class="pa-1 fc-event-text-color">
              {{ item.title }}
            </v-card-subtitle>
          </v-card>
        </div>
        <v-date-picker
          v-if="cUniq == 1"
          v-model="datePicker"
          event-color="green lighten-1"
          :events="uniqMonthEvents"
          no-title
          small
          first-day-of-week="1"
          class="mt-4"
        ></v-date-picker>
        <v-btn
          class="ma-1  caption"
          color="success"
          :disabled="saveBtn == 0 ? true : false"
          @click="save"
          >Сохранить</v-btn
        >
        <v-btn
          class="ma-1  caption"
          color="error"
          @click="clearAll"
          >Очистить всё</v-btn
        >
      </v-col>

      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline"> Что будем делать? </v-card-title>
          <v-card-text>
            Убрать - спрятать подборку в отображении.
            <br />
            Удалить - удалить подборку из расписания.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="
                lastClickedEvent.remove();
                dialog = false;
              "
            >
              Убрать
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                deleteEvent();
                dialog = false;
              "
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
//    import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        droppable: true,
        editable: true,
        timeZone: "local",
        locale: ruLocale,
        allDaySlot: false,
        slotDuration: "00:30",
        height: "auto",
        expandRows: false,
        customButtons: {
          prev: {
            click: () => {
              if (this.saveBtn == 1) {
                if (window.confirm("Были внесены изменения. Сохранить?")) {
                  this.save().then(() => {
                    this.calendarApi.prev();
                  });
                } else {
                  this.calendarApi.prev();
                }
              } else {
                this.calendarApi.prev();
              }
            },
          },
          next: {
            click: () => {
              if (this.saveBtn == 1) {
                if (window.confirm("Были внесены изменения. Сохранить?")) {
                  this.save().then(() => {
                    this.calendarApi.next();
                  });
                } else {
                  this.calendarApi.next();
                }
              } else {
                this.calendarApi.next();
              }
            },
          },
          today: {
            text: "Сегодня",
            click: () => {
              if (this.saveBtn == 1) {
                if (window.confirm("Были внесены изменения. Сохранить?")) {
                  this.save().then(() => {
                    this.calendarApi.today();
                  });
                } else {
                  this.calendarApi.today();
                }
              } else {
                this.calendarApi.today();
              }
            },
          },
        },
        dayCellContent: (u) => {
          var h = { html: "" };
          for (let e of this.uniqEvents) {
            if (u.date.getDay() == e.start.getDay()) {
              h = { html: "<font color='red'>Уникальный день</font>" };
            }
          }
          return h;
        },
        datesSet: (info) => {
          if (!this.calendarApi) {
            this.calendarApi = this.$refs.fullCalendar.getApi();
          }

          var startDate =
            info.start.getFullYear() +
            "_" +
            ("0" + (info.start.getMonth() + 1)).slice(-2) +
            "_" +
            ("0" + info.start.getDate()).slice(-2);
          var endDate =
            info.end.getFullYear() +
            "_" +
            ("0" + (info.end.getMonth() + 1)).slice(-2) +
            "_" +
            ("0" + info.end.getDate()).slice(-2);
          var interval = startDate + "_" + endDate;
          this.interval = interval;
          this.getSchedule(interval);
          this.hidden = [];
          this.saveBtn = 0;
        },
        eventContent: function (args) {
          let startHour = args.event.start.getHours();
          startHour = ("0" + startHour).slice(-2);
          let startMinute = args.event.start.getMinutes();
          startMinute = ("0" + startMinute).slice(-2);
          let endHour = args.event.end.getHours();
          endHour = ("0" + endHour).slice(-2);
          let endMinute = args.event.end.getMinutes();
          endMinute = ("0" + endMinute).slice(-2);
          //let uniq =
          //  args.event.extendedProps.uniq == 0
          //    ? "<p>цикличное</p>"
          //    : '<p><font color="black" face="monospace">УНИКАЛЬНОЕ</font></p>';
          return {
            html: "<b>" + startHour + ":" + startMinute + "-" + endHour + ":" + endMinute,
            // +
            //"<br>" +
            //args.event.title +
            //"</b>" +
            //uniq,
          };
        },
        eventClick: (u) => {
          // this.lastClickedEvent=u.event
          // this.dialog=true

          if (window.confirm("Удалить интервал?")) {
            this.saveBtn = 1;
            if (u.event.id != "") this.deleted.push(u.event);
            u.event.remove();
          }
        },
        eventResize: (u) => {
          this.saveBtn = 1;
          if (u.event.start.getDate() != u.event.end.getDate()) {
            let nextDay = new Date(u.event.start)
            nextDay.setDate(u.event.start.getDate()+1)
            if(nextDay.getDate() == u.event.end.getDate() && u.event.end.getHours()== 0 && u.event.end.getMinutes()==0){
              this.saveBtn = 1;
              return;
            }
            u.revert();
          }
        },
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
        },
        nowIndicator: true,
        eventReceive: (u) => {
          this.saveBtn = 1;
          this.lastAppendEvent = u.event;
        },
        eventDrop: (u) => {//Shot then event move betwen cells
          if (u.event.start.getDate() != u.event.end.getDate()) {
            let nextDay = new Date(u.event.start)
            nextDay.setDate(u.event.start.getDate()+1)
            if(nextDay.getDate() != u.event.end.getDate() || u.event.end.getHours() != 0 || u.event.end.getMinutes() != 0){
              u.revert();
              return;
            }
          }

          this.saveBtn = 1;
          var d = u.event.start.getDay();
          var isUniqDay = false;
          var isEmptyDay = true;
          for (let e of this.calendarApi.getEvents()) {
            if (e.id == u.event.id) {
              continue;
            }
            if (e.start.getDay() == d) {
              isEmptyDay = false;
              break;
            }
          }
          for (let e of this.hidden) {
            if (e.id == u.event.id) {
              continue;
            }
            if (e.start.getDay() == d) {
              isEmptyDay = false;
              break;
            }
          }
          if (isEmptyDay) {
            if(u.event.start.getDay()==u.oldEvent.start.getDay()){
              return
            } else {
              let clone = {
                title: u.oldEvent.title,
                start: u.oldEvent.start,
                end: u.oldEvent.end,
                backgroundColor: u.oldEvent.backgroundColor,
                extendedProps : {
                  plid: u.oldEvent.extendedProps.plid,
                  uniq: u.oldEvent.extendedProps.uniq
                }
              }
              this.calendarApi.addEvent(clone)
              return
            }
          }
          for (let e of this.calendarApi.getEvents()) {
            if (e.id == u.event.id) {
              continue;
            }
            if (e.start.getDay() == d && e.extendedProps.uniq == 1) {
              isUniqDay = true;
              break;
            }
          }
          for (let e of this.hidden) {
            if (e.id == u.event.id) {
              continue;
            }
            if (e.start.getDay() == d) {
              isEmptyDay = false;
              break;
            }
          }
          if (
            (isUniqDay && u.event.extendedProps.uniq != 1) ||
            (!isUniqDay && u.event.extendedProps.uniq != 0)
          ) {
            
            u.revert();
          }
          if(u.event.start.getDay()!=u.oldEvent.start.getDay()){
              let clone = {
                title: u.oldEvent.title,
                start: u.oldEvent.start,
                end: u.oldEvent.end,
                backgroundColor: u.oldEvent.backgroundColor,
                extendedProps : {
                  plid: u.oldEvent.extendedProps.plid,
                  uniq: u.oldEvent.extendedProps.uniq
                }
              }
              this.calendarApi.addEvent(clone)
            }
        },
      },
      // dayHeaderContent: (args) => {
      //     console.log("day header")
      //     console.log(args)
      //     return {html:'Foo'}
      // },
      localEvents: {
        uniq: [],
        cycle: [],
      },
      calendarApi: null,
      uniqEvents: [],
      uniqMonthEvents: [], // Events for dotted month calendar
      cycleEvents: [],
      draggables: [],
      dragContainer: null,
      deleted: [],
      hidden: [],
      lastAppendEvent: null,
      lastClickedEvent: null,
      uniq: 0,
      saveBtn: 0,
      dialog: false,
      datePicker: new Date().toISOString().slice(0, 10),
      interval: null,
    };
  },
  methods: {
    clearAll(){
      this.$confirm('Вы действительно желаете очистить всё расписание?').then(res => {
                if(res){
                    this.calendarApi.removeAllEvents();
                    HTTP.DELETE('/endpoints/schedule/'+this.id).then(() => {
                      this.getSchedule(this.interval);
                    }).catch((error) => {
                        console.log(error)
                    })
                } 
            })
    },
    loadData() {
      this.saveBtn = 0;
      this.datePicker = new Date().toISOString().slice(0, 10);
      this.calendarApi.gotoDate(this.datePicker);
      this.getSchedule(this.interval);
      //var startDate=info.start.getFullYear()+"_"+("0"+(info.start.getMonth()+1)).slice(-2)+"_"+("0"+info.start.getDate()).slice(-2)
      //var endDate=info.end.getFullYear()+"_"+("0"+(info.end.getMonth()+1)).slice(-2)+"_"+("0"+info.end.getDate()).slice(-2)
      // var interval=startDate+"_"+endDate
      //this.getSchedule(interval)
      this.getPlaylists();
      this.getUniqEvents();
    },
    renderView() {
      this.calendarApi.removeAllEvents();
      if (this.uniq == 0) {
        for (let e of this.cycleEvents) {
          this.calendarApi.addEvent(e);
        }
      } else {
        for (let e of this.uniqEvents) {
          this.calendarApi.addEvent(e);
        }
      }
      //this.calendarApi.render();
    },
    deleteEvent() {
      this.saveBtn = 1;
      this.deleted.push(this.lastClickedEvent);
      this.lastClickedEvent.remove();
    },
    delete() {
      if (this.deleted.length > 0) {
        let formData = new FormData();
        for (let e of this.deleted) {
          formData.append("items", e.id);
        }
        this.deleted = [];
        HTTP.DELETE("/endpoints/scheduleitems/" + this.id, formData)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    create() {
      let scheduleRaw = this.calendarApi.getEvents();
      for (let e of this.hidden) {
        scheduleRaw.push(e);
      }
      let formData = new FormData();
      for (let e of scheduleRaw) {
        let dateStrStart =
          e.start.getFullYear() +
          "-" +
          ("0" + (e.start.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + e.start.getDate()).slice(-2) +
          " " +
          ("0" + e.start.getHours()).slice(-2) +
          ":" +
          ("0" + e.start.getMinutes()).slice(-2) +
          ":00";
        let dateStrEnd =
          e.end.getFullYear() +
          "-" +
          ("0" + (e.end.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + e.end.getDate()).slice(-2) +
          " " +
          ("0" + e.end.getHours()).slice(-2) +
          ":" +
          ("0" + e.end.getMinutes()).slice(-2) +
          ":00";
        formData.append(
          "items",
          JSON.stringify({
            id: e.id,
            uniq: e.extendedProps.uniq,
            plid: e.extendedProps.plid,
            start: dateStrStart,
            end: dateStrEnd,
          })
        );
      }
      HTTP.POST_ARRAY("/endpoints/schedule/" + this.id, formData)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async save() {
      this.delete();
      this.create();
      this.saveBtn = 0;
      await new Promise((res) => setTimeout(res, 1000));
      return true;
    },
    getPlaylists() {
      if (this.id == "") {
        this.$route.push("/endpoints");
        return;
      }
      HTTP.GET("/endpoints/scheduleitems/" + this.id)
        .then((res) => {
          this.draggables = res.data;
          this.dragContainer = document.getElementById("external-events");
          new Draggable(this.dragContainer, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
              return {
                title: eventEl.innerText,
                duration: "01:00",
                plid: eventEl.getAttribute("plid"),
                backgroundColor: eventEl.getAttribute("fccolor"),
                overlap: false,
              };
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSchedule(interval) {
      if (this.id == "") {
        this.$route.push("/endpoints");
        return;
      }
      this.calendarApi.removeAllEvents();
      HTTP.GET("/endpoints/schedule/" + this.id + "_" + interval)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            res.data.forEach((element) => {
              let e = {
                id: element.Id,
                uniq: element.Uniq,
                plid: element.Plid,
                start: element.Start.split("+")[0].trim(),
                end: element.End.split("+")[0].trim(),
                title: element.Title,
                overlap: false,
                backgroundColor: element.Color,
              };
              this.calendarApi.addEvent(e);
            });
          }

          this.uniqEvents = [];
          this.cycleEvents = [];
          for (let e of this.calendarApi.getEvents()) {
            if (e.extendedProps.uniq == 0) {
              this.cycleEvents.push(e);
            } else {
              this.uniqEvents.push(e);
            }
          }
          this.renderView();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUniqEvents() {
      // Uniq events for doted month callendar
      if (this.id == "") {
        this.$route.push("/endpoints");
        return;
      }
      HTTP.GET("/endpoints/uniqyeardays/" + this.id)
        .then((res) => {
          for (let v of res.data) {
            this.uniqMonthEvents.push(v["d"]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getUniqEvents();
  },
  computed: {
    cUniq: {
      get: function () {
        return this.uniq;
      },
      set: function (v) {
        if (v) {
          this.uniq = 1;
        } else {
          this.uniq = 0;
        }
      },
    },
  },
  watch: {
    lastAppendEvent() {
      this.lastAppendEvent.setExtendedProp("uniq", this.uniq);
      if (this.uniq == 0) {
        for (let e of this.uniqEvents) {
          if (this.lastAppendEvent.start.getDay() == e.start.getDay()) {
            this.lastAppendEvent.remove();
            break;
          }
        }
      }
    },
    cUniq() {
      this.renderView();
    },
    datePicker(d) {
      if (this.saveBtn == 1) {
        if (window.confirm("Были внесены изменения. Сохранить?")) {
          this.save().then(() => {
            this.calendarApi.gotoDate(d);
          });
        }
      } else {
        this.calendarApi.gotoDate(d);
      }
    },
    id() {
      this.loadData();
    },
    reload() {
      this.loadData();
    },
  },
  components: {
    FullCalendar,
  },
  mounted() {
    this.getPlaylists();
  },
  props: ["id", "name", "reload"],
};
</script>

<style scoped>

</style>
