<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Вход</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-alert :value="errorBool" type="warning">
                            {{ error }}
                        </v-alert>
                        <v-form v-model="valid">
                            <v-text-field clearable label="Логин" name="login" prepend-icon="mdi-account" type="login" required v-model="login" :rules="loginRules"></v-text-field>
                            <v-text-field clearable id="password" label="Пароль" name="password" prepend-icon="mdi-lock" type="password" required v-model="password" :rules="passwordRules" v-on:keyup.enter="onEnter"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.prevent="signin" :disabled="processing || !valid">Войти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
      </v-container>
</template>

<script>
export default {
    data() {
        return {
            login: null,
            password: null,
            valid: false,
            loginRules: [
                (v) => !!v || 'Введите логин',
                //(v) => (v && v.length >= 1) || 'Короткий пароль. Минимум 6 символов'
                //(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат e-mail'
            ],
            passwordRules: [
                (v) => !!v || 'Введите пароль',
                (v) => (v && v.length >= 1) || 'Короткий пароль. Минимум 1 символов'
            ],
        }
    },
    methods: {
        onEnter: function() {
            if(this.valid){
                this.signin()
            }
        },
        signin(){
            this.$store.dispatch('LOGIN',{login:this.login, password:this.password})
        },
    },
    computed: {
        errorBool(){
            return this.$store.getters.getErrorBool
        },
        error(){
            return this.$store.getters.getError
        },
        processing(){
            return this.$store.getters.getProcessing
        },
        isUserAuthenticated(){
            return this.$store.getters.isUserAuthenticated
        }
    },
    watch: {
        isUserAuthenticated(val){
            if (val === true)
                this.$router.push("/")
        }
    }
}
</script>

<style scoped>

</style>
