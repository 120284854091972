<template>
  <v-card width="100%" class="mx-3" elevation="2" height="50px">
    <v-row class="audio-player" dense>
      <v-col md="1">
        <div class="controls mx-4 py-2">
          <div class="toggle-play play"></div>
        </div>
      </v-col>
      <v-col md="11" class="my-2">
        <div class="timeline">
          <div class="progress"></div>
          <div class="time white--text">
            <span class="current">0:00</span>
            <span class="divider"> / </span>
            <span class="length">0:00</span>
          </div>
        </div>
      </v-col>
      <v-col class="px-5 pb-2" style="line-height: 10px; margin-top: -8px">
        {{ name }}</v-col
      >
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      audio: new Audio(),
    };
  },
  methods: {
    renderPlayer() {
      const audioPlayer = document.querySelector(".audio-player");

      this.audio.addEventListener(
        "loadeddata",
        () => {
          audioPlayer.querySelector(" .controls .toggle-play").classList.remove("play");
          audioPlayer.querySelector(" .controls .toggle-play").classList.add("pause");
          this.audio.play();
          console.log(this.audio.src);
          audioPlayer.querySelector(".time .length").textContent = getTimeCodeFromNum(
            this.audio.duration
          );
          this.audio.volume = 0.75;
        },
        false
      );

      //audio playing end
      this.audio.addEventListener("ended", () => {
        audioPlayer.querySelector(".controls .toggle-play").classList.remove("pause");
        audioPlayer.querySelector(".controls .toggle-play").classList.add("play");
        this.$emit("playend", {});
      });

      this.audio.addEventListener("pause", () => {
        audioPlayer.querySelector(".controls .toggle-play").classList.remove("pause");
        audioPlayer.querySelector(".controls .toggle-play").classList.add("play");
        this.$emit("pause", {});
      });

      //click on timeline to skip around
      const timeline = audioPlayer.querySelector(".timeline");
      timeline.addEventListener(
        "click",
        (e) => {
          const timelineWidth = window.getComputedStyle(timeline).width;
          const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * this.audio.duration;
          this.audio.currentTime = timeToSeek;
        },
        false
      );

      //check audio percentage and update time accordingly
      setInterval(() => {
        const progressBar = audioPlayer.querySelector(".progress");
        progressBar.style.width =
          (this.audio.currentTime / this.audio.duration) * 100 + "%";
        audioPlayer.querySelector(".time .current").textContent = getTimeCodeFromNum(
          this.audio.currentTime
        );
      }, 500);

      //toggle between playing and pausing on button click
      const playBtn = audioPlayer.querySelector(".controls .toggle-play");
      playBtn.addEventListener(
        "click",
        () => {
          if (this.audio.paused) {
            playBtn.classList.remove("play");
            playBtn.classList.add("pause");
            this.audio.play();
          } else {
            playBtn.classList.remove("pause");
            playBtn.classList.add("play");
            this.audio.pause();
          }
        },
        false
      );

      //turn 128 seconds into 2:08
      function getTimeCodeFromNum(num) {
        let seconds = parseInt(num);
        let minutes = parseInt(seconds / 60);
        seconds -= minutes * 60;
        const hours = parseInt(minutes / 60);
        minutes -= hours * 60;

        if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
        return `${String(hours).padStart(2, 0)}:${minutes}:${String(
          seconds % 60
        ).padStart(2, 0)}`;
      }
    },
  },
  computed: {},
  mounted() {
    this.renderPlayer();
  },
  beforeDestroy() {
    this.audio.pause();
  },
  watch: {
    url() {
      if (this.url != "") {
        this.audio.src = this.url;
        //this.audio.src="http://10.10.0.53/foo.mp3"
        this.audio.load();
        this.audio.play();
        //this.renderPlayer()
      } else {
        this.audio.pause();
      }
    },
  },
  props: ["name", "url"],
};
</script>

<style scoped>
.audio-player {
  font-size: 0.75em;
  overflow: hidden;
}
.audio-player .timeline {
  background: #1976d2;
  width: 95%;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}
.audio-player .timeline .progress {
  background: #ffc107;
  width: 0%;
  height: 95%;
  transition: 0.25s;
}

.audio-player .controls .toggle-play.play {
  cursor: pointer;
  position: relative;
  left: 0;
  height: 0;
  width: 0;
  border: 7px solid #0000;
  border-left: 13px solid #4caf50;
}
.audio-player .controls .toggle-play.play:hover {
  transform: scale(1.1);
}
.audio-player .controls .toggle-play.pause {
  height: 15px;
  width: 20px;
  cursor: pointer;
  position: relative;
}
.audio-player .controls .toggle-play.pause:before {
  position: absolute;
  top: 0;
  left: 0px;
  background: #ffc107;
  content: "";
  height: 15px;
  width: 4px;
}
.audio-player .controls .toggle-play.pause:after {
  position: absolute;
  top: 0;
  right: 8px;
  background: #ffc107;
  content: "";
  height: 15px;
  width: 4px;
}
.audio-player .controls .toggle-play.pause:hover {
  transform: scale(1.1);
}
.audio-player .controls .time {
  display: flex;
}
.audio-player .controls .time > * {
  padding: 2px;
}
.progress {
  height: 15px !important;
  position: absolute;
  z-index: 2;
}
.current,
.divider,
.length {
  position: relative;
  z-index: 2;
  left: 50%;
  top: 20%;
}
.time {
  height: 15px !important;
}
</style>
