<template>
    <v-card>
        <v-toolbar color="primary" flat>
            <v-toolbar-title class="text-h5 primary white--text">
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon class="white--text" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
            <v-row>
                <v-col>
                    <v-select
                        :disabled="id!='0'"
                        :items="roles"
                        :item-text="(item) => item.comment"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userRole"
                        label="Уровень доступа"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="allowFor(['g_root']) && userRole!='1000'">
                <v-col>
                    <v-select
                        :disabled="id!='0'"
                        :items="admins"
                        :item-text="(item) => item.name"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userGroup"
                        label="Группа"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="userRole=='3'">
                <v-col>
                    <v-select
                        :disabled="id!='0'"
                        :items="filteredEndpoints"
                        :item-text="(item) => item.name+` [`+item.address+`]`"
                        :item-value="(item) => item.id"
                        hide-details
                        dense
                        v-model="userEndpoint"
                        label="Услуга"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userLogin"
                      label="Логин"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userPassword"
                      label="Пароль"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userName"
                      label="Имя"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                      dense
                      hide-details
                      v-model="userComment"
                      label="Комментарий"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row >
                <v-col>
                    
                </v-col>
                <v-col class="text-right">
                    <v-btn dense color="success" :disabled="!valid" @click="createUser">
                        Сохранить
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                users: [],
                roles: [],
                admins: [],
                endpoints: [],
                userGroup: "",
                userName: "",
                userLogin: "",
                userRole: "",
                userEndpoint: "",
                userPassword: "",
                userComment: "",
                id: "0"
            }
        },
        methods: {
            loadEndpoints(){
                this.endpoints = []
                HTTP.GET("/endpoints/endpoints").then((res) => {
                    this.endpoints = res.data
                }).catch((error) => {
                    console.log(error)
                })
            },
            loadAdmins(){
                if(this.allowFor(['g_root'])){
                    HTTP.GET("/users/admins").then((res) => {
                        this.admins = res.data
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            },
            close() {
                this.$emit("close", {})
                this.unsetForm()
            },
            unsetForm(){
                this.userGroup=""
                this.userName=""
                this.userLogin=""
                this.userPassword=""
                this.userComment=""
                this.id="0"
            },
            loadRoles(){
                HTTP.GET("/users/roles").then((res) => {
                    this.roles=res.data
                }).catch((error) => {
                    console.log(error)
                })
            },
            createUser(){
                HTTP.POST('/users/create',{id:this.id, owner:this.userGroup, name: this.userName,login: this.userLogin, password: this.userPassword, role: this.userRole, endpoint: this.userEndpoint ,comment: this.userComment}).then(() =>{
                    this.unsetForm()
                    this.$emit('saved',{})
               }).catch((error) =>{
                    console.log(error)
               })
            }
        },
        computed: {
            valid(){
                if (this.userName.length > 0 && this.userPassword.length > 0 && this.userLogin.length > 0 && this.userRole.length > 0 ) {
                   if (this.userRole=="3" && this.userEndpoint.length > 0) return true 
                   if (this.userRole!="3") return true
                }
                return false
            },
            title(){
                if(this.user_id!==undefined && this.user_id!="" && this.user_name!="" && this.user_name!=undefined){
                    return "Редактировать пользователя"
                }
                return "Добавить пользователя"
            },
            filteredEndpoints(){
                /*console.log(this.endpoints)
                console.log(this.userGroup)
                return this.endpoints.filter((endpoint) => {
                    return endpoint.owner==this.userGroup
                })*/
                return this.endpoints
            }
        },
        created(){
            if(this.user_id){
                this.id=this.user_id
            }
            this.loadRoles()
            this.loadAdmins()
            this.loadEndpoints()
        },
        watch: {
            userRole(r){
                if(r!="3") {
                    this.userEndpoint=""
                    return
                }
                console.log("Load endpoints list")
                this.loadEndpoints()
            },
        },
        props: ['owner_id','user_id', 'user_name']
    }
</script>

<style scoped>

</style>