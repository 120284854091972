<template>
<v-dialog
    persistent
    v-model="dialog"
    scrollable
    fullscreen
    max-width="1200px"
>
    <template v-slot:activator="{ on: d }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on: t }">
                <v-btn
                    small
                    color="primary"
                    v-on="d"
                    icon
                    @click="loadSelected"
                >
                    <v-icon v-on="t">mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Добавить</span>
        </v-tooltip>
    </template>
    <v-card>
        <v-card-title>Выберите файлы (Всего выбрано: {{ selectedFiles.length }})</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="min-height: 500px;">
            <v-row>
                <v-col lg="3" md="4">
                    <v-treeview
                        activatable
                        open-on-click
                        v-if="ready"
                        :active.sync="active"
                        :items="rootfolders"
                        transition
                    >
                    </v-treeview>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col lg="8" md="7">
                    <div
                        v-if="!this.active.length"
                        ><!--Выберите директорию-->
                        <v-text-field class="mx-3" flat label="Поиск по всей базе" append-icon="mdi-magnify" v-model="searchGlobal" clearable @click:clear="searchGlobal=''"></v-text-field>
                        <template v-for="item in filelist">
                            <file-browser-item @playThis="playThis" v-bind:key="item.id" v-bind:isplay="play==item.id" v-bind:id="item.id" v-bind:name="joinName(item.artist,item.title)" v-bind:path="item.album+` / `+item.genre"/> 
                        </template>
                    </div>
                    
                     <div v-else>
                        <v-text-field class="mx-3" flat label="Поиск" v-model="search" clearable @click:clear="search=''"></v-text-field>
                        <v-list-item dense>
                            <v-checkbox
                                hide-details
                                v-model="selectAllCheckbox"
                                dense
                                color="primary"
                            />
                        </v-list-item>
                        <template v-for="item in filteredItems">
                            <file-browser-item @playThis="playThis" v-bind:key="item.id" v-bind:isplay="play==item.id" v-bind:id="item.id" v-bind:name="joinName(item.artist,item.title)"/> 
                        </template>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="Add"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="warning"
            text
            @click="cancel"
          >
            Отмена
          </v-btn>
          <player
            @pause="play = ''"
            @playend="play = ''"
            v-bind:name="selectedName"
            v-bind:url="audioSrc"
          />
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    import FileBrowserItem from './FileBrowserItem'
    import Player from "../Player.vue";
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    export default {
        data(){
            return {
                dialog: false,
                ready: false,
                dialogm1: null,
                rootfolders: [],
                subfolders: [],
                filelist: [],
                active: [],
                search: '',
                searchGlobal: "",
                play: "",
                selectedName: "",
                renderPlayer: false,
                globalSearchItems: [],
                selectAllCheckbox: false,
            }
        },
        methods: {
            async playThis(id,name){
                if (id == this.play) {
                    this.play = "";
                    return;
                }
                this.play = id;
                this.selectedName = name
                this.renderPlayer = false;
                await sleep(1);
                this.renderPlayer = true;
            },
            joinName(artist,title){
                return artist+" - "+title
            },
            cancel(){
                this.$confirm('Все изменения будут потеряны. Вы уверенны?').then(res => {
                    if(res){
                        this.$emit('dialog-closed', this.playlist_id)
                        this.dialog=false
                    } else {
                        this.dialog=true
                    }
                })
            },
            loadSelected(){
                this.$store.dispatch('PLAYLISTS_CLEAR_SELECTED_FILES')
                HTTP.GET('/playlistfolders/playlist/'+this.playlist_id).then((res) => {
                        for(let s of res.data){
                            this.$store.dispatch('PLAYLISTS_ADD_SELECTED_FILES',s.id)
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
            },
            Add(){
                let formData = new FormData();
                for(let b of this.$store.getters.playlists_selected_files){
                    formData.append('items',b)
                }
                HTTP.POST_ARRAY('/playlistfolders/playlist/'+this.playlist_id,formData).then(() => {
                    this.$emit('dialog-closed', this.playlist_id)
                    this.dialog=false
                }).catch((error) => {
                    console.log(error)
                })
            },
            loadList(){
                HTTP.GET('/basefolders/filelist/'+this.active[0]).then((res) => {
                    this.filelist=res.data
                    for(let f of this.filelist){
                        f["tagname"]=f["artist"]+" "+f["title"]
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            hotSearch(){
                HTTP.POST("/basefolders/hotsearch",{name: this.searchGlobal.toLowerCase()}).then((res) => {
                    if(res.data!==null){
                        this.filelist=res.data
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            ArrayJoin(){
                for (let rf of this.rootfolders){
                    rf.children=[]
                }
                for (let sf of this.subfolders){
                    for(let rf of this.rootfolders){
                        if(rf.id==sf.parent){
                            rf.children.push(sf)
                        }
                    }
                }
                
                // HTTP.GET('/playlistfolders/playlist/'+this.playlist_id).then((res) => {
                //         for(let s of res.data){
                //             this.selection.push(s.base_id)
                //         }
                //     }).catch((error) => {
                //         console.log(error)
                //     })
                this.ready=true
            }
        },
        computed: {
            audioSrc() {
                if (this.play == "") {
                    return "";
                }
                return "https://api.akpha.org/basefolders/play/" + this.play;
            },
            filteredItems() {
                if(this.search=='') return this.filelist;
                return this.filelist.filter(item => {
                    return item.tagname.toLowerCase().includes(this.search.toLowerCase())
                })
            },
            selectedFiles: {
                get: function () {
                    return this.$store.getters.playlists_selected_files
                },
                // set: function (a) {
                //     this.$store.dispatch('PLAYLISTS_ADD_SELECTED_FILES',a)
                // }
            }
        },
        created() {
            HTTP.GET('/basefolders/bases').then((res) => {
                this.rootfolders = res.data
                HTTP.GET('/basefolders/subbases').then((res) => {
                    this.subfolders = res.data
                    this.ArrayJoin()
                }).catch((error) => {
                    console.log(error)
                })
            }).catch((error) =>{
                console.log(error)
            })
        },
        watch: {
            active(){
                this.filelist=[]
                if (this.active[0]!==undefined && this.active[0]!="0"){
                    this.loadList()
                }
            },
            searchGlobal(v){
                if(v===null || v==""){
                    this.filelist=[]
                    return
                }
                this.hotSearch()
            },
            selectAllCheckbox(v){
                if(v){
                    for(let f of this.filteredItems){
                        let index = this.$store.getters.playlists_selected_files.indexOf(f.id)
                        if(index == -1){
                            this.$store.dispatch('PLAYLISTS_ADD_SELECTED_FILES',f.id)
                        }
                    }
                } else {
                    for(let f of this.filteredItems){
                        let index = this.$store.getters.playlists_selected_files.indexOf(f.id)
                        if(index != -1){
                            this.$store.dispatch('PLAYLISTS_ADD_SELECTED_FILES',f.id)
                        }
                    }
                }
            }
        },
        components: {
            FileBrowserItem,
            Player
        },
        props: ['playlist_id']
    }
</script>

<style scoped>

</style>
