<template>
  <v-row>
    <v-col md="6" lg="5">
      <v-card elevation="0" class="mt-4">
        <v-treeview
          v-if="ready"
          :active.sync="active"
          :items="rootfolders"
          activatable
          open-on-click
          transition
          @update:open="updateOpened"
          :open="openedTree"
        >
          <template v-slot:label="{ item }">
            <v-row>
              <v-col md="3" >
                <v-icon class="mx-1" v-if="!item.children" x-small>mdi-music</v-icon>
                <span>{{ item.name }}</span>
              </v-col>
              <v-col md="2">
                <span
                  class="font-weight-light text--secondary"
                  v-if="item.comment != '<nil>'"
                  >{{ item.comment.toLowerCase() }}</span
                >
              </v-col>
              <v-col md="1">
                <span class="text-caption text--disabled">id: {{ item.id }}</span>
              </v-col>
              <v-col md="3">
                <span
                  class="text-caption text--disabled"
                  v-if="item.owner != '<nil>' && !item.parent"
                  >{{ item.owner }}</span
                >
              </v-col>
                       <v-col md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.parent && item.used_pl > 0"
                      color="warning"
                    >mdi-lock
                </v-icon>
                  </template>
                  <span>Используется</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>

          <!--Delete base button-->
          <template v-slot:append="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="'/bases/delete/base/' + item.id + '/' + slashToBackslash(item.name)"
                  x-small
                  v-if="!item.parent"
                  :disabled="item.children.length!=0"
                  v-on="on"
                  icon
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
            
            <!--Delete subbase button-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-if="item.parent"  v-on="on" x-small color="error" :disabled="item.used_files != 0 || item.used_pl != 0" icon :to="'/bases/delete/base/' + item.id + '/' + slashToBackslash(item.name)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
        </v-treeview>
      </v-card>
    </v-col>
    <v-divider vertical></v-divider>

    <v-col>
      <v-card elevation="0">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
           
          >
            <!--Выберите базу-->
            <global-search/>
          </div>
          <div v-else>
            <span>выбрано: {{ selected }}</span>
            <file-list @deleted="loadData()" v-bind:folder_id="active[0]" />
          </div>
        </v-scroll-y-transition>
      </v-card>
    </v-col>


  </v-row>
</template>

<script>
import HTTP from "@/helpers/http-common";
import FileList from "./FileList.vue";
import GlobalSearch from "./GlobalSearch.vue"

export default {
  data() {
    return {
      ready: false,
      rootfolders: [],
      subfolders: [],
    };
  },
  methods: {
    slashToBackslash(s){
      return s.replace(/\//g, '\\')
    },
    loadData() {
      this.ready = false;
      HTTP.GET("/basefolders/bases")
        .then((res) => {
          this.rootfolders = res.data;
          HTTP.GET("/basefolders/subbases")
            .then((res) => {
              this.subfolders = res.data;
              this.ArrayJoin();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateOpened(a) {
      this.$store.dispatch("BASES_ADD_OPENED", a);
    },
    ArrayJoin() {
      for (let rf of this.rootfolders) {
        rf.children = [];
      }
      for (let sf of this.subfolders) {
        for (let rf of this.rootfolders) {
          if (rf.id == sf.parent) {
            rf.children.push(sf);
          }
        }
      }
      this.ready = true;
    },
  },
  computed: {
    selected() {
      if (!this.active.length) return undefined;
      let sfname = "";
      let rfname = "";
      const id = this.active[0];
      for (let sf of this.subfolders) {
        if (id == sf.id) {
          sfname = sf.name;
          for (let rf of this.rootfolders) {
            if (sf.parent == rf.id) {
              rfname = rf.name;
            }
          }
        }
      }
      return rfname + " / " + sfname;
    },
    openedTree() {
      return this.$store.getters.bases_opened_tree;
    },
    active: {
      get: function () {
        return this.$store.getters.bases_active_tree;
      },
      set: function (a) {
        this.$store.dispatch("BASES_ADD_ACTIVE", a);
      },
    },
  },
  components: {
    FileList,
    GlobalSearch
  },
  created() {
    this.loadData();
    /*HTTP.GET("/basefolders/bases")
      .then((res) => {
        this.rootfolders = res.data;
        HTTP.GET("/basefolders/subbases")
          .then((res) => {
            this.subfolders = res.data;
            this.ArrayJoin();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });*/
  },
  watch: {
    reload() {
      this.loadData();
    },
  },
  props: ["reload"],
};
</script>
