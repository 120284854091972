<template>
    <v-container>
        <v-row align="center" class="pt-2">
        </v-row>
        <streams-list/>
    </v-container>
</template>

<script>
    import StreamsList from '../components/Streams/StreamsList.vue'
    export default {
        data(){
            return {

            }
        },
        created() {

        },
        components: {
            StreamsList
        }
    }
</script>

<style scoped>

</style>
