<template>
  <v-card elevation="2" class="mt-4">
    <v-row>
      <v-col md="4" offset-md="4" v-if="!ready">
        <v-progress-circular
          :size="70"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-row v-else class="my-2 mx-1">
        <v-col>
          <v-treeview
            v-model="selection"
            v-if="ready"
            :items="rootfolders"
            transition
            selectable
            item-disabled="empty"
          >
            <template v-slot:label="{ item }">
              <v-badge
                inline
                x-small
                v-if="item.parent"
                :color="item.dynamic == 1 ? 'success' : 'warning'"
                :content="item.dynamic == 0 ? 's' : 'd'"
              >
                {{ item.name }}
              </v-badge>
              <v-badge
                inline
                x-small
                v-if="!item.parent"
                color="grey"
                :content="item.children.length > 0 ? item.children.length : '0'"
              >
                {{ item.name }}
              </v-badge>
            </template>
          </v-treeview>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      ready: false,
      rootfolders: [],
      subfolders: [],
      selection: [],
    };
  },
  methods: {
    loadData() {
      HTTP.GET("/playlistfolders/bases")
        .then((res) => {
          this.rootfolders = res.data;
          HTTP.GET("/playlistfolders/subbases")
            .then((res) => {
              this.subfolders = res.data;
              this.ArrayJoin();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ArrayJoin() {
      for (let rf of this.rootfolders) {
        rf.children = [];
      }
      for (let sf of this.subfolders) {
        for (let rf of this.rootfolders) {
          if (rf.id == sf.parent) {
            rf.children.push(sf);
          }
        }
      }
      for (let rf of this.rootfolders) {
        if (rf.children.length == 0) {
          rf.empty = true;
        }
      }
      HTTP.GET("/endpoints/playlists/" + this.id)
        .then((res) => {
          for (let s of res.data) {
            this.selection.push(s.id);
            for (let f of this.rootfolders) {
              for (let c of f.children) {
                if (c.id == s.id && c.id == s.used) {
                  c.empty = true;
                }
              }
            }
          }
          this.ready = true;
        })
        .catch((error) => {
          console.log(error);
        });
      
    },
  },
  computed: {},
  watch: {
    selection() {
      if (this.ready == false) return;
      let formData = new FormData();
      for (let b of this.selection) {
        formData.append("items", b);
      }
      HTTP.POST_ARRAY("/endpoints/playlists/" + this.id, formData)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    id() {
      this.ready = false;
      this.selection = [];
      this.loadData();
    },
    reload() {
      this.ready = false;
      this.selection = [];
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  props: ["id", "name", "reload"],
};
</script>

<style scoped></style>
