<template>
    <v-container>

    </v-container>
</template>

<script>
    export default {
        created() {
            this.$confirm('Вы действительно желаете выйти?').then(res => {
                if(res){
                    this.$store.dispatch('LOGOUT')
                    this.$store.dispatch('WS_CLOSE')
                    location.reload()
                } else {
                    this.$router.go(-1)
                }
            })
        }
    }
</script>

<style scoped>

</style>
