var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var filelistDialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, filelistDialog)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v("Список роликов")])])]}}]),model:{value:(_vm.filelistDialog),callback:function ($$v) {_vm.filelistDialog=$$v},expression:"filelistDialog"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":function($event){_vm.filelistDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-4 pt-4"},[_vm._v(" Добавляйте доступные ролики в рекламный блок."),_c('br'),_vm._v(" Перемещайте их между собой, что бы выстроить нужную последовательность. ")]),_c('v-card-text',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.current.length == 0),expression:"current.length == 0"}]},[_vm._v("Список пуст...")]),_c('draggable',{directives:[{name:"show",rawName:"v-show",value:(_vm.current.length >= 1),expression:"current.length >= 1"}],model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},_vm._l((_vm.current),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeAdvFromBlock(item.relationid)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])]),_c('span',{staticClass:"caption text--secondary"},[_vm._v(_vm._s(_vm.formatDuration(item.duration)))]),_vm._v(" "+_vm._s(item.name)+" ")],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.append == false),expression:"append == false"}],attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.append = true;
              _vm.loadAdvs();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Добавить ролик в блок")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length != 0),expression:"selected.length != 0"}],attrs:{"icon":"","color":"success"},on:{"click":_vm.addAdvToBlock}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Сохранить")])]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.append == true),expression:"append == true"}],attrs:{"items":_vm.selectItems,"item-text":"name","item-value":"id","single-line":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }