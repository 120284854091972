<template>
  <v-container fluid>
    <v-card elevation="0" class="ma-2">
      <v-dialog v-model="dialogAddGroup" width="500">
        <template v-slot:activator="{ on: dialog }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                color="primary"
                class="white--text"
                small
                v-on="{ ...tooltip, ...dialog }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Создать коллекцию</span>
          </v-tooltip>
        </template>
        <create-group @close="dialogAddGroup = false" @finish="finishCreate" />
      </v-dialog>
      <playlist-list v-bind:reload="reloadList" />
    </v-card>
  </v-container>
</template>

<script>
import PlaylistList from "@/components/Playlists/PlaylistList.vue";
import CreateGroup from "@/components/Playlists/CreatePlaylistGroup.vue";
export default {
  data() {
    return {
      dialogAddGroup: false,
      reloadList: false,
    };
  },
  methods: {
    finishCreate() {
      this.dialogAddGroup = false;
      this.reloadList = !this.reloadList;
    },
  },
  components: {
    PlaylistList,
    CreateGroup,
  },
};
</script>
