import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './user'
import generalModule from './general'
import wsModule from './ws'
import endpointsModule from './endpoints'
import basesModule from './bases'
import playlistsModule from './playlists'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        userModule,
        generalModule,
        wsModule,
        endpointsModule,
        basesModule,
        playlistsModule
    }
})
