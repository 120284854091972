<template>
  <v-card elevation="0" rounded="0">
    <span class="text--secondary caption" v-if="filelist.length > 0"
      >Треков: {{ filelist.length }}</span
    >
    <v-row class="pt-1" dense>
      <v-col md="6">
        <v-text-field
          v-model="searchName"
          label="Название"
          clearable
          append-icon="mdi-magnify"
          @click:clear="searchName = ''"
          dense
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-select
          v-model="searchYear"
          :items="selectYears"
          item-text="value"
          item-value="id"
          label="Год"
          dense
        >
        </v-select>
      </v-col>
      <v-col md="1">
        <v-select
          v-model="searchMonth"
          :items="selectMonths"
          :disabled="searchYear == ''"
          item-text="value"
          item-value="id"
          label="Месяц"
          dense
        >
        </v-select>
      </v-col>
      <v-col md="2">
        <v-menu
        dense
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            readonly
            v-model="searchDate"
            label="Дата"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="searchDate"
            @input="clickDatePicker"
            :events="uploadDates"
            :first-day-of-week="1"
            event-color="green lighten-1">
        </v-date-picker>
      </v-menu>
      </v-col>
      <v-col md="2">
        <v-btn
          :disabled="
            (searchName == '' || searchName === null) &&
            searchYear == '' &&
            searchMonth == '' &&
            searchDate == ''
          "
          small
          class="warning"
          @click="
            searchName = '';
            searchYear = '';
            searchMonth = '';
            searchDate = '';
          "
        >
          Сбросить
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="9" class="font-weight-bold pa-0 ma-0 d-inline-flex">
          <v-checkbox
            v-model="selectAllCheckbox"
            :ripple="false"
            dense
            hide-details
            @change="selectAllToggle"
          ></v-checkbox>
        <span class="text--secondary text-subtitle-2">Наименование треков</span>
      </v-col>
      <v-col cols="2" class="font-weight-bold pa-0 ma-0">
        <span class="text--secondary text-subtitle-2">Дата</span>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn icon small @click="deleteSelected" :disabled="selectedLen==0">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
      </v-col>
    </v-row>
    </div>
    <v-divider/>
    <v-virtual-scroll
        :bench="80"
        :items="filteredItems"
        style="height: calc(100vh - 320px) !important"
        item-height="24"
      >
      <template v-slot:default="{ item }">
        <file-list-item @play="itemClick" v-bind:item="item" v-bind:selected="item.selected" v-bind:playid="play" @itemSelected="itemSelected" v-if="showList"/>
      </template>
    </v-virtual-scroll>
    
    <player
      class="pt-2"
      @pause="play = ''"
      @playend="play = ''"
      v-bind:name="selectedName"
      v-bind:url="audioSrc"
    />

    <!--<v-data-table
      v-model="selected"
      dense
      :headers="headers"
      :items="filteredItems"
      fixed-header
      sort-by="name"
      disable-pagination
      hide-default-footer
      show-select
      style="height: calc(100vh - 280px) !important"
      height="100%"
      @toggle-select-all="selectAllFilesToggle"
    >
      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-simple-checkbox
          :value="item.selected"
          :ripple="false"
          :disabled="item.used > 0"
          @input="inputClick(item)"
        />
      </template>
      <template v-slot:[`header.actions`]>
        <v-row justify="end" class="px-2">
          <v-btn icon @click="deleteSelected" :disabled="!selected.length > 0">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-row justify="start">
          <v-col md="12"> {{ item.artist }} - {{ item.title }} </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.uploaddate`]="{ item }">
        {{ splitDate(item.uploaddate) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" v-if="item.used > 0" color="warning"
                >mdi-lock
              </v-icon>
            </template>
            <span>Используется</span>
          </v-tooltip>
          <v-btn class="mx-2" @click.stop="itemClick(item)" icon color="success">
            <v-icon :color="playIcon(item.id) == 'mdi-play' ? 'succes' : 'warning'">{{
              playIcon(item.id)
            }}</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row justify="center" class="mt-2">-->
          <!--v-if="renderPlayer" -->
          <!--<player
            @pause="play = ''"
            @playend="play = ''"
            v-bind:name="selectedName"
            v-bind:url="audioSrc"
          />
        </v-row>
      </template>
    </v-data-table>-->
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
import Player from "../Player.vue";
import FileListItem from "./FileListItem.vue"

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export default {
  name: 'file-list',
  data() {
    return {
      dateMenu: false,
      searchDate: '',
      selectAllCheckbox: false,
      selectedLen: 0,
      showList: true,
      showFilter: false,
      searchYear: "",
      searchMonth: "",
      selectedName: "",
      renderPlayer: false,
      filelist: [],
      play: "",
      selected: [],
      searchName: "",
      deleted: 0,
      loading: true,
      wavesurfer: null,
      headers: [
        {
          text: "Наименование треков",
          value: "name",
          align: "left",
          width: "70%",
          sortable: false,
          fixed: true,
        },
        {
          text: "Дата",
          value: "uploaddate",
          align: "left",
          width: "15%",
          sortable: true,
          fixed: true,
        },
        {
          text: "",
          value: "actions",
          align: "left",
          sortable: false,
          fixed: true,
        },
      ],
      selectMonths: [
        { id: "", value: "Все" },
        { id: "01", value: "Январь" },
        { id: "02", value: "Февраль" },
        { id: "03", value: "Март" },
        { id: "04", value: "Апрель" },
        { id: "05", value: "Май" },
        { id: "06", value: "Июнь" },
        { id: "07", value: "Июль" },
        { id: "08", value: "Август" },
        { id: "09", value: "Сентябрь" },
        { id: "10", value: "Октябрь" },
        { id: "11", value: "Ноябрь" },
        { id: "12", value: "Декабрь" },
      ],
    };
  },
  methods: {
    clickDatePicker() {
      this.dateMenu = !this.dateMenu;
    },
    selectAllToggle(){
      let disabledCount = this.filelist.filter((item) => {
        return item.used != 0;
      });
      var t = this.filelist.filter((item) => item.selected==true)
      if (t.length != this.filelist.length - disabledCount.length) {
        for(let f of this.filelist){
          if(f.used==0){
            f.selected=true
            this.selectedLen++
          }
        }
      } else {
        this.selectedLen=0
        for(let f of this.filelist){
          f.selected=false
        }
      }
      this.reloadList()
    },
    reloadList(){
      this.showList=false
      this.showList=true
    },
    itemSelected(item){
      let track = this.filelist.find((t) => t.id==item.id && t.used==0)
      track.selected = !track.selected
      if(track.selected) {
        this.selectedLen++
      } else {
        this.selectedLen--
      }
    },
    /*inputClick(item){
      this.filelist[this.filelist.findIndex(el => el.hash == item.hash)].selected =! item.selected
      this.selected.push('a')
    },*/
    splitDate(d) {
      let a = d.split("+");
      return a[0];
    },
    /*selectAllFilesToggle() {
      let disabledCount = this.filelist.filter((item) => {
        return item.used != 0;
      });
      if (this.selected.length != this.filelist.length - disabledCount.length) {
        this.selected = this.filelist.filter((item) => {
          return item.used == 0;
        });
      } else {
        this.selected = [];
      }
    },*/
    loadList() {
      this.loading = true;
      HTTP.GET("/basefolders/filelist/" + this.folder_id)
        .then((res) => {
          this.filelist = res.data;
          for(let f of this.filelist){
            f["tagname"]=f["artist"]+" "+f["title"]
            f["selected"]=false
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async itemClick(a) {
      if (a.id == this.play) {
        this.play = "";
        this.reloadList()
        return;
      }
      let track = this.filelist.find((item) => item.id==a.id)
      this.selectedName = track.artist + " - " + track.title;
      this.play = a.id;
      this.renderPlayer = false;
      await sleep(1);
      this.renderPlayer = true;
      this.reloadList()
      //var p = document.getElementById("player");
      //p.volume = 0.6;
    },
    /*playIcon(id) {
      if (this.play == id) {
        return "mdi-pause";
      }
      return "mdi-play";
    },
    selectAll() {
      this.selected = [];
      this.filelist.forEach((element) => {
        if (element.used == "<nil>") {
          this.selected.push(element.id);
        }
      });
    },
    unselectAll() {
      this.selected = [];
    },*/
    deleteSelected() {
      console.log(this.filelist.filter((item) => item.selected==true))
      this.$confirm("Вы действительно желаете удалить выбранные файлы?").then((res) => {
        if (res) {
          let files = this.filelist.filter((item) => item.selected==true && item.used==0)
          this.deleted = files.length;
          for (let f of files) {
            HTTP.DELETE("/basefolders/track/" + f.id)
              .then(() => {
                this.filelist = this.filelist.filter((track) => track.id != f.id);
                this.deleted--;
              })
              .catch((error) => {
                this.deleted--;
                console.log(error);
                return;
              });
          }
          this.selected = [];
        }
      });
    },
  },
  computed: {
    uploadDates(){
      let forSorting = []
      for(let f of this.filelist){
        forSorting.push(f.uploaddate.substr(0, 10))
      }
      let ret = forSorting.filter((el, i, a) => i === a.indexOf(el))
      return ret
    },
    selectYears() {
      let startYear = 2021;
      let currentYear = new Date().getFullYear();
      let r = [];
      r.push({ id: "", value: "Все" });
      while (startYear <= currentYear) {
        r.push({ id: startYear, value: startYear });
        startYear++;
      }
      return r;
    },
    audioSrc() {
      if (this.play == "") {
        return "";
      }
      return "https://api.akpha.org/basefolders/play/" + this.play;
    },
    filteredItems() {
      if(this.searchDate!='') {
        return this.filelist.filter((item) => {
          return item.uploaddate.substr(0, 10)==this.searchDate
        })
      }
      if (
        (this.searchName == "" || this.searchName === null) &&
        this.searchMonth == "" &&
        this.searchYear == ""
      ) {
        return this.filelist;
      }
      let result = [];
      let params = [
        { field: "tagname", value: this.searchName, exact: false },
        { field: "year", value: this.searchYear, exact: true },
        { field: "month", value: this.searchMonth, exact: true },
      ];
      params = params.filter((item) => {
        return item.value != "";
      });

      for (let e of this.filelist) {
        e["year"] = e.uploaddate.split("-")[0];
        e["month"] = e.uploaddate.split("-")[1];
        let match = true;
        for (let fltr of params) {
          switch (fltr.exact) {
            case true:
              if (e[fltr.field] != fltr.value) {
                match = false;
              }
              break;
            case false:
              if (!e[fltr.field].toLowerCase().includes(fltr.value.toLowerCase())) {
                match = false;
              } 
              break;
          }
        }
        if (match) result.push(e);
      }
      result = result.filter(
        (item, index, ar) => ar.findIndex((t) => t.id === item.id) === index
      );
      return result;
    },
    isPlay() {
      return this.play;
    },
  },
  created() {
    this.loadList();
  },
  destroyed() {
    this.selected = [];
  },
  watch: {
    folder_id() {
      this.loadList();
      this.reloadList();
      this.selectAllCheckbox=false
      this.selectedLen=0
    },
    async deleted(o, n) {
      if (o != 0 && n == 0) {
        await sleep(100);
        this.$emit("deleted", {});
        this.selectAllCheckbox=false
        this.reloadList()
      }
    },
  },
  props: ["folder_id"],
  components: {
   Player,
   FileListItem
  },
};
</script>
