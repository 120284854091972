var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0","min-height":"700"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" Загрузка треков ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-4 pt-4"},[_vm._v(" Загрузка треков возможна только при наличии заполненных тегов: \"Артист\", \"Наименование\", \"Альбом\", \"Жанр\", \"Издатель\"."),_c('br'),_c('br'),_vm._v(" Формирование баз происходит автоматически:"),_c('br'),_vm._v(" тег \"Альбом\" - формирует группу базы;"),_c('br'),_vm._v(" тег \"Жанр\" - формирует подгруппу базы. ")]),_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-file-input',{attrs:{"counter":"","multiple":"","show-size":"","id":"tracks","accept":".mp3,audio/*","color":"primary","label":"Добавьте файлы в формате MP3","prepend-icon":"mdi-paperclip","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.uploading)?_c('v-progress-linear',{staticClass:"mx-8",attrs:{"value":_vm.progress}}):_vm._e()],1),(_vm.files.length > 0)?_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"caption",attrs:{"color":"primary","disabled":!_vm.valid || _vm.uploading || _vm.parsing},on:{"click":_vm.upload}},[_vm._v("Загрузить")])],1):_vm._e()],1),(_vm.files.length > 0)?_c('span',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.filesProgress.filter(function (o) {
              return o.duplicated || o.tagserror;
            }).length > 0
          ),expression:"\n            filesProgress.filter((o) => {\n              return o.duplicated || o.tagserror;\n            }).length > 0\n          "}],staticClass:"mx-8",attrs:{"order":"first"}},[_vm._v(" Ошибки в файлах: "),_vm._l((_vm.filesProgress.filter(function (item) {
              return item.tagserror || item.duplicated;
            })),function(item,i){return _c('v-list-item',{key:i},[(item.tagserror || item.duplicated)?_c('v-list-item-subtitle',{staticClass:"red--text"},[_vm._v(_vm._s(item.name))]):_vm._e()],1)})],2)],1),_c('v-row',{staticClass:"mx-8 mt-6 mb-2"},[_c('v-btn',{staticClass:"caption",attrs:{"small":"","color":"warning"},on:{"click":function($event){_vm.additional = !_vm.additional}}},[_vm._v("Показать загружаемые треки")])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.additional),expression:"additional"}],staticClass:"mx-4",attrs:{"transition":"slide-x-transition"}},_vm._l((_vm.filesProgress),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('div')],1),(!item.tagserror && !item.duplicated)?_c('v-list-item-action',[_c('v-avatar',{attrs:{"color":item.result == null
                    ? 'success'
                    : item.result == 'D'
                    ? 'warning'
                    : 'error',"size":"34"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.result == null ? item.progress : item.result))])])],1):_vm._e()],1)}),1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Загрузка завершена ")]),_c('v-card-text',[_c('p',[_vm._v(" Всего файлов: "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.filesProgress.filter(function (item) { return !item.tagserror && !item.duplicated; }).length))])]),(_vm.accepted > 0)?_c('p',{staticClass:"mt-0"},[_vm._v(" Успешно загружено: "),_c('span',{staticClass:"success--text font-weight-medium"},[_vm._v(_vm._s(_vm.accepted))])]):_vm._e(),(_vm.duplicated.length > 0)?_c('p',{staticClass:"mt-0"},[_vm._v(" Перезаписано: "),_c('br'),_vm._l((_vm.duplicated),function(f,index){return _c('span',{key:index,staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(f.name)+" "),_c('br'),_c('v-divider')],1)})],2):_vm._e(),(_vm.fataled.length > 0)?_c('p',{staticClass:"mt-0"},[_vm._v(" Не загружено(ошибки в файлах): "),_vm._l((_vm.fataled),function(f,index){return _c('span',{key:index,staticClass:"font-weight-medium"},[_vm._v(_vm._s(f.name))])})],2):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.accept}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }