<template>
    <v-row class="mt-2 ml-2" v-if="allowFor(['g_root'])">
        <v-col>
            <report-by-owner/>
        </v-col>
    </v-row>
</template>

<script>
    import ReportByOwner from "@/components/Reports/ReportByOwner.vue"
    export default {
        data() {
            return {

            };
        },
        created() {
        },
        components: {
            ReportByOwner
        }
    }
</script>

<style scoped>

</style>