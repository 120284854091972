<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-form>
        <v-text-field v-model="baseName">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Наименование
          </template>
        </v-text-field>

        <v-row>
          <v-spacer></v-spacer>
          <v-col align="right">
            <v-btn class="caption" color="success" @click="createBase" :disabled="!valid">
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar
          timeout="1200"
          v-model="snackSuccess"
          absolute
          top
          color="success"
          center
        >
          Коллекция создана
        </v-snackbar>
        <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
          Ошибка создания коллекции
        </v-snackbar>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      snackSuccess: false,
      snackError: false,
      baseName: "",
      id: "0",
      dynamic: 1,
      color: "#FF00FF",
    };
  },
  methods: {
    createBase() {
      if(this.edit_id!==undefined && this.edit_id!=""){
        HTTP.PATCH("/playlistfolders/base", {
          id: this.edit_id,
          parent_id: "0",
          name: this.baseName,
          color: this.color,
          dynamic: this.dynamic == 0 ? "0" : "1",
        })
          .then(() => {
            this.$emit("finish", {});
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        HTTP.POST("/playlistfolders/base", {
          id: this.id,
          parent_id: "0",
          name: this.baseName,
          color: this.color,
          dynamic: this.dynamic == 0 ? "0" : "1",
        })
          .then(() => {
            this.$emit("finish", {});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    close() {
      this.$emit("close", {});
    },
  },
  computed: {
    valid() {
      if (this.baseName.length > 0) return true;
      return false;
    },
    title(){
      if(this.edit_id!==undefined && this.edit_name!=undefined){
        return "Редактировать группу коллекций"
      }
      return "Создать группу коллекций"
    }
  },
  watch: {
    edit_id(){
      if(this.edit_id!==undefined){
        this.id=this.edit_id
        this.baseName=this.edit_name
      }
    }
  },
  created() {
    if(this.edit_id!==undefined){
        this.id=this.edit_id
        this.baseName=this.edit_name
      }
  },
  props: ["edit_id","edit_name"]
};
</script>

<style scoped></style>
