var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"pt-5",staticStyle:{"height":"calc(100vh - 270px) !important"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.users,"fixed-header":"","sort-by":"name","disable-pagination":"","hide-default-footer":"","show-select":"","height":"100%"},on:{"toggle-select-all":_vm.selectAll},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleById(item.role))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyComment(item.comment))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""}},[_c('v-icon',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.editUser(item.id)}}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.selectedUsers.indexOf(item)==-1?false:true,"ripple":false,"disabled":item.role=='1000'},on:{"input":function($event){_vm.selectedUsers.indexOf(item)==-1?_vm.selectedUsers.push(item):_vm.selectedUsers.splice(_vm.selectedUsers.indexOf(item),1)}}})]}},{key:"header.actions",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.selectedUsers.length > 0}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v("mdi-delete")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editUserDialog),callback:function ($$v) {_vm.editUserDialog=$$v},expression:"editUserDialog"}},[_c('edit-user',{attrs:{"user_id":_vm.editUserId},on:{"close":function($event){_vm.editUserDialog=false;_vm.editUserId=null;_vm.loadUsers();}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }