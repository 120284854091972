import Vue from 'vue'
import VueRouter from 'vue-router'
import Logout from '@/views/Logout'
import Login from '@/views/Login'
import SelfSetting from '@/views/SelfSetting'

import Dashboard from '@/views/Dashboard'


import Users from '@/views/Users'
import UsersAdd from '@/components/Users/AddUser'

import Bases from '@/views/Bases'
import BasesAddBase from '@/components/Bases/AddBase'
import BasesUpload from '@/components/Bases/Upload'
import BasesDelete from '@/components/Bases/Delete'
import BasesEdit from '@/components/Bases/EditBase'

import Playlists from '@/views/Playlists'
//import AddPlaylist from '@/components/Playlists/AddPlaylist'
import DeletePlaylist from '@/components/Playlists/Delete'
import DeletePlaylistItem from '@/components/Playlists/DeleteItem'
import EditPlaylist from '@/components/Playlists/EditPlaylist'

import Endpoints from '@/views/Endpoints'
import EndpointsAddGroup from '@/components/Endpoints/AddGroup'
import EndpointsAddSubgroup from '@/components/Endpoints/AddSubgroup'
import EndpointsAddEndpoint from '@/components/Endpoints/EndpointEdit'
import EndpointsDelete from '@/components/Endpoints/Delete'
import EndpointPlaylist from '@/components/Endpoints/EndpointPlaylist'
import EndpointSchedule from '@/components/Endpoints/EndpointSchedule'
import EndpointAdv from '@/components/Endpoints/EndpointAdv'

import Reports from '@/views/Reports'

import Streams from '@/views/Streams'

import NotFound from '@/views/NotFound'
import Cookies from 'js-cookie'
import Store from '../store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta :{ requiresAuth: true }
  },



  {
    path: '/users',
    name: 'users',
    component: Users,
    meta :{ requiresAuth: true }
  },
  {
    path: '/users/add/:parent_id',
    name: 'usersadd',
    component: UsersAdd,
    props: true,
    meta :{ requiresAuth: true }
  },



  {
    path: '/bases',
    name: 'bases',
    component: Bases,
    meta :{ requiresAuth: true }
  },
  {
    path: '/bases/add/base',
    name: 'basesaddbase',
    component: BasesAddBase,
    meta :{ requiresAuth: true }
  },
  {
    path: '/bases/add/subfolder/:parent_id',
    name: 'basesaddsubfolder',
    component: BasesAddBase,
    props: true,
    meta :{ requiresAuth: true }
  },
  // {
  //   path: '/bases/upload/:folder_id/:folder_name',
  //   name: 'basesupload',
  //   component: BasesUpload,
  //   props: true,
  //   meta :{ requiresAuth: true }
  // },
  {
    path: '/bases/upload',
    name: 'basesupload',
    component: BasesUpload,
    props: true,
    meta :{ requiresAuth: true }
  },
  {
    path: '/bases/delete/:type/:id/:name',
    name: 'basesdelete',
    component: BasesDelete,
    props: true,
    meta :{ requiresAuth: true }
  },
  {
    path: '/bases/edit/:id/:c/',
    name: 'basesedit',
    component: BasesEdit,
    props: true,
    meta :{ requiresAuth: true }
  },



  {
    path: '/playlists',
    name: 'playlists',
    component: Playlists,
    meta :{ requiresAuth: true }
  },
 /* {
    path: '/playlists/add/playlist',
    name: 'playlistsadd',
    component: AddPlaylist,
    meta :{ requiresAuth: true }
  },
  {
    path: '/playlists/add/subfolder/:parent_id/:parent_name',
    name: 'playlistsaddsubfolder',
    component: AddPlaylist,
    props: true,
    meta :{ requiresAuth: true }
  },*/
  {
    path: '/playlists/delete/:type/:id/:name',
    name: 'playlistsdelete',
    component: DeletePlaylist,
    props: true,
    meta :{ requiresAuth: true }
  },
  {
    path: '/playlists/delete/item/:parent_id/:deleted_id/:deleted_name',
    name: 'playlistsdeleteitem',
    component: DeletePlaylistItem,
    props: true,
    meta :{ requiresAuth: true }
  },
  {
    path: '/playlists/edit/:id/:name/:parent_id',
    name: 'playlistsedit',
    component: EditPlaylist,
    props: true,
    meta :{ requiresAuth: true }
  },



  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta :{ requiresAuth: true }
  },
  


  
  {
    path: '/endpoints',
    name: 'endpoints',
    component: Endpoints,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/add/group',
    name: 'endpointsaddgroup',
    component: EndpointsAddGroup,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/edit/group/:id/:name',
    name: 'endpointseditgroup',
    props: true,
    component: EndpointsAddGroup,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/add/subgroup/:parent_id/:parent_name',
    name: 'endpointsaddsubgroup',
    component: EndpointsAddSubgroup,
    props: true,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/add/endpoint/:parent_id/:parent_name',
    name: 'endpointsaddendpoint',
    props: true,
    component: EndpointsAddEndpoint,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/edit/endpoint/:id',
    name: 'endpointseditendpoint',
    props: true,
    component: EndpointsAddEndpoint,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/copy/endpoint/:copy/:id',
    name: 'endpointscopyendpoint',
    props: true,
    component: EndpointsAddEndpoint,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/edit/subgroup/:id/:name/:parent_id',
    name: 'endpointseditsubgroup',
    props: true,
    component: EndpointsAddSubgroup,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/delete/:type/:id/:name',
    name: 'endpointsdelete',
    props: true,
    component: EndpointsDelete,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/playlist/:id/:name',
    name: 'endpointplaylist',
    props: true,
    component: EndpointPlaylist,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/schedule/:id/:name',
    name: 'endpointschedule',
    props: true,
    component: EndpointSchedule,
    meta :{ requiresAuth: true }
  },
  {
    path: '/endpoints/adv/:id/:name',
    name: 'endpointadv',
    props: true,
    component: EndpointAdv,
    meta :{ requiresAuth: true }
  },
  

  
  {
    path: '/streams',
    name: 'streams',
    component: Streams,
    meta :{ requiresAuth: true }
  },

  
  
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/self',
    name: 'self',
    component: SelfSetting,
    meta :{ requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta :{ requiresAuth: true }
  },
  { 
    path: '/404', 
    name: '404', 
    component: NotFound, 
  },
  { 
    path: '*', 
    redirect: '/self' 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: '/ajy/',
  routes
})

router.beforeEach((to, from, next) => {
  if (Store.getters.isUserAuthenticated && to=="/login") {
    next(from)
    return
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (Store.getters.isUserAuthenticated) {
        Cookies.set('route',to.path)
        next()
      } else {
        next('/login')
      }
  } else {
    next()
  }
})

export default router
