<template>
    <v-container>
        <v-row align="center" justify="center" class="pt-2">
            <v-btn to="/bases" 
                    color="primary">
                    <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-spacer/>
            <h3 v-if="this.id==0">Создать базу</h3>
            <h3 v-if="this.id!=0">Создать директорию</h3>
            <v-spacer/>
        </v-row>
        <v-row align="center" justify="center">
            <v-form>
                <v-text-field v-model="baseName">
                    <template #label>
                        <span class="red--text"><strong>* </strong></span>Имя
                    </template>
                </v-text-field>
                <v-text-field v-model="comment" label="Описание">
                </v-text-field>
                <v-btn
                    class="mr-4"
                    @click="createBase"
                    :disabled="!valid"
                >
                    Создать
                </v-btn>
            </v-form>
        </v-row>
    </v-container>
</template>

<script>
    import HTTP from '@/helpers/http-common'
    export default {
        data(){
            return {
                baseName: "",
                comment: "",
                id: "0"
            }
        },
        methods: {
            createBase(){
                HTTP.POST('/basefolders/base',{id:this.id, name: this.baseName, comment: this.comment}).then(() =>{
                   this.$router.push('/bases')
               }).catch((error) =>{
                   console.log(error)
               })
            }
        },
        computed: {
            valid(){
                if (this.baseName.length > 0) return true
                return false
            }
        },
        created(){
            if(this.parent_id){
                this.id=this.parent_id
            }
        },
        props: ['parent_id']
    }
</script>

<style scoped>

</style>