<template>
  <v-card elevation="2" class="mt-4">
    <v-row >
      <v-col>
        <v-form>
          <v-row class="mt-2">
            <v-col>
              <v-text-field hide-details dense v-model="form.name">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Наименование
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field hide-details dense v-model="form.jur_name">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Наименование юр.лица
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field hide-details dense v-model="form.inn">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>ИНН
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field hide-details dense v-model="form.city">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Город
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field hide-details dense v-model="form.address">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Адрес
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field hide-details dense v-model="form.phone">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Телефон
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field hide-details dense v-model="form.email">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Почта
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field hide-details dense v-model="form.dog_number">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Номер договора
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-menu
                dense
                v-model="dpdogmenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="caption"
                    hide-details
                    dense
                    readonly
                    v-model="form.dog_date"
                    label="Дата договора"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.dog_date"
                  @input="dpdogmenu = !dpdogmenu"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field hide-details dense v-model="dog_pay">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Стоимость услуги
                </template></v-text-field
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.rao_number"
                label="Номер договор РАО"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                dense
                v-model="dpraomenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="caption"
                    hide-details
                    dense
                    readonly
                    v-model="form.rao_date"
                    label="Дата договор РАО"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.rao_date"
                  @input="dpraomenu = !dpraomenu"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                min="0"
                type="number"
                v-model="rao_pay"
                label="Платёж РАО"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.vois_number"
                label="Номер договор ВОИС"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                dense
                v-model="dpvoismenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="caption"
                    hide-details
                    dense
                    readonly
                    v-model="form.vois_date"
                    label="Дата договор ВОИС"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.vois_date"
                  @input="dpvoismenu = !dpvoismenu"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                min="0"
                type="number"
                v-model="vois_pay"
                label="Платёж ВОИС"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.inspector"
                label="Инспектор"
              ></v-text-field>
            </v-col>
            <v-col> </v-col>
            <v-col> </v-col>
          </v-row>
          <v-row v-if="form.type == 2">
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.wifi_ssid"
                label="WiFi SSID"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.wifi_key"
                label="WiFi Key"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="wifiCryptoTypes"
                :item-text="(item) => item.name"
                :item-value="(item) => item.value"
                hide-details
                dense
                v-model="form.wifi_crypto"
                label="WiFi Crypto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="form.type == 2">
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.ipv4_ip"
                label="IP address"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.ipv4_mask"
                label="Mask"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.ipv4_gtw"
                label="Gateway"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="form.ipv4_dns"
                label="DNS"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4 v-if="form.type==2">
                <v-select
                  hide-details
                  v-model="form.tz"
                  :items="tztypes"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Часовой пояс
                  </template>
                </v-select>
              </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
      <v-col align="right">
        <v-dialog v-if="id" v-model="dialogCopySettings" width="800">
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="primary" large icon dark v-on="{ ...tooltip, ...dialog }">
                  <v-icon>mdi-cog-transfer-outline</v-icon>
                </v-btn>
              </template>
              <span>Копировать настройки</span>
            </v-tooltip>
          </template>
          <endpoint-copy-settings
            @saved="copySettings"
            @closed="dialogCopySettings = false"
            v-bind:target_ep="id"
            v-bind:subgroup_id="form.parentid"
          />
        </v-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              dark
              icon
              color="success"
              @click="createEndpoint"
              :disabled="!valid"
              v-on="on"
            >
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </template>
          <span>Сохранить</span>
        </v-tooltip>

        <v-dialog v-if="id" v-model="dialogCopy" width="800">
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn color="warning" large icon dark v-on="{ ...tooltip, ...dialog }">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Копировать услугу</span>
            </v-tooltip>
          </template>
          <endpoint-copy
            @saved="copyCreated"
            @closed="dialogCopy = false"
            v-bind:id="id"
            v-bind:copy="true"
          />
        </v-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="id"
              large
              dark
              icon
              color="error"
              :to="'/endpoints/delete/endpoint/' + id + '/' + form.name"
              v-on="on"
            >
              <v-icon>mdi-delete-alert-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить услугу</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-snackbar timeout="1200" v-model="snackSuccess" absolute top color="success" center>
      Изменения сохранены
    </v-snackbar>
    <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
      Внимание! Ошибка сохранения
    </v-snackbar>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
import EndpointCopy from "./EndpointCopy.vue";
import EndpointCopySettings from "./EndpointCopySettings.vue"

export default {
  components: { EndpointCopy,EndpointCopySettings },
  data() {
    return {
      wifiCryptoTypes: [
        { name: "Open", value: "Open" },
        { name: "WPA2", value: "WPA2" },
      ],
      dpdogmenu: false,
      dpraomenu: false,
      dpvoismenu: false,
      dialogCopy: false,
      dialogCopySettings: false,
      snackSuccess: false,
      snackError: false,
      edit: true,
      types: [],
      subgroups: [],
      form: {
        parentid: null,
        type: null,
        name: null,
        jur_name: null,
        inn: null,
        city: null,
        address: null,
        email: null,
        phone: null,
        dog_date: null,
        dog_number: null,
        dog_pay: "0",
        rao_number: null,
        rao_date: null,
        rao_pay: "0",
        vois_number: null,
        vois_date: null,
        vois_pay: "0",
        inspector: null,
        hostname: null,

        wifi_ssid: null,
        wifi_key: null,
        wifi_crypto: null,
        ipv4_ip: null,
        ipv4_mask: null,
        ipv4_gtw: null,
        ipv4_dns: null,
        tz: "",
      },
      tztypes: [
        {id:"+02 Europe/Kaliningrad",name:"+02 Калининград"},
        {id:"+03 Europe/Moscow",name:"+03 Москва"},
        {id:"+04 Europe/Samara",name:"+04 Самара"},
        {id:"+05 Europe/Yekaterinburg",name:"+05 Екатеренбург"},
        {id:"+06 Asia/Omsk",name:"+06 Омск"},
        {id:"+07 Asia/Novosibirsk",name:"+07 Новосибирск"},
        {id:"+08 Asia/Irkutsk",name:"+08 Иркутск"},
        {id:"+09 Asia/Yakutsk",name:"+09 Якутск"},
        {id:"+10 Asia/Vladivostok",name:"+10 Владивосток"},
        {id:"+11 Asia/Magadan",name:"+11 Магадан"},
        {id:"+12 Asia/Kamchatka",name:"+12 Камчатка"},
      ],
    };
  },
  computed: {
    subgroupText() {
      if (this.subgroups.length == 0) {
        return "";
      }
      let a = this.subgroups.filter((sg) => {
        return sg.id == this.form.parentid;
      });
      if (a.length > 0) return a[0].name;
      return "";
    },
    typeText() {
      if (this.types.length == 0) {
        return "";
      }
      let a = this.types.filter((t) => {
        return t.id == this.form.type;
      });
      if (a.length > 0) return a[0].name;
      return "";
    },
    valid() {
      let reqFields = [
        "type",
        "name",
        "jur_name",
        "inn",
        "dog_number",
        "dog_date",
        "city",
        "address",
        "email",
        "phone",
        "hostname",
      ];
      let v = true;
      let arr = Object.entries(this.form);
      for (let k of reqFields) {
        for (let el of arr) {
          if ((el[0] == k && el[1] === null) || (el[0] == k && el[1] == "")) {
            v = false;
            break;
          }
        }
      }
      return v;
    },
    rao_pay: {
      get: function () {
        return this.form.rao_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.rao_pay = "0";
        } else {
          this.form.rao_pay = v;
        }
      },
    },
    vois_pay: {
      get: function () {
        return this.form.vois_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.vois_pay = "0";
        } else {
          this.form.vois_pay = v;
        }
      },
    },
    dog_pay: {
      get: function () {
        return this.form.dog_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.dog_pay = "0";
        } else {
          this.form.dog_pay = v;
        }
      },
    },
  },
  methods: {
    copyCreated() {
      this.$emit("saved", {});
      this.dialogCopy = false;
    },
    copySettings() {
      this.$emit("saved", {});
      this.dialogCopySettings = false;
    },
    createEndpoint() {
      let d = {};
      let arr = Object.entries(this.form);
      for (let el of arr) {
        d[el[0]] = el[1];
      }
      HTTP.POST("/endpoints/endpoint", d)
        .then(() => {
          //this.$router.push('/endpoints')
          this.snackSuccess = true;
          this.$emit("saved", {});
        })
        .catch((error) => {
          this.snackError = true;
          console.log(error);
        });
    },
  },

  created() {
    HTTP.GET("/endpoints/type")
      .then((res) => {
        this.types = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.GET("/endpoints/subgroup")
      .then((res) => {
        this.subgroups = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.id) {
      //EDIT OR COPY
      HTTP.GET("/endpoints/subgroup")
        .then((res) => {
          this.subgroups = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      HTTP.GET("/endpoints/endpoint/" + this.id)
        .then((res) => {
          this.form.id = res.data[0].id;
          this.form.parentid = res.data[0].parent;
          this.form.type = res.data[0].type;
          (this.form.name = res.data[0].name),
            (this.form.jur_name = res.data[0].jur_name),
            (this.form.inn = res.data[0].inn),
            (this.form.city = res.data[0].city),
            (this.form.address = res.data[0].address),
            (this.form.email = res.data[0].email),
            (this.form.phone = res.data[0].phone),
            (this.form.dog_date = res.data[0].dog_date),
            (this.form.dog_number = res.data[0].dog_number),
            (this.form.rao_number = res.data[0].rao_number),
            (this.form.rao_date = res.data[0].rao_date),
            (this.form.vois_number = res.data[0].vois_number),
            (this.form.vois_date = res.data[0].vois_date),
            (this.form.inspector = res.data[0].inspector);
          (this.form.hostname = res.data[0].hostname),
            (this.form.rao_pay = res.data[0].rao_pay),
            (this.form.vois_pay = res.data[0].vois_pay);
          this.form.dog_pay = res.data[0].dog_pay;
          this.form.wifi_ssid = res.data[0].wifissid;
          this.form.wifi_key = res.data[0].wifikey;
          this.form.wifi_crypto = res.data[0].wificrypto;
          this.form.ipv4_ip = res.data[0].ipv4address;
          this.form.ipv4_mask = res.data[0].ipv4mask;
          this.form.ipv4_gtw = res.data[0].ipv4gtw;
          this.form.ipv4_dns = res.data[0].ipv4dns;
          this.form.tz = (res.data[0].tz=="<nil>" || res.data[0].tz=="")?"+03 Europe/Moscow":res.data[0].tz;
          if (this.copy) {
            //COPY
            this.form.id = null;
            this.form.hostname = "";
            this.edit = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //NEW
      this.edit = false;
      this.form.parentid = this.parent_id;
    }
  },
  props: ["id", "parent_id", "parent_name", "copy"],
};
</script>

<style scoped></style>
