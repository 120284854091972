<template>
  <v-card elevation="0">
    <v-toolbar color="primary">
      <v-toolbar-title class="text-h5 primary white--text">
        Копирование услуги
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closed">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row>
        <v-col>
          <v-form>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  disabled
                  v-model="form.parentid"
                  :items="subgroups"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                  outlined
                >
                  <template #label>
                    Родительская группа
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  disabled
                  hide-details
                  v-model="form.type"
                  :items="types"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.id"
                  dense
                  outlined
                >
                  <template #label>
                   Тип
                  </template>
                </v-select>
              </v-col>
            </v-row>
            
            <v-row justify="space-around">
              <v-col>
                <v-checkbox
                  v-model="form.copyCollection"
                  label="Коллекции"
                  color="primary"
                  :disabled="form.copySchedule"
                  :value="form.copySchedule"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="form.copySchedule"
                  label="Расписание"
                  color="primary"
                  @click="form.copyCollection=form.copySchedule"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="form.copyAdvSchedule"
                  label="Реклама"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="form.copyPlaynow"
                  label="Playnow"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            
            <v-row>
              <!-- <v-col>
                <v-text-field hide-details dense v-model="form.hostname">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Hostname
                  </template>
                </v-text-field>
              </v-col> -->
              <v-col>
                <v-text-field hide-details dense v-model="form.name">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Наименование
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.jur_name">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Наименование юр.лица
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.inn">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>ИНН
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.dog_number">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Номер договора
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.dog_date">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Дата договора
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="dog_pay"
                  min="0"
                  type="number"
                  label="Стоимость услуги"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.city">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Город
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.address">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Адрес
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field hide-details dense v-model="form.email">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Почта
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field hide-details dense v-model="form.phone">
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Телефон
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.rao_number"
                  label="Номер договор РАО"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.rao_date"
                  label="Дата договор РАО"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  min="0"
                  type="number"
                  v-model="rao_pay"
                  label="Платёж РАО"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.vois_number"
                  label="Номер договор ВОИС"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.vois_date"
                  label="Дата договор ВОИС"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  min="0"
                  type="number"
                  v-model="vois_pay"
                  label="Платёж ВОИС"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  dense
                  v-model="form.inspector"
                  label="Инспектор"
                ></v-text-field>
              </v-col>
              <v-col> </v-col>
              <v-col> </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-col align="right">
                <v-btn
                  class="caption"
                  color="success"
                  @click="createEndpoint"
                  :disabled="!valid"
                >
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
            <v-snackbar
              timeout="1200"
              v-model="snackSuccess"
              absolute
              top
              color="success"
              center
            >
              Изменения сохранены
            </v-snackbar>
            <v-snackbar
              timeout="1200"
              v-model="snackError"
              absolute
              top
              color="error"
              center
            >
              Внимание! Ошибка сохранения
            </v-snackbar>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      dialogCopy: false,
      snackSuccess: false,
      snackError: false,
      edit: true,
      types: [],
      subgroups: [],
      form: {
        parentid: null,
        type: null,
        name: null,
        jur_name: null,
        inn: null,
        city: null,
        address: null,
        email: null,
        phone: null,
        dog_date: null,
        dog_number: null,
        dog_pay: "0",
        rao_number: null,
        rao_date: null,
        rao_pay: "0",
        vois_number: null,
        vois_date: null,
        vois_pay: "0",
        inspector: null,
        hostname: null,
        copyid: this.id,
        copyCollection: true,
        copySchedule: true,
        copyAdvSchedule: true,
        copyPlaynow: true
      },
    };
  },
  computed: {
    subgroupText() {
      if (this.subgroups.length == 0) {
        return "";
      }
      let a = this.subgroups.filter((sg) => {
        return sg.id == this.form.parentid;
      });
      if (a.length > 0) return a[0].name;
      return "";
    },
    typeText() {
      if (this.types.length == 0) {
        return "";
      }
      let a = this.types.filter((t) => {
        return t.id == this.form.type;
      });
      if (a.length > 0) return a[0].name;
      return "";
    },
    valid() {
      let reqFields = [
        "type",
        "name",
        "jur_name",
        "inn",
        "dog_number",
        "dog_date",
        "city",
        "address",
        "email",
        "phone"
        //"hostname",
      ];
      let v = true;
      let arr = Object.entries(this.form);
      for (let k of reqFields) {
        for (let el of arr) {
          if ((el[0] == k && el[1] === null) || (el[0] == k && el[1] == "")) {
            v = false;
            break;
          }
        }
      }
      return v;
    },
    rao_pay: {
      get: function () {
        return this.form.rao_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.rao_pay = "0";
        } else {
          this.form.rao_pay = v;
        }
      },
    },
    vois_pay: {
      get: function () {
        return this.form.vois_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.vois_pay = "0";
        } else {
          this.form.vois_pay = v;
        }
      },
    },
    dog_pay: {
      get: function () {
        return this.form.dog_pay;
      },
      set: function (v) {
        if (v == null || v == "") {
          this.form.dog_pay = "0";
        } else {
          this.form.dog_pay = v;
        }
      },
    },
  },
  methods: {
    closed(){
      this.$emit("closed",{})
    },
    createEndpoint() {
      let d = {};
      let arr = Object.entries(this.form);
      for (let el of arr) {
        if(typeof el[1] === "boolean") {
          d[el[0]] = el[1].toString()
        } else {
          d[el[0]] = el[1]
        }
      }
      HTTP.POST("/endpoints/endpoint", d)
        .then(() => {
          //this.$router.push('/endpoints')
          this.snackSuccess = true;
          this.$emit("saved", {});
        })
        .catch((error) => {
          this.snackError = true;
          console.log(error);
        });
    },
  },

  created() {
    HTTP.GET("/endpoints/type")
      .then((res) => {
        this.types = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.GET("/endpoints/subgroup")
      .then((res) => {
        this.subgroups = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.id) {
      //EDIT OR COPY
      HTTP.GET("/endpoints/subgroup")
        .then((res) => {
          this.subgroups = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      HTTP.GET("/endpoints/endpoint/" + this.id)
        .then((res) => {
          this.form.id = res.data[0].id;
          this.form.parentid = res.data[0].parent;
          this.form.type = res.data[0].type;
          (this.form.name = res.data[0].name),
            (this.form.jur_name = res.data[0].jur_name),
            (this.form.inn = res.data[0].inn),
            (this.form.city = res.data[0].city),
            (this.form.address = res.data[0].address),
            (this.form.email = res.data[0].email),
            (this.form.phone = res.data[0].phone),
            (this.form.dog_date = res.data[0].dog_date),
            (this.form.dog_number = res.data[0].dog_number),
            (this.form.rao_number = res.data[0].rao_number),
            (this.form.rao_date = res.data[0].rao_date),
            (this.form.vois_number = res.data[0].vois_number),
            (this.form.vois_date = res.data[0].vois_date),
            (this.form.inspector = res.data[0].inspector);
          (this.form.hostname = res.data[0].hostname),
            (this.form.rao_pay = res.data[0].rao_pay),
            (this.form.vois_pay = res.data[0].vois_pay);
          this.form.dog_pay = res.data[0].dog_pay;
          if (this.copy) {
            //COPY
            this.form.id = null;
            this.form.hostname = "";
            this.edit = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //NEW
      this.edit = false;
      this.form.parentid = this.parent_id;
    }
  },
  props: ["id", "parent_id", "parent_name", "copy"],
};
</script>

<style scoped></style>
