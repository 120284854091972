var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"pt-1",attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Поиск по всем базам","clearable":"","append-icon":"mdi-magnify","dense":""},on:{"click:clear":function($event){_vm.searchName = ''}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)],1),(this.filelist.length>0)?_c('v-data-table',{staticStyle:{"height":"calc(100vh - 290px) !important"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.filelist,"fixed-header":"","sort-by":"name","disable-pagination":"","hide-default-footer":"","show-select":"","height":"100%"},on:{"toggle-select-all":_vm.selectAllFilesToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.selected.indexOf(item) == -1 ? false : true,"ripple":false,"disabled":item.used > 0},on:{"input":function($event){_vm.selected.indexOf(item) == -1
          ? _vm.selected.push(item)
          : _vm.selected.splice(_vm.selected.indexOf(item), 1)}}})]}},{key:"header.actions",fn:function(){return [_c('v-row',{staticClass:"px-2",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.selected.length > 0},on:{"click":_vm.deleteSelected}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"md":"12"}},[_vm._v(" "+_vm._s(item.artist)+" - "+_vm._s(item.title)+" "),_c('span',{staticClass:"text--secondary"},[_vm._v("["+_vm._s(item.album)+" / "+_vm._s(item.genre)+"]")])])],1)]}},{key:"item.uploaddate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.splitDate(item.uploaddate))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.used > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Используется")])]),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.itemClick(item)}}},[_c('v-icon',{attrs:{"color":_vm.playIcon(item.id) == 'mdi-play' ? 'succes' : 'warning'}},[_vm._v(_vm._s(_vm.playIcon(item.id)))])],1)],1)]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('player',{attrs:{"name":_vm.selectedName,"url":_vm.audioSrc},on:{"pause":function($event){_vm.play = ''},"playend":function($event){_vm.play = ''}}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }