var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" Рекламный контент ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Доступные ролики ")]),_c('v-row',[(_vm.files.length > 0)?_c('v-col',[_vm._v(" Всего файлов для загрузки: "+_vm._s(_vm.files.length)+" "),_c('br'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length > 0),expression:"files.length > 0"}],attrs:{"disabled":_vm.files.length == 0,"small":"","color":"primary"},on:{"click":_vm.upload}},[_vm._v("Загрузить")])],1):_vm._e(),_c('v-col',{attrs:{"md":"12"}},[_c('v-file-input',{ref:"uploader",staticStyle:{"color":"red"},attrs:{"dense":"","hide-input":"","id":"tracks","accept":".mp3,audio/*","multiple":"","prepend-icon":"mdi-folder-upload-outline"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1),_c('v-col',{staticClass:"px-0"},[_c('v-data-table',{staticStyle:{"height":"calc(100vh - 310px) !important"},attrs:{"dense":"","headers":_vm.headersFiles,"items":_vm.advsCurrent,"fixed-header":"","sort-by":"name","disable-pagination":"","hide-default-footer":"","show-select":"","height":"100%"},on:{"toggle-select-all":_vm.selectAllFilesToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.selected.indexOf(item)==-1?false:true,"ripple":false,"disabled":item.used>0},on:{"input":function($event){_vm.selected.indexOf(item)==-1?_vm.selected.push(item):_vm.selected.splice(_vm.selected.indexOf(item),1)}}})]}},{key:"header.actions",fn:function(){return [_c('v-row',{staticClass:"px-2",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.selected.length > 0}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteAdv()}}},[_vm._v("mdi-delete")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"px-2",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.used > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Используется")])]),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.itemClick(item)}}},[_c('v-icon',[_vm._v("mdi-play")])],1)],1)]}},{key:"footer",fn:function(){return [_c('v-row',[_c('player',{attrs:{"name":_vm.selectedName,"url":_vm.audioSrc},on:{"playend":function($event){_vm.play=''}}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Рекламные блоки ")]),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"color":"primary","small":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Создать рекламный блок")])])]}}]),model:{value:(_vm.dialogAddBlock),callback:function ($$v) {_vm.dialogAddBlock=$$v},expression:"dialogAddBlock"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" Создать рекламный блок ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":function($event){_vm.dialogAddBlock = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-form',[_c('v-text-field',{attrs:{"dense":"","label":"Наименование"},model:{value:(_vm.blockTitle),callback:function ($$v) {_vm.blockTitle=$$v},expression:"blockTitle"}}),_c('v-text-field',{attrs:{"dense":"","label":"Комментарий"},model:{value:(_vm.blockComment),callback:function ($$v) {_vm.blockComment=$$v},expression:"blockComment"}}),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"caption",attrs:{"color":"success","disabled":_vm.blockTitle.length == 0},on:{"click":function($event){return _vm.createBlock()}}},[_vm._v("Сохранить")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"pt-5",staticStyle:{"height":"calc(100vh - 270px) !important"},attrs:{"dense":"","headers":_vm.headersBlocks,"items":_vm.blocksCurrent,"fixed-header":"","sort-by":"name","disable-pagination":"","hide-default-footer":"","show-select":"","height":"100%"},on:{"toggle-select-all":_vm.selectAllBlocksToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.selectedBlocks.indexOf(item)==-1?false:true,"ripple":false,"disabled":item.used>0},on:{"input":function($event){_vm.selectedBlocks.indexOf(item)==-1?_vm.selectedBlocks.push(item):_vm.selectedBlocks.splice(_vm.selectedBlocks.indexOf(item),1)}}})]}},{key:"header.actions",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.selectedBlocks.length > 0}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteBlock()}}},[_vm._v("mdi-delete")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.used > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Используется")])]),_c('file-list',{attrs:{"id":item.id,"name":item.name,"subgroup":_vm.id}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"color":"warning","x-small":"","icon":""},on:{"click":function($event){_vm.selectedBlock = item;
                    _vm.dialogEditBlock = true;}}},{ tooltip: tooltip }),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать")])])]}}],null,true),model:{value:(_vm.selectedBlocks),callback:function ($$v) {_vm.selectedBlocks=$$v},expression:"selectedBlocks"}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.uploading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogEditBlock),callback:function ($$v) {_vm.dialogEditBlock=$$v},expression:"dialogEditBlock"}},[_c('block-edit',{attrs:{"block":_vm.selectedBlock,"parentid":_vm.id},on:{"saved":function($event){_vm.loadBlocks();
          _vm.dialogEditBlock = false;},"closed":function($event){_vm.dialogEditBlock = false}}})],1),_c('v-snackbar',{attrs:{"timeout":"3000","absolute":"","top":"","color":"success","center":""},model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_vm._v(" Рекламный блок успешно создан ")]),_c('v-snackbar',{attrs:{"timeout":"3000","absolute":"","top":"","color":"warning","center":""},model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_vm._v(" Ошибка создания рекламного блока ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }