<template>
  <v-container fluid>
    <endpoints-list />
    <v-row> </v-row>
  </v-container>
</template>

<script>
import EndpointsList from "@/components/Endpoints/EndpointsList";
export default {
  data() {
    return {};
  },
  components: {
    EndpointsList,
  },
};
</script>

<style scoped></style>
