export default {
    state: {
        playlists: {
            openedTree: [],
            active: [],
            selectedFiles: []
        }
    },
    mutations:{
        PLAYLISTS_SET_OPENED(state,payload){
            state.playlists.openedTree = payload
        },
        PLAYLISTS_SET_ACTIVE(state,payload){
            state.playlists.active = payload
        },
        PLAYLISTS_SET_SELECTED_FILES(state,payload){
            if (state.playlists.selectedFiles.indexOf(payload)>=0){
                state.playlists.selectedFiles.splice(state.playlists.selectedFiles.indexOf(payload),1)
            } else {
                state.playlists.selectedFiles.push(payload)
            }
        },
        PLAYLISTS_SET_SELECTED_FILES_NULL(state){
            state.playlists.selectedFiles=[]
        }
    },
    actions: {
        PLAYLISTS_ADD_OPENED({commit},payload){
            commit('PLAYLISTS_SET_OPENED',payload)
        },
        PLAYLISTS_ADD_ACTIVE({commit},payload){
            commit('PLAYLISTS_SET_ACTIVE',payload)
        },
        PLAYLISTS_ADD_SELECTED_FILES({commit},payload){
            commit('PLAYLISTS_SET_SELECTED_FILES',payload)
        },
        PLAYLISTS_CLEAR_SELECTED_FILES({commit}){
            commit('PLAYLISTS_SET_SELECTED_FILES_NULL')
        },
    },
    getters: {
        playlists_opened_tree: (state) => state.playlists.openedTree,
        playlists_active_tree: (state) => state.playlists.active,
        playlists_selected_files: (state) => state.playlists.selectedFiles,
    }
}
