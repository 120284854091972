import Cookies from 'js-cookie'

// var c

// function connect(){
//   var w  = new WebSocket('wss://api.akpha.org/ws')
//   w.onopen = function() {
//     c('SOCKET_ONOPEN',w)
//   }
//   w.onclose = function() {
//     if(Cookies.get('token')!=""){//Backend offline
//       setTimeout(function() {
//         connect()
//       },3000)
//   }
//     c('SOCKET_ONCLOSE')
//   }
//   w.onmessage = function(event) {
//     c('SOCKET_ONMESSAGE',event.data)
//   }
//   w.onerror = function(){
    
//   }
//   return w
// }

export default {
    state: {
        ws: {
          socket: null,
          isConnected: false,
          messages: {'traficNow' : '','systemMessages' : '','pinger':'', 'endpointsMinutesAgo':'','activeStreams':''},
          reconnectError: false,
        }
      },
      mutations:{
        SOCKET_SEND(state,msg){
          if(!state.ws.isConnected){
            console.log("Awaiting websocket in SOCKET_SEND")
            setTimeout(()=>state.ws.socket.send(msg),1000)
          } else {
            state.ws.socket.send(msg)
          }
        },
        SOCKET_CLOSE(state){
          state.ws.socket.close()
        },
        SOCKET_ONOPEN (state,w)  {
          //Vue.prototype.$socket = event.currentTarget
          state.ws.isConnected = true
          state.ws.socket = w
          state.ws.socket.send(JSON.stringify({'type':'subscribe','target':'systemMessages'}))//Subscribe to system messages
          console.log("Socket ready")
        },
        SOCKET_ONCLOSE (state)  {
          state.ws.isConnected = false
          state.ws.socket=null
        },
        // SOCKET_ONERROR (state, event)  {
        //   console.error(state, event)
        // },
        // // default handler called for all methods
        SOCKET_ONMESSAGE (state, message)  {
          let t = JSON.parse(message)
          //state.ws.messages[t.target] = atob(t.data)
          state.ws.messages[t.target] = decodeURIComponent(escape(window.atob(t.data)))
        },
        // // mutations for reconnect methods
        // SOCKET_RECONNECT(state, count) {
        //   console.info(state, count)
        // },
        // SOCKET_RECONNECT_ERROR(state) {
        //   state.socket.reconnectError = true;
        // },
      },
      actions: {
        WS_CONNECT({commit}){
          // c=commit
          // connect()
          console.log("Connecting to websocket")
          const that=this
          var w  = new WebSocket('wss://api.akpha.org/ws')
          w.onopen = function() {
              commit('SOCKET_ONOPEN',w)
          }
          w.onclose = function() {
            commit('SOCKET_ONCLOSE')
            if(Cookies.get('token')!="" && Cookies.get('token')!=null){//Backend offline
              console.log("Backend is offline")
              setTimeout(() => that.dispatch('WS_CONNECT') , 3000)
            }
          }
          w.onmessage = function(event) {
            commit('SOCKET_ONMESSAGE',event.data)
          }
        },
        WS_CLOSE({commit}){
            commit('SOCKET_CLOSE')
        },
        WS_SUBSCRIBE({commit},payload){
          commit('SOCKET_SEND',JSON.stringify({'type':'subscribe','target':payload}))
        }
      },
      getters: {
        getWS: (state) => state.ws.isConnected,
        getWSData: state => key => state.ws.messages[key]
      }
}