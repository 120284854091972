<template>
  <v-card elevation="0">
    <v-toolbar color="primary" flat>
      <v-toolbar-title class="text-h5 primary white--text">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="white--text" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-form>
        <v-text-field v-model="groupName">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Наименование
          </template>
        </v-text-field>

        <v-row>
          <v-spacer></v-spacer>
          <v-col align="right">
            <v-btn
              class="caption"
              color="success"
              @click="createGroup"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar
          timeout="1200"
          v-model="snackSuccess"
          absolute
          top
          color="success"
          center
        >
          Группа услуг создана
        </v-snackbar>
        <v-snackbar timeout="1200" v-model="snackError" absolute top color="error" center>
          Ошибка создания группы услуг
        </v-snackbar>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/helpers/http-common";
export default {
  data() {
    return {
      snackSuccess: false,
      snackError: false,
      edit: true,
      groupName: "",
    };
  },
  methods: {
    createGroup() {
      HTTP.POST("/endpoints/group", { id: this.id, name: this.groupName })
        .then(() => {
          this.$emit("created", {});
          //this.$router.push('/endpoints')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close(){
        this.$emit("close", {});
    }
  },
  computed: {
    valid() {
      if (this.groupName.length > 0) return true;
      return false;
    },
    title(){
      if(this.name!="" && this.id!="" ){
        return "Редактировать группу"
      }
      return "Создать группу"
    }
  },
  created() {
    if (this.id) {
      //EDIT
      this.edit = true;
      this.groupName = this.name;
    } else {
      //NEW
      this.edit = false;
    }
  },
  props: ["id", "name"],
};
</script>

<style scoped></style>
